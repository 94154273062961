import React, { useEffect, useState } from 'react';

import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InputSelect, InputNumber } from '../../forms';
import { number_format } from '../../../utils';
import { useOperator } from '../../../contexts/operator-context';
import { useMasamuneServices } from '../../../contexts/accounting-contexts/masamune-services-context';

const FormContractOperator = ({ contractsOperators = [], setContractsOperators, handleUpdateContract = null }) => {
	const { getOperators } = useOperator();
	const { getServices } = useMasamuneServices();

	const [agents, setAgents] = useState(null);
	const [services, setServices] = useState(null);

	// comp operators data
	const [user, setUser] = useState(null);
	const [hourlyRate, setHourlyRate] = useState(false);
	const [service, setService] = useState(null);
	const [qtaServices, setQtaServices] = useState(0.00);
	const [discount, setDiscount] = useState(0.00);
	const [perc, setPerc] = useState(0.00);
	const [expensesOperator, setExpensesOperator] = useState(0.00);
	const [expensesAgency, setExpensesAgency] = useState(0.00);

	useEffect(() => {
		const fetchAgents = async () => {
			const { data, error } = await getOperators();
			if (error !== null) return alert(error.data.error);

			const cleanedData = data.results.map((item, index) => {
				return {
					index: index,
					value: index,
					data: item,
					label: `${item.first_name} ${item.last_name}`
				}
			})

			setAgents(cleanedData);
		};

		const fetchServices = async () => {
			const { data, error } = await getServices();
			if (error !== null) return alert(error.data.error);

			const cleanedData = data.results.map((item, index) => {
				return {
					index: index,
					value: index,
					data: item,
					label: `${item.code} - ${item.name} - ${item.category}`
				}
			})

			setServices(cleanedData);
		};

		fetchAgents();
		fetchServices();
	}, [getOperators, getServices]);

	const handleAddContractOperator = () => {
		if (user === null || service === null || qtaServices === 0) return ;

		setContractsOperators(
			items => [
				...items,
				{
					userIndex: user,
					serviceIndex: service,
					user: agents[user].data.id,
					hourly_rate: hourlyRate,
					service: services[service].data.id,
					qta_services: qtaServices,
					discount: discount,
					perc: perc,
					expenses_operator: expensesOperator,
					expenses_agency: expensesAgency,
					total_comp: parseFloat((services[service].data.supplier_price * qtaServices)) + parseFloat(expensesOperator) - parseFloat(expensesAgency)
				}
			]
		);

		setUser(null);
		setHourlyRate(false);
		setService(null);
		setQtaServices(0.00);
		setDiscount(0.00);
		setPerc(0.00);
		setExpensesOperator(0.00);
		setExpensesAgency(0.00);

		document.getElementById('qta-service').value = 0;
		document.getElementById('discount').value = 0;
		document.getElementById('perc').value = 0;
		document.getElementById('expenses-operator').value = 0;
		document.getElementById('expenses-agency').value = 0;

		document.getElementById('operator').selectedIndex = 0;
		document.getElementById('service').selectedIndex = 0;
	}

	const handleRemoveOperator = (index) => {
		setContractsOperators(items => items.filter((_, i) => i !== index));
	}

	const handleUpdateOperator = (index) => {
		const data = contractsOperators[index];

		let userIndex = data.userIndex;
		let serviceIndex = data.serviceIndex;

		if (userIndex === null) {
			agents.filter((_, index) => {
				if (_.data.id === data.user.id) {
					userIndex = index;
				}

				return null;
			});
		}

		if (serviceIndex === null) {
			services.filter((_, index) => {
				if (_.data.id === data.service.id) {
					serviceIndex = index;
				}

				return null;
			});
		}

		setUser(userIndex);
		setHourlyRate(data.hourly_rate);
		setService(serviceIndex);
		setQtaServices(data.qta_services);
		setDiscount(data.discount);
		setPerc(data.perc);
		setExpensesOperator(data.expenses_operator);
		setExpensesAgency(data.expenses_agency);

		document.getElementById('qta-service').value = data.qta_services;
		document.getElementById('discount').value = data.discount;
		document.getElementById('perc').value = data.perc;
		document.getElementById('expenses-operator').value = data.expenses_operator;
		document.getElementById('expenses-agency').value = data.expenses_agency;

		document.getElementById('operator').selectedIndex = parseInt(userIndex) + 1;
		document.getElementById('service').selectedIndex = parseInt(serviceIndex) + 1;

		handleRemoveOperator(index);
	}

	return (
		<div className="operators-settings">
			<hr />

			<p className="section-title mb-0">Compensi operatori</p>

			<div className="row">
				<div className="col-md-4">
					{/*  hourly rate boolean */}
					<p className="d-block mb-0">&nbsp;</p>
					<label htmlFor="hourly-rate"><input type="checkbox" name="hourly-rate" id="hourly-rate" onChange={() => setHourlyRate(!hourlyRate)} checked={hourlyRate} /> Rate Oraria?</label>
				</div>
				<div className="col-md-4">
					{/* select user */}
					<InputSelect name="operator" label={'Operatore'} data={agents !== null ? agents : []} onChange={setUser} />
				</div>
				<div className="col-md-4">
					{/* select service */}
					<InputSelect name="service" label={'Servizio'} data={services !== null ? services : []} onChange={setService} />
				</div>
				<div className="col-md-4">
					{/* qta service */}
					<InputNumber value={qtaServices} type="number" label={hourlyRate ? 'Ore' : 'Qta'} name="qta-service" onChange={setQtaServices} step={hourlyRate ? '0.01' : '1'} />
				</div>
				<div className="col-md-4">
					{/* discount % */}
					<InputNumber value={discount} type="number" label="Sconto" name="discount" perc={true} onChange={setDiscount} step={'0.01'} />
				</div>
				<div className="col-md-4">
					{/* perc % */}
					<InputNumber value={perc} type="number" label="Percentuale" name="perc" perc={true} onChange={setPerc} step={'0.01'} />
				</div>
				<div className="col-md-4">
					{/* expenses operator + */}
					<InputNumber value={expensesOperator} type="number" label="Spese Operatore (+)" name="expenses-operator" price={true} onChange={setExpensesOperator} step={'0.01'} />
				</div>
				<div className="col-md-4">
					{/* expenses agency - */}
					<InputNumber value={expensesAgency} type="number" label="Spese Agenzia (-)" name="expenses-agency" price={true} onChange={setExpensesAgency} step={'0.01'} />
				</div>
				<div className="col-md-4">
					<p className="d-block">&nbsp;</p>
					<button type="button" className="btn btn-primary btn-block w-100" onClick={() => handleAddContractOperator()}>Aggiungi</button>
				</div>
			</div>

			{
				contractsOperators.length > 0 ?
					<div class="table-responsive">
						<table className="table table-striped mt-5">
							<thead>
								<tr>
									<th>Azioni</th>
									<th>Utente</th>
									<th>Servizio</th>
									<th>Qta</th>
									<th>Perc</th>
									<th>Sconto</th>
									<th>Spese Oper.</th>
									<th>Spese Agenzia.</th>
									<th>Totale Compenso</th>
								</tr>
							</thead>
							<tbody>
								{
									contractsOperators.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<button className="btn btn-edit me-2" onClick={() => handleUpdateOperator(index)}><FontAwesomeIcon icon={faPen} /></button>
													<button className="btn btn-delete" onClick={() => handleRemoveOperator(index)}><FontAwesomeIcon icon={faTrash} /></button>
												</td>
												<td>{'userIndex' in item ? agents[item.userIndex].data.username : item.user.username}</td>
												<td>{'userIndex' in item ? services[item.serviceIndex].data.code : item.service.code}</td>
												<td>{number_format(item.qta_services, 2, ',', '.')}</td>
												<td>{number_format(item.perc, 2, ',', '.')} %</td>
												<td>{number_format(item.discount, 2, ',', '.')} %</td>
												<td>&euro; {number_format(item.expenses_operator, 2, ',', '.')}</td>
												<td>&euro; {number_format(item.expenses_agency, 2, ',', '.')}</td>
												<td>&euro; {number_format(item.total_comp, 2, ',', '.')}</td>
											</tr>
										)
									})
								}
							</tbody>
						</table></div>
					: null}

			{
				handleUpdateContract !== null ?
					<>
						<hr />
						<button className="btn btn-primary" onClick={handleUpdateContract}>Aggiorna operatori</button>
					</>
					: null}
		</div>
	)
};

export default FormContractOperator;