import React, { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";

import { useBonus } from "../../../contexts/accounting-contexts/bonus-context";
import { QuarterSectionBonus } from "./bonus-components";
import { number_format } from "../../../utils";
import { InputText } from "../../../ui-components/forms";

import TopBarSection from "../../../ui-components/top-bar-section";

const BonusAreaInterns = () => {
	const { getBonusInterns, storeCompsBonusInterns, createNewBonus } =
		useBonus();

	const [target_amount, set_target_amount] = useState(null);
	const [target_year, set_target_year] = useState(null);
	const [perc_bonus, set_perc_bonus] = useState(null);
	const [perc_responsables, set_perc_responsables] = useState(null);
	const [perc_operators, set_perc_operators] = useState(null);
	const [perc_bonus_sale, set_perc_bonus_sale] = useState(null);

	const [refresh, setRefresh] = useState(0);
	const [data, setData] = useState(null);
	const [year, setYear] = useState(new Date().getFullYear());
	const [compareYear, setCompareYear] = useState(null);

	useEffect(() => {
		const fetchBonusSingleSeller = async () => {
			const { data, error } = await getBonusInterns(year, compareYear);
			if (error !== null) return alert(error.data.error);
			setData(data);
		};

		fetchBonusSingleSeller();
	}, [year, compareYear, refresh, getBonusInterns]);

	const handleStoreCompsBonusInterns = async () => {
		const { error } = await storeCompsBonusInterns(year);
		if (error !== null) return alert(error.data.error);
		setRefresh((x) => x + 1);
	};

	const handleSaveBonus = async () => {
		const { error } = await createNewBonus(
			target_amount,
			target_year,
			perc_bonus,
			perc_responsables,
			perc_operators,
			perc_bonus_sale
		);
		if (error !== null) return alert(error.data.error);
		return window.location.reload();
	};

	return (
		<div className="bonus-area-sales">
			<TopBarSection pageName="Bonus Interni" handleChangeYear={setYear} />

			{data === null && (
				<div className="row">
					<div className="col-md-3 mb-2">
						<InputText
							label={"Anno (YYYY)"}
							name="year"
							onChange={set_target_year}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<InputText
							label={"Target (es. 200000)"}
							name="target"
							onChange={set_target_amount}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<InputText
							label={"Bonus % (es. 2.00)"}
							name="bonus_perc"
							onChange={set_perc_bonus}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<InputText
							label={"Responsabili % (es. 65)"}
							name="resp_perc"
							onChange={set_perc_responsables}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<InputText
							label={"Operatori % (es. 35)"}
							name="ope_perc"
							onChange={set_perc_operators}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<InputText
							label={"Sales % (es. 2.00)"}
							name="sales_perc"
							onChange={set_perc_bonus_sale}
						/>
					</div>
					<div className="col-md-3 mb-2">
						<label className="d-block">&nbsp;</label>
						<input
							className="btn btn-primary"
							type="submit"
							value="Aggiungi bonus"
							onClick={handleSaveBonus}
						/>
					</div>
				</div>
			)}

			{data !== null ? (
				<div className="component">
					{data.has_achieved_target &&
					(!data.stored_payment || !data.stored_payment_splitted) ? (
						<p>
							Obiettivo raggiunto, ecco il bonus da dividere:{" "}
							<strong>
								&euro; {number_format(data.bonus_value, 2, ",", ".")}
							</strong>
						</p>
					) : null}
					{data.has_achieved_target &&
					(!data.stored_payment || !data.stored_payment_splitted) ? (
						<p>
							Bonus operatori:{" "}
							<strong>
								&euro; {number_format(data.operators_bonus, 2, ",", ".")}
							</strong>
						</p>
					) : null}
					{data.has_achieved_target &&
					(!data.stored_payment || !data.stored_payment_splitted) ? (
						<p>
							Bonus responsabili:{" "}
							<strong>
								&euro; {number_format(data.managers_bonus, 2, ",", ".")}
							</strong>
						</p>
					) : null}
					{data.has_achieved_target &&
					data.stored_payment &&
					data.stored_payment_splitted ? (
						<p>Obiettivo raggiunto e compensi divisi</p>
					) : null}

					{!data.has_achieved_target && data.a_quarter_was_achieved ? (
						<p>Un obiettivo trimestrale è stato raggiunto!</p>
					) : null}
					{!data.has_achieved_target && data.a_quarter_was_achieved ? (
						<p>
							Bonus operatori:{" "}
							<strong>
								&euro; {number_format(data.operators_bonus, 2, ",", ".")}
							</strong>
						</p>
					) : null}
					{!data.has_achieved_target && data.a_quarter_was_achieved ? (
						<p>
							Bonus responsabili:{" "}
							<strong>
								&euro; {number_format(data.managers_bonus, 2, ",", ".")}
							</strong>
						</p>
					) : null}

					{data.has_achieved_target && !data.stored_payment ? (
						<button
							onClick={handleStoreCompsBonusInterns}
							className="btn btn-primary"
						>
							Dividi i compensi
						</button>
					) : null}
					{!data.has_achieved_target &&
					data.a_quarter_was_achieved &&
					!data.stored_payment_splitted ? (
						<button
							onClick={handleStoreCompsBonusInterns}
							className="btn btn-primary"
						>
							Dividi i compensi
						</button>
					) : null}

					<div className="row mt-4">
						<div className="col-md-3">
							<QuarterSectionBonus
								className={
									data.quarters[0].has_achieved_quarter_target
										? "success-bonus"
										: "no-completed-bonus"
								}
							>
								<p className="mb-2">Primo trimestre</p>
								<p>
									<strong>Obiettivo: </strong>&euro;{" "}
									{number_format(data.quarters[0].amount_target, 2, ",", ".")}
								</p>
								<p>
									<strong>Totale: </strong>&euro;{" "}
									{number_format(data.quarters[0].amount_quarter, 2, ",", ".")}
								</p>
							</QuarterSectionBonus>
						</div>
						<div className="col-md-3">
							<QuarterSectionBonus
								className={
									data.quarters[1].has_achieved_quarter_target
										? "success-bonus"
										: "no-completed-bonus"
								}
							>
								<p className="mb-2">Secondo trimestre</p>
								<p>
									<strong>Obiettivo: </strong>&euro;{" "}
									{number_format(data.quarters[1].amount_target, 2, ",", ".")}
								</p>
								<p>
									<strong>Totale: </strong>&euro;{" "}
									{number_format(data.quarters[1].amount_quarter, 2, ",", ".")}
								</p>
							</QuarterSectionBonus>
						</div>
						<div className="col-md-3">
							<QuarterSectionBonus
								className={
									data.quarters[2].has_achieved_quarter_target
										? "success-bonus"
										: "no-completed-bonus"
								}
							>
								<p className="mb-2">Terzo trimestre</p>
								<p>
									<strong>Obiettivo: </strong>&euro;{" "}
									{number_format(data.quarters[2].amount_target, 2, ",", ".")}
								</p>
								<p>
									<strong>Totale: </strong>&euro;{" "}
									{number_format(data.quarters[2].amount_quarter, 2, ",", ".")}
								</p>
							</QuarterSectionBonus>
						</div>
						<div className="col-md-3">
							<QuarterSectionBonus
								className={
									data.quarters[3].has_achieved_quarter_target
										? "success-bonus"
										: "no-completed-bonus"
								}
							>
								<p className="mb-2">Quarto trimestre</p>
								<p>
									<strong>Obiettivo: </strong>&euro;{" "}
									{number_format(data.quarters[3].amount_target, 2, ",", ".")}
								</p>
								<p>
									<strong>Totale: </strong>&euro;{" "}
									{number_format(data.quarters[3].amount_quarter, 2, ",", ".")}
								</p>
							</QuarterSectionBonus>
						</div>
					</div>

					<hr />

					<h2 className="section-title mt-3 mb-2">Analisi</h2>

					<label htmlFor="compare-year" className="mt-0 mb-2">
						Anno
					</label>
					<select
						name="compare-year"
						id="compare-year"
						onChange={(e) =>
							setCompareYear(e.target.value === "" ? null : e.target.value)
						}
						className="form-select w-auto mb-5"
					>
						<option value="">Nessun anno selezionato</option>
						<option value={new Date().getFullYear()}>
							{new Date().getFullYear()}
						</option>
						<option value={new Date().getFullYear() - 1}>
							{new Date().getFullYear() - 1}
						</option>
						<option value={new Date().getFullYear() - 2}>
							{new Date().getFullYear() - 2}
						</option>
						<option value={new Date().getFullYear() - 3}>
							{new Date().getFullYear() - 3}
						</option>
						<option value={new Date().getFullYear() - 4}>
							{new Date().getFullYear() - 4}
						</option>
						<option value={new Date().getFullYear() - 5}>
							{new Date().getFullYear() - 5}
						</option>
					</select>

					<div style={{ height: 400, marginTop: 10 }}>
						<p>Fatturato {year}</p>
						<ResponsiveLine
							data={data.data_chart}
							margin={{ top: 50, right: 50, bottom: 70, left: 100 }}
							colors={{ scheme: "dark2" }}
							xScale={{ type: "point" }}
							yScale={{
								type: "linear",
								min: "0",
								max: "auto",
								stacked: true,
								reverse: false,
							}}
							yFormat=" >-.2f"
							theme={{
								tooltip: {
									container: {
										background: "#333",
										color: "#fff",
									},
								},
								background: "#111",
								textColor: "#fff",
								fontSize: "14px",
								tickColor: "#fff",
							}}
							axisBottom={{
								tickSize: 10,
								tickPadding: 5,
								tickRotation: -30,
								legend: "Mese",
								legendPosition: "start",
								legendOffset: 40,
							}}
							axisLeft={{
								tickSize: 10,
								tickPadding: 5,
								tickRotation: 0,
								tickValues: 5,
								legend: "Totale",
								legendPosition: "middle",
								legendOffset: -80,
							}}
							useMesh={true}
							pointSize={10}
							pointColor={{ theme: "background" }}
							pointBorderWidth={1}
							pointBorderColor={{ from: "serieColor" }}
							pointLabelYOffset={-12}
						/>
					</div>

					<hr />

					<h2 className="section-title">Compensi capireparto</h2>
					<div class="table-responsive">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Codice Fornitore</th>
									<th>Compenso</th>
									<th>Pagato</th>
								</tr>
							</thead>
							<tbody>
								{data.bonus_splitted_managers.map((item, index) => {
									return (
										<tr key={index}>
											<td>{item.supplier_code}</td>
											<td>&euro; {number_format(item.amount, 2, ",", ".")}</td>
											{data.a_quarter_was_achieved &&
											!data.has_achieved_target ? (
												<td>{data.stored_payment_splitted ? "SI" : "NO"}</td>
											) : data.a_quarter_was_achieved &&
											  data.has_achieved_target ? (
												<td>{data.stored_payment ? "SI" : "NO"}</td>
											) : (
												<td>N/A</td>
											)}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>

					<hr />

					<h2 className="section-title">Compensi sottoposti</h2>
					<div class="table-responsive">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Codice Fornitore</th>
									<th>Compenso</th>
									<th>Pagato</th>
								</tr>
							</thead>
							<tbody>
								{data.bonus_splitted_operators.map((item, index) => {
									return (
										<tr key={index}>
											<td>{item.supplier_code}</td>
											<td>&euro; {number_format(item.amount, 2, ",", ".")}</td>
											{data.a_quarter_was_achieved &&
											!data.has_achieved_target ? (
												<td>{data.stored_payment_splitted ? "SI" : "NO"}</td>
											) : data.a_quarter_was_achieved &&
											  data.has_achieved_target ? (
												<td>{data.stored_payment ? "SI" : "NO"}</td>
											) : (
												<td>N/A</td>
											)}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default BonusAreaInterns;
