import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

import TopBarSection from '../../../../ui-components/top-bar-section';
import { useHosting } from '../../../../contexts/accounting-contexts/hosting-context';

import { number_format, Padder } from '../../../../utils';
import moment from 'moment';
import TableFilterMonth from '../../../../ui-components/table-filter-months';


const ListHostingSubscriptions = (props) => {
	const { getSubscriptions, getRecapSubscriptions } = useHosting();

	const [subscriptions, setSubscriptions] = useState([]);
	const [year, setYear] = useState(new Date().getFullYear())

	useEffect(() => {
		const fetchSubscriptions = async () => {
			const { data, error } = await getSubscriptions(null, null);
			if (error !== null) return alert(error.data.error);
			setSubscriptions(data.results);
		};

		fetchSubscriptions();
	}, [year, getSubscriptions, getRecapSubscriptions]);

	return (
		<div className="list-hosting-subscriptions">
			<TopBarSection pageName="Lista Abbonamenti" handleChangeYear={setYear} />

			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>Azioni</th>
							<th>Nr. Abbonamento</th>
							<th>Data Scadenza</th>
							<th>Totale</th>
							<th>Piano</th>
							<th>Stato</th>
						</tr>
					</thead>
					<tbody>
						{
							subscriptions.map((item, index) => {
								return (
									<tr key={index}>
										<td>
											<a className="btn btn-edit" href={`/hosting/subscriptions/${item.id}`}><FontAwesomeIcon icon={faBoxOpen} /></a>
										</td>
										<td>#{new Padder(6).pad(item.nr_subscription)}</td>
										<td>{moment(item.expire_date).format('DD/MM/YYYY')}</td>
										<td>&euro; {number_format(item.tot, 2, ',', '.')}</td>
										<td>{item.plane}</td>
										<td>{item.status}</td>
									</tr>
								)
							})
						}
					</tbody>
				</table></div>
		</div>
	)
};


export default ListHostingSubscriptions;