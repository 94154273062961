import React from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import FormReferral from '../../../ui-components/form-components/referrals';

import { useReferral } from '../../../contexts/referral-context';
import { handlingSubmitError } from '../../../utils/handling-submit-error';


const NewReferral = ({ closePage }) => {
  const { createReferral } = useReferral();

  const handleSaveReferral = async (data) => {
    const { error } = await createReferral(data);
    
    if (error !== null) {
      if (error.data.error !== undefined) {
        return alert(error.data.error)
      }

      await handlingSubmitError(error);
      return ;
    }

    closePage(false);
    return window.location.reload();
  }

  return (
    <NewPageWrapper>
      <NewPageWrapperCopy>
        <div className="top-page-form">
          <h3>Segnala un nuovo cliente</h3>
          <button onClick={() => closePage(false)} className="badge bg-danger">&times;</button>
        </div>

        <hr />

        <FormReferral handleSave={handleSaveReferral} data={null} />
      </NewPageWrapperCopy>
    </NewPageWrapper>
  )
}

export default NewReferral;