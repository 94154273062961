import React, { useEffect } from 'react';
import moment from 'moment';

import { contractsActions } from '../../../constants/API_ACTIONS';
import { useContract } from '../../../contexts/contract-context';
import { InputDate, InputNumber, InputSelect } from '../../../ui-components/forms';

const ContractExpireChecker = ({ contract = null, handleUpdate = () => {} }) => {
	const { actionContract } = useContract();

	useEffect(() => {
		const updateExpireDate = async () => {
			await actionContract(
				contract.id,
				contractsActions.UPDATE_EXPIRE_DATE,
				{ value: moment(contract.completed_date_time).days(90).format('YYYY-MM-DD') }
			);
		}

		if (contract.completed_date_time !== null && contract.expire_date === null) {
			updateExpireDate();
			// window.location.reload();
		}
	}, [actionContract, contract]);

	const updateEffectiveDate = async (value) => {
		const confirm = window.confirm('Stai modificando delle informazioni contrattuali.\nSei sicuro di voler continuare?');
		if (!confirm) return ;

		const { error } = await actionContract(contract.id, contractsActions.UPDATE_EFFECTIVE_DATE, { value });
		if (error !== null) return alert(error.data.error);

		window.location.reload();
	};

	const updateContractStatus = async (value) => {
		const confirm = window.confirm('Stai modificando delle informazioni contrattuali.\nSei sicuro di voler continuare?');
		if (!confirm) return ;

		const { error } = await actionContract(contract.id, contractsActions.UPDATE_CONTRACT_STATUS, { value });
		if (error !== null) return alert(error.data.error);

		window.location.reload();
	}

	const updateContractDuration = async (value) => {
		const confirm = window.confirm('Stai modificando delle informazioni contrattuali.\nSei sicuro di voler continuare?');
		if (!confirm) return ;

		const { error } = await actionContract(contract.id, contractsActions.UPDATE_DURATION_CONTRACT, { value });
		if (error !== null) return alert(error.data.error);

		window.location.reload();
	}

	const updateReactivateContract = async (value) => {
		const confirm = window.confirm('Stai modificando delle informazioni contrattuali.\nSei sicuro di voler continuare?');
		if (!confirm) return ;

		const { error } = await actionContract(contract.id, contractsActions.REACTIVATE_CONTRACT, { value });
		if (error !== null) return alert(error.data.error);

		window.location.reload();
	}

	const statusContract = [
		{ value: 'activated', label: 'Attivo' },
		{ value: 'stand-by', label: 'Stand-By' },
		{ value: 'closed', label: 'Chiuso' },
	]

	return (
    <div className="contract-expire-checker">
			<hr />

			<div className="row">
				<div className="col-md-3">
					<InputDate defaultValue={contract.completed_date_time} label={'Data firma'} disabled={true} />
				</div>
				<div className="col-md-3">
					<InputDate name="effective-date" onSubmit={updateEffectiveDate} withSubmit={true} defaultValue={contract.effective_date} label="Data decorrenza" disabled={contract.status !== 'completed'} />
					{ contract.effective_date !== null ? <button className="badge bg-primary" onClick={() => updateEffectiveDate(null)}>Set null</button> : null }
				</div>	
				<div className="col-md-3">
					<InputDate defaultValue={contract.expire_date} label="Data scadenza" disabled={contract.expire_date !== null || contract.status !== 'completed'} />
				</div>
				<div className="col-md-3">
					<InputNumber step="1" name="contract-duration-value" onSubmit={updateContractDuration} withSubmit={true} defaultValue={contract.duration_contract} label="Durata contratto" disabled={contract.status !== 'completed'} />
				</div>
				<div className="col-md-3">
					<InputSelect data={statusContract} name="contact-status" defaultValue={contract.contract_status} label="Stato contratto" onChange={updateContractStatus}  />
				</div>
				{
					contract.contract_status === 'stand-by' ? 
					<div className="col-md-3">
						<InputDate name="end-standby-contract" defaultValue={contract.end_standby_date} onSubmit={updateReactivateContract} withSubmit={true} label="Data riattivazione" disabled={contract.contract_status !== 'stand-by'} />
					</div>
					: null
				}
			</div>
		</div>    
	);
};

export default ContractExpireChecker;