import React from 'react'

import { httpDelete, httpGet, httpPatch, httpPost } from '../../http';
import { HOSTING_ENDPOINT } from '../../constants/API_ENDPOINT';

import { useAuth } from '../auth-context';

const HostingContext = React.createContext()

const HostingProvider = (props) => {
	const { jwtToken } = useAuth();

	const getSubscriptions = async (month, year) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${HOSTING_ENDPOINT}/subscriptions/`, jwtToken, { month, year, order: '-nr_subscription' });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

  const getSingleSubscription = async (id) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${HOSTING_ENDPOINT}/subscriptions/single/${id}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getUsers = async () => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${HOSTING_ENDPOINT}/users/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

  const getSingleUser = async (id) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${HOSTING_ENDPOINT}/users/single/${id}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const updateUser = async (id, body) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPatch(`${HOSTING_ENDPOINT}/users/single`, jwtToken, body, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getSubscriptionsByUserId = async (userId) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${HOSTING_ENDPOINT}/subscriptions/uid/${userId}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getRecapSubscriptions = async (year) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${HOSTING_ENDPOINT}/subscriptions/recap-year/${year}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const sendEventRequest = async (id, event) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${HOSTING_ENDPOINT}/subscriptions/events/`, jwtToken, {
				subscriptionId: id,
				eventType: event
			});

			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const deleteUser = async (id) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpDelete(`${HOSTING_ENDPOINT}/users/single`, jwtToken, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const createSubRequest = async (body) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${HOSTING_ENDPOINT}/subscriptions/create/`, jwtToken, body);

			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const updatePassword = async (userId, body) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${HOSTING_ENDPOINT}/users/update-password/${userId}/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	return (
		<HostingContext.Provider
			value={{
				getUsers,
				getSingleUser,
				getSubscriptions,
				getSingleSubscription,
				getSubscriptionsByUserId,
				updateUser,
				getRecapSubscriptions,
				sendEventRequest,
				updatePassword,
				createSubRequest,
				deleteUser
			}}
			{...props}
		/>
	)
}
const useHosting = () => React.useContext(HostingContext)

export { HostingProvider, useHosting }