import React from 'react';
import { number_format } from '../../utils';

import { SingleMonthItem, TableFilterMonthWrap } from './components';

const TableFilterMonth = ({ data = [], handleChangeMonth, showTotal = true, showCount = false }) => {
  const months = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ]

  const handleOnClickMonth = async (e) => {
    const elements = document.getElementsByClassName('single-month-item');
    
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active');
      e.target.classList.add('active');
    }

    e.target.classList.add('active');
  }
  
  return (
    <TableFilterMonthWrap>
      {
        months.map((item, index) => {
          return (
            <SingleMonthItem className={ new Date().getMonth() === index ? 'active single-month-item' : 'single-month-item' } key={index} onClick={(e) => { 
              handleOnClickMonth(e);
              handleChangeMonth(index + 1)
            }}>
              <h2>{item}</h2>
							{ showTotal ? <span className={data[index] === null ? 'false' : 'ok'}>{data[index] === null ? 'NO DATA' : `€ ${number_format(data[index], 2, ',', '.')}`}</span> : null }
							{ showCount ? <span className={data[index] === 0 ? 'false' : 'ok'}>{data[index]} Items</span> : null }
            </SingleMonthItem> 
          )
        })
      }
    </TableFilterMonthWrap>   
  )
}

export default TableFilterMonth;