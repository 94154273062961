import React from 'react';

const LabelInfo = ({ name, value, colSize = 'col-md-4' }) => {
  return (
    <div className={colSize}>
      <p className="fw-bold mb-0">{name}</p>
      <p className="fw-normal">{value === '' ? 'N/A' : value}</p>
    </div>
  )
};

export default LabelInfo;