import React from 'react';
import { Switch, Route } from 'react-router-dom';

// contexts
import { OperatorProvider } from '../contexts/operator-context';
import { MasamuneServicesProvider } from '../contexts/accounting-contexts/masamune-services-context';

// components
import OperatorsList from '../components/operators-components/list-operators';
import OperatorUpdate from '../components/operators-components/update-operator';


const Operators = ({ match }) => {
  return (
    <OperatorProvider>
      <MasamuneServicesProvider>
        <Switch>
          <Route exact path={`${match.path}`} component={OperatorsList} />
          <Route exact path={`${match.path}/:id`} component={OperatorUpdate} />
        </Switch>
      </MasamuneServicesProvider>
    </OperatorProvider>
  )
}

export default Operators;