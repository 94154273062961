import React, { useEffect, useState } from 'react';

import { useAuth } from '../../contexts/auth-context';
import { useOperator } from '../../contexts/operator-context';
import { InputText } from '../../ui-components/forms';
import { number_format } from '../../utils';

import LabelInfo from '../../ui-components/label-info';
import TopBarSection from '../../ui-components/top-bar-section';

const Profile = () => {
  const { id } = useAuth();
  const { getSingleOperator, updatePasswordOperator } = useOperator();

  const [operator, setOperator] = useState(null);

  useEffect(() => {
    const fetchOperator = async () => {
      const { data, error } = await getSingleOperator(id);
      if (error !== null) return alert(error.data.error);
      setOperator(data.results);
    }

    fetchOperator();
  }, [id, getSingleOperator]);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    let oldPassword = e.target[0].value;
    let newPassword = e.target[1].value;
    let confNewPassword = e.target[2].value;

    const { data, error } = await updatePasswordOperator(id, {
      old_password: oldPassword,
      new_password: newPassword,
      conf_new_password: confNewPassword
    });

    if (error !== null) return alert(error.data.error);

    return alert(data.results)
  }

  return (
    <div className="profile-component">
      <TopBarSection pageName="Profilo" />

      {
        operator !== null ?
          <div>
            <h4 className="section-title">Le tue informazioni</h4>
            <div className="row">
              <LabelInfo name="Nome" value={operator.first_name} />
              <LabelInfo name="Cognome" value={operator.last_name} />
              <LabelInfo name="Username" value={operator.username} />
              <LabelInfo name="Email" value={operator.email} />
              <LabelInfo name="Ruolo" value={operator.role} />
              <LabelInfo name="Codice Fornitore" value={operator.supplier_code} />

							{
								operator.sales ?
								<>
									<LabelInfo name="Percentuale Bonus" value={`${number_format(operator.perc_sales, 2, ',', '.')} %`} />
									<LabelInfo name="Obiettivo Annuo" value={`€ ${number_format(operator.sales_yearly_amount, 2, ',', '.')}`} />
								</>
								: null
							}
            </div>

            <hr />

            <h4 className="section-title">I tuoi permessi</h4>
						<label className="d-block" htmlFor="is_manager"><input disabled={true} name="is_manager" id="is_manager" type="checkbox" defaultChecked={operator.is_manager} /> Capo Reparto</label>
						<label className="d-block" htmlFor="sales"><input disabled={true} name="sales" id="sales" type="checkbox" defaultChecked={operator.sales} /> Sales</label>
						<label className="d-block" htmlFor="can_view_accounting"><input disabled={true} name="can_view_accounting" id="can_view_accounting" type="checkbox" defaultChecked={operator.can_view_accounting} /> Contabilità</label>
						<label className="d-block" htmlFor="can_view_contracts"><input disabled={true} name="can_view_contracts" id="can_view_contracts" type="checkbox" defaultChecked={operator.can_view_contracts} /> Contratti</label>
						<label className="d-block" htmlFor="can_view_operators"><input disabled={true} name="can_view_operators" id="can_view_operators" type="checkbox" defaultChecked={operator.can_view_operators} /> Operatori</label>
						<label className="d-block" htmlFor="can_view_settings"><input disabled={true} name="can_view_settings" id="can_view_settings" type="checkbox" defaultChecked={operator.can_view_settings} /> Impostazioni</label>
						<label className="d-block" htmlFor="can_view_customers"><input disabled={true} name="can_view_customers" id="can_view_customers" type="checkbox" defaultChecked={operator.can_view_customers} /> Gestione clienti</label>
						<label className="d-block" htmlFor="can_view_quotes"><input disabled={true} name="can_view_quotes" id="can_view_quotes" type="checkbox" defaultChecked={operator.can_view_quotes} /> Gestione preventivi</label>
						<label className="d-block" htmlFor="can_view_referrals"><input disabled={true} name="can_view_referrals" id="can_view_referrals" type="checkbox" defaultChecked={operator.can_view_referrals} /> Referrals</label>
						<label className="d-block" htmlFor="can_view_bonus"><input disabled={true} name="can_view_bonus" id="can_view_bonus" type="checkbox" defaultChecked={operator.can_view_bonus} /> Bonus</label>

            <hr />

            <h4 className="section-title">Modifica Password</h4>
            <form onSubmit={handleUpdatePassword}>
              <InputText type="password" name="oldPassword" label="Vecchia password" />
              <InputText type="password" name="newPassword" label="Nuova password" />
              <InputText type="password" name="confNewPassword" label="Conferma nuova password" />
              <input type="submit" value="Conferma" className="btn btn-primary mt-3" />
            </form>


          </div>
          : null
      }
    </div>
  )
};

export default Profile;