import { faDownload, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

import { useContract } from '../../../contexts/contract-context';
import TopBarSection from '../../../ui-components/top-bar-section';
import ContractExpireChecker from './contract-expire-checker';
import { contractsActions } from '../../../constants/API_ACTIONS';

import FormContractOperator from '../../../ui-components/form-components/contracts/contract-operator';

const SingleContract = ({ match }) => {
	const { id } = match.params;
	const { getSingleContract, downloadDocument, actionContract } = useContract();

	const [contract, setContract] = useState(null);
	const [countUpdate, setCountUpdate] = useState(0);

	const [contractsOperators, setContractsOperators] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const { data, error } = await getSingleContract(id);
			if (error !== null) return alert(error.data.error);
			setContract(data.results);
			setContractsOperators(data.results.contract_operators);
		}

		fetchData();
	}, [getSingleContract, id, countUpdate]);

	const handleDownloadDocument = async (documentId, name) => {
		const { data, error } = await downloadDocument(id, documentId);
		if (error !== null) return alert(error.data.error);

		const pdfB64 = data.results;

		const href = `data:application/pdf;base64,${pdfB64}`;
		const element = document.createElement("a");
		const filename = `${name}.pdf`;

		element.href = href;
		element.download = filename;
		element.click();

		element.remove();
	}

	const handleUpdateContractOperators = async () => {
		await actionContract(contract.id, contractsActions.UPDATE_CONTRACT_OPERATORS, { value: contractsOperators })
	}

	return (
		<div className="single-contract-page">
			<TopBarSection pageName={`Contratto ID ${id}`} />

			{
				contract !== null ?
					<div>
						<div className="row">
							<div className="col-md-6">
								<h3 className="section-title">Informazioni Contratto</h3>
								<div class="table-responsive">
									<table className="table table-one-way table-striped">
										<tr>
											<th>ID</th>
											<td>{contract.id}</td>
										</tr>
										<tr>
											<th>Nome Cliente</th>
											<td>{contract.customer_name}</td>
										</tr>
										<tr>
											<th>Email Cliente</th>
											<td>{contract.customer_email}</td>
										</tr>
										<tr>
											<th>Stato Documento</th>
											<td>{contract.type_file === 'Preventivo' ? contract.contract_status : contract.status}</td>
										</tr>
										<tr>
											<th>Envelope ID</th>
											<td>{contract.envelope_id}</td>
										</tr>
									</table></div>
							</div>
							<div className="col-md-6">
								<h3 className="section-title">Documenti</h3>
								<div class="table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
												<th>Azioni</th>
												<th>ID</th>
												<th>Nome</th>
											</tr>
										</thead>
										<tbody>
											{
												contract.documents.map((item, index) => {
													return (
														<tr key={index}>
															<td>
																<button className="btn btn-edit" onClick={() => handleDownloadDocument(item.id, item.name)}>
																	<FontAwesomeIcon icon={faDownload} />
																</button>
															</td>
															<td>{item.id}</td>
															<td>{item.name}</td>
														</tr>
													)
												})
											}
										</tbody>
									</table></div>
							</div>
						</div>

						<hr />

						<div className="row">
							<div className="col-md-6">
								<h3 className="section-title">Mittenti</h3>
								<div class="table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
												<th>Azioni</th>
												<th>Nome</th>
												<th>Email</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<a className="btn btn-edit" href={`mailto:${contract.sender.email}`}>
														<FontAwesomeIcon icon={faUser} />
													</a>
												</td>
												<td>{contract.sender.name}</td>
												<td>{contract.sender.email}</td>
											</tr>
										</tbody>
									</table></div>
							</div>
							<div className="col-md-6">
								<h3 className="section-title">Destinatari</h3>
								<div class="table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
												<th>Azioni</th>
												<th>Email</th>
												<th>Tramite</th>
											</tr>
										</thead>
										<tbody>
											{
												contract.signers.map((item, index) => {
													return (
														<tr key={index}>
															<td>
																<a className="btn btn-edit" href={`mailto:${item.email}`}>
																	<FontAwesomeIcon icon={faUser} />
																</a>
															</td>
															<td>{item.email}</td>
															<td>{item.delivery_method}</td>
														</tr>
													)
												})
											}
										</tbody>
									</table></div>
							</div>
						</div>

						{contract.type_file === 'Preventivo' ? <FormContractOperator contractsOperators={contractsOperators} setContractsOperators={setContractsOperators} handleUpdateContract={handleUpdateContractOperators} /> : null}
						{contract.type_file === 'Preventivo' ? <ContractExpireChecker handleUpdate={setCountUpdate} contract={contract} /> : null}
					</div>
					: <p>Caricamento risorse...</p>}
		</div>
	)
}

export default SingleContract;