import React from 'react'

import { httpGet, httpPost } from '../../http';
import { BONUS_ENDPOINT, INVOICES_ENDPOINT } from '../../constants/API_ENDPOINT';

import { useAuth } from '../auth-context';

const MasamuneServicesContext = React.createContext()

const MasamuneServicesProvider = (props) => {
	const { jwtToken } = useAuth();

	const getInvoices = async (month, year, params = { }) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${INVOICES_ENDPOINT}/list/`, jwtToken, { month, year, ...params });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getSingleInvoice = async (id) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${INVOICES_ENDPOINT}/single/${id}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getRecapInvoices = async (year, params = { }) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${INVOICES_ENDPOINT}/recap-year/${year}/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const getAgentsInfo = async (month, year, userId = 'default') => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${INVOICES_ENDPOINT}/agent/comps/${userId}/`, jwtToken, { month, year });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const getRecapSingleAgent = async (year, userId = 'default') => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${INVOICES_ENDPOINT}/agent/recap-year/${year}/${userId}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const getServices = async () => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${INVOICES_ENDPOINT}/list/services/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const setInvoiceComps = async (id, string) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${INVOICES_ENDPOINT}/set-comps/${id}/`, jwtToken, { string });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getSumCreditNotes = async (year) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${INVOICES_ENDPOINT}/credit-notes-sum/`, jwtToken, { year });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const storeCompUser = async (id, amount) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${INVOICES_ENDPOINT}/store-payment-to-supplier/${id}/`, jwtToken, { amount });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const storeCompUserBonus = async (id) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${BONUS_ENDPOINT}/store-payment-to-supplier/${id}/`, jwtToken, { });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const updateCompOperator = async (id, amount) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${INVOICES_ENDPOINT}/update-comp/${id}/`, jwtToken, { amount });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	return (
		<MasamuneServicesContext.Provider
			value={{
        getInvoices,
				getSingleInvoice,
        getRecapInvoices,
				getAgentsInfo,
				getRecapSingleAgent,
				getServices,
				setInvoiceComps,
				getSumCreditNotes,
				storeCompUser,
				storeCompUserBonus,
				updateCompOperator
			}}
			{...props}
		/>
	)
}
const useMasamuneServices = () => React.useContext(MasamuneServicesContext)

export { MasamuneServicesProvider, useMasamuneServices }
