import React from 'react';

const Terms = () => {
	return (
		<div className="privacy-page container">
			<h1>Termini e condizioni</h1>
			<p>I seguenti termini e condizioni indicano regole e normative per l'utilizzo della piattaforma gestionale ERP di Anfutravel s.r.l., accessibile all'indirizzo <a href="https://dashboard.masamune.it/">https://dashboard.masamune.it/</a>.</p>
			<p>Accedendo a questo ERP, l'utente accetta i termini e le condizioni esposte di seguito. </p>


			<h1>Utilizzo dell’ERP</h1>
			<p>L’ERP è accessibile tramite delle credenziali d’accesso che verranno fornite all’utente in seguito alla registrazione effettuata a cura di Anfutravel s.r.l. L’utente che desidera effettuare il login alla piattaforma deve inserire le credenziali fornite, che si impegna a custodire con riservatezza e a non divulgare a terzi in nessun caso, senza l’autorizzazione esplicita e data per iscritto di Anfutravel s.r.l. Una volta eseguito il login, l’utente avrà accesso a dati e informazioni personalizzate in base al livello di permessi accordati. </p>

			<p>Anfutravel s.r.l provvederà a inserire nella piattaforma informazioni aggiornate e precise, ma non garantisce circa la loro completezza o accuratezza. Anfutravel s.r.l si riserva il diritto di modificare i contenuti del sito e delle note legali in qualsiasi momento e senza alcun preavviso.</p>

			<h1>Note e commenti</h1>
			<p>Le funzionalità dell’ERP riservate agli utenti sono per buona parte di sola visualizzazione, essendo le funzioni di modifica dei dati e delle informazioni prerogativa di utenti Admin e Super Admin. In alcuni casi, tuttavia, parti di questa piattaforma ERP offrono all'utente la possibilità di pubblicare informazioni in certe aree. </p>

			<p>Anfutravel s.r.l. non filtra, modifica, pubblica o revisiona i commenti prima di aggiungerli sul gestionale ERP. I commenti non riflettono il punto di vista e l'opinione di Anfutravel s.r.l., dei suoi agenti e/o dei suoi affiliati. I commenti riflettono il punto di vista e opinione di colui che pubblica tale punto di vista o opinione. </p>

			<p>Nella misura consentita dalla legge, Anfutravel s.r.l. non deve essere ritenuta responsabile per i commenti e non può ricevere richieste di risarcimento danni o spese causate dall'uso, dalla pubblicazione e/o dalla presenza dei commenti sul gestionale ERP. Anfutravel s.r.l. si riserva il diritto di monitorare tutti i commenti e rimuovere quelli che considera inappropriati, offensivi o in violazione della legge e dei presenti termini e condizioni.</p>


			<h1>Cookie</h1>
			<p>La piattaforma ERP utilizza cookie indispensabili per il suo funzionamento. Accedendo a Dashboard Masamune, l’utente accetta l'uso dei cookie richiesti. L’accettazione dei cookie richiesti prevede anche l'accettazione dei cookie di terzi integrati sulla piattaforma.</p>


			<h1>Proprietà intellettuale</h1>

			<p>A meno che indicato altrimenti, Anfutravel s.r.l. detiene in maniera esclusiva tutti i diritti di proprietà intellettuale per tutto il materiale su Dashboard Masamune. </p>

			<p>L’utente può accedere alla piattaforma per uso personale e non gli è consentito in alcun modo di:</p>

			<ul>
				<li>Copiare o ripubblicare il materiale di Dashboard Masamune</li>
				<li>Vendere, noleggiare o fornire in sub-licenza il materiale di Dashboard Masamune</li>
				<li>Riprodurre, duplicare o copiare il materiale di Dashboard Masamune</li>
				<li>Ridistribuire contenuti di Dashboard Masamune</li>
			</ul>


			<p>È pertanto vietata la riproduzione, la modifica, la duplicazione, la copia, la distribuzione, la vendita comunque lo sfruttamento della piattaforma per scopi commerciali. L'utente si impegna a conservare tutte le indicazioni sui marchi e il diritto di autore contenute sui materiali scaricati o copiati. L'uso di questi materiali su qualsiasi altro sito web è vietato. L'uso non autorizzato del sito e del materiale contenuto nel medesimo costituisce violazione delle leggi applicabili sulla proprietà intellettuale. </p>

			<h1>Responsabilità</h1>

			<p>In nessun caso Anfutravel s.r.l potrà essere ritenuta responsabile dei danni di qualsiasi natura causati direttamente o indirettamente dall’accesso al sito, dall’incapacità o impossibilità di accedervi, dall'affidamento e utilizzo dell’utente in merito a dati, informazioni, proiezioni e notizie in esso contenute.</p>

			<h1>Collegamenti ipertestuali</h1>

			<p>Nessuna organizzazione e persona fisica sono autorizzate ad aggiungere o distribuire collegamenti ipertestuali al nostro ERP senza previa autorizzazione scritta di Anfutravel s.r.l.</p>

			<h1>Legge Regolatrice</h1>
			<p>Ai presenti Termini e Condizioni è applicabile la legge italiana.</p>

			<h1>Condizioni Ulteriori</h1>
			<p>Ulteriori termini e condizioni diversi dalle presenti potranno applicarsi alla vendita di beni e servizi o all'uso di altre parti del sito. </p>



			<h1>Definizioni</h1>

			<p><strong>Credenziali di Accesso:</strong> significa il sistema di autenticazione attraverso il quale è possibile accedere e utilizzare la piattaforma ERP, inclusi i codici di identificazione e le chiavi di accesso associati a ciascun Utente e gli eventuali token.</p>

			<p><strong>Cookie:</strong> I cookie sono frammenti di dati sugli utenti memorizzati sul computer e utilizzati per migliorare la navigazione. I cookie, anche conosciuti come cookie HTTP, web, Internet o del browser, vengono creati dal server e inviati sul browser dell’utente. </p>

			<p><strong>ERP:</strong> Enterprise Resource Planning (ERP) si riferisce a un tipo di software che le organizzazioni utilizzano per gestire le attività commerciali quotidiane, come ad esempio contabilità, procurement, project management, gestione del rischio e compliance e operations per la supply chain. Una suite ERP completa include anche delle funzioni di enterprise performance management, cioè risorse che aiutano a pianificare, quantificare, prevedere e comunicare i risultati finanziari di un'organizzazione.</p>

			<p><strong>Proprietà Intellettuale:</strong> significa ogni diritto di proprietà intellettuale e/o industriale, registrato o non registrato, in tutto o in parte, ovunque nel mondo, quali - a titolo esemplificativo e non esaustivo - marchi, brevetti, modelli di utilità, disegni e modelli, nomi a dominio, know-how, opere coperte dal diritto d’autore, database e software (ivi inclusi, ma non limitatamente a, le sue derivazioni, il codice sorgente, il codice oggetto e le interfacce).</p>

			<p><strong>Saas:</strong> significa software-as-a-service.</p>

			<p><strong>Utente:</strong> significa ciascun dipendente e/o collaboratore di Anfutravel s.r.l., da quest’ultimo autorizzato ad utilizzare le Credenziali di Accesso per accedere e utilizzare l’ERP al fine di usufruirne.</p>



		</div >
	)
}

export default Terms;