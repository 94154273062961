import React from 'react'

import { httpGet, httpPost } from '../http';
import { CUSTOMERS_ENDPOINT } from '../constants/API_ENDPOINT';

import { useAuth } from './auth-context';

const CustomerContext = React.createContext()

const CustomerProvider = (props) => {
	const { jwtToken } = useAuth();

   const getCustomers = async (params = {}) => {
    let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}/list/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
  }

	const getSingleCustomer = async (id) => {
    let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}/single/${id}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
  }

	const addNote = async (id, note) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${CUSTOMERS_ENDPOINT}/single/${id}/note/`, jwtToken, { note });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	} 

	const updateFidoValue = async (id, fido) => {
    let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${CUSTOMERS_ENDPOINT}/single/${id}/update-fido/`, jwtToken, { fido });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
  }

	const checkFido = async (id) => {
    let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${CUSTOMERS_ENDPOINT}/single/${id}/check-fido/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
  }

	return (
		<CustomerContext.Provider
			value={{
        getCustomers,
				getSingleCustomer,
				addNote,
				updateFidoValue,
				checkFido
			}}
			{...props}
		/>
	)
}
const useCustomer = () => React.useContext(CustomerContext)

export { CustomerProvider, useCustomer }