import React, { useState, useEffect } from 'react';

import TopBarSection from '../../../ui-components/top-bar-section';
import TableFilterMonths from '../../../ui-components/table-filter-months';
import { useProject } from '../../../contexts/project-context';
import NewProject from '../new-project';
import { useAuth } from '../../../contexts/auth-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import SingleProject from '../single-project';
import EditProject from '../edit-project';

const ListProjects = () => {
	const { getProjects, getRecapProjects, getSingleProject, splitPaymentsProjects } = useProject();
	const { role } = useAuth();

	const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);
	const [showSingleProject, setShowSingleProject] = useState(false);
	const [showEditProject, setShowEditProject] = useState(false);
	const [singleProject, setSingleProject] = useState(null);
	const [projects, setProjects] = useState([]);
	const [recapProjects, setRecapProjects] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1)
	const [year, setYear] = useState(new Date().getFullYear());

	useEffect(() => {
		const fetchProjects = async () => {
			const { data, error } = await getProjects({ year, month });
			if (error !== null) return alert(error.data.error);
			setProjects(data.results);
		};

		const fetchRecapProjects = async () => {
			const { data, error } = await getRecapProjects(year);
			if (error !== null) return alert(error.data.error);
			setRecapProjects(data.results);
		}
		
		fetchProjects();
		fetchRecapProjects();
	}, [year, month, getRecapProjects, getProjects]);

	const handleOpenModalInfo = async (id) => {
		const { data, error } = await getSingleProject(id);
		if (error !== null) return alert(error.data.error);
		setSingleProject(data.results);
		setShowSingleProject(true);
	};

	const handleOpenModalEdit = async (id) => {
		const { data, error } = await getSingleProject(id);
		if (error !== null) return alert(error.data.error);
		setSingleProject(data.results);
		setShowEditProject(true);
	}

	const handleCloseModal = () => {
		setSingleProject(null);
		setShowSingleProject(false);
		setShowEditProject(false);
	}

	const handleClickSplitPayments = async () => {
		const confirmSplit = window.confirm("Sei sicuro di voler continuare?");
		if (!confirmSplit) return ;

		const { data, error } = await splitPaymentsProjects(year);
		if (error !== null) return alert(error.data.error);
		return alert(data.results)
	}

	return (
		<div className="projects-page-list">
			<TopBarSection pageName="Progetti" handleChangeYear={setYear} handleNewComponent={role === 'SUPER ADMIN' || role === 'ADMIN' ? () => setShowCreateProjectForm(true) : null} />

			{
				role === 'SUPER ADMIN' || role === 'ADMIN' ? 
					<div className="admin-settings">
						<button type="button" className="btn btn-primary" onClick={() => handleClickSplitPayments()}>Dividi compensi</button>

						<hr />
					</div>
				: null
			}


			<TableFilterMonths data={recapProjects} handleChangeMonth={setMonth}  showTotal={false} showCount={true}  />
			<div class="table-responsive">
<table className="table table-striped">
				<thead>
					<tr>
						<th>Azioni</th>
						<th>Nome progetto</th>
						<th>Nr. Contratti</th>
						<th>Nr. Operatori</th>
						<th>Nr. Fatture</th>
					</tr>
				</thead>
				<tbody>
					{
						projects !== null ?
							projects.map((item, index) => {
								return (
									<tr key={index}>
										<td>
											<button className="btn btn-edit" onClick={() => handleOpenModalInfo(item.id)}><FontAwesomeIcon icon={faEye} /></button>
											{ role === 'SUPER ADMIN' || role === 'ADMIN' ? <button className="btn btn-edit" onClick={() => handleOpenModalEdit(item.id)}><FontAwesomeIcon icon={faPen} /></button> : null }
										</td>
										<td>{ item.name }</td>
										<td>{ item.contracts.length }</td>
										<td>{ item.operators.length }</td>
										<td>{ item.invoices.length }</td>
									</tr>
								)
							})
						: null
					}
				</tbody>
			</table></div>

			{ showCreateProjectForm && (role === 'SUPER ADMIN' || role === 'ADMIN')  ? <NewProject closePage={setShowCreateProjectForm} /> : null }
			{ showSingleProject && singleProject !== null ? <SingleProject closePage={handleCloseModal} data={singleProject} /> : null }
			{ showEditProject && singleProject !== null ? <EditProject closePage={handleCloseModal} project={singleProject} /> : null }
		</div>
	)
};

export default ListProjects;