import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// contexts
import { AccountingProvider } from '../contexts/accounting-contexts/accounting-context';
import { MasamuneServicesProvider } from '../contexts/accounting-contexts/masamune-services-context';

// components
import SingleAgentInfo from '../components/single-agent-info';

const Maturato = ({ match }) => {
  return (
    <BrowserRouter>
      <AccountingProvider>
        <MasamuneServicesProvider>
          <Switch>
            <Route exact path={`${match.path}`} component={SingleAgentInfo} />
          </Switch>
        </MasamuneServicesProvider>
      </AccountingProvider>
    </BrowserRouter>
  )
}

export default Maturato;