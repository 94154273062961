export const contractsActions = {
	UPDATE_EFFECTIVE_DATE: 'update-effective-date',
	UPDATE_CONTRACT_STATUS: 'update-contract-status',
	UPDATE_EXPIRE_DATE: 'update-expire-date',
	UPDATE_DURATION_CONTRACT: 'update-duration-contract',
	REACTIVATE_CONTRACT: 'reactivate-contract',
	UPDATE_CONTRACT_OPERATORS: 'update-contract-operators'
}

export const referralsConstants = {
	STORE_REFERRAL_ON_FIC: 'store-referral-on-fic',
	UPDATE_EXPIRE_DATE: 'update-expire-date',
	APPROVE_REFERRAL: 'approve-referral',
	REFUSE_REFERRAL: 'refuse-referral'
}