import React from 'react'

import { httpGet, httpPatch, httpPost } from '../http';
import { QUOTES_ENDPOINT } from '../constants/API_ENDPOINT';

import { useAuth } from './auth-context';

const QuoteContext = React.createContext()

const QuoteProvider = (props) => {
	const { jwtToken } = useAuth();

	const getQuotes = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${QUOTES_ENDPOINT}/list/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getRecapQuotes = async (year, params = { }) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${QUOTES_ENDPOINT}/recap-year/${year}/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getSingleQuote = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${QUOTES_ENDPOINT}/single/${id}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const editStatusSingleQuote = async (id, status) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${QUOTES_ENDPOINT}/change-status`, jwtToken, { status }, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const addNote = async (id, note) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${QUOTES_ENDPOINT}/single/${id}/note/`, jwtToken, { note });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	} 

	return (
		<QuoteContext.Provider
			value={{
				getQuotes,
				getRecapQuotes,
				getSingleQuote,
				editStatusSingleQuote,
				addNote
			}}
			{...props}
		/>
	)
}
const useQuote = () => React.useContext(QuoteContext)

export { QuoteProvider, useQuote }