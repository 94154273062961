import React from 'react'

import { httpDelete, httpGet, httpPatch, httpPost } from '../http';
import { REFERRALS_INTERNAL_ENDPOINT } from '../constants/API_ENDPOINT';

import { useAuth } from './auth-context';

const ReferralContext = React.createContext()

const ReferralProvider = (props) => {
	const { jwtToken } = useAuth();

	const getReferrals = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${REFERRALS_INTERNAL_ENDPOINT}/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const createReferral = async (body = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${REFERRALS_INTERNAL_ENDPOINT}/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getSingleReferral = async (id, params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${REFERRALS_INTERNAL_ENDPOINT}/single/${id}/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const updateReferral = async (id, body = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${REFERRALS_INTERNAL_ENDPOINT}/single`, jwtToken, body, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const actionSingleReferral = async (id, action, body = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${REFERRALS_INTERNAL_ENDPOINT}/actions/${action}`, jwtToken, body, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const setReferralCompPayed = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${REFERRALS_INTERNAL_ENDPOINT}/set-referral-comp-payed`, jwtToken, {}, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const deleteReferral = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${REFERRALS_INTERNAL_ENDPOINT}/single`, jwtToken, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	return (
		<ReferralContext.Provider
			value={{
				getReferrals,
				createReferral,
				getSingleReferral,
				updateReferral,
				deleteReferral,
				actionSingleReferral,
				setReferralCompPayed
			}}
			{...props}
		/>
	)
}
const useReferral = () => React.useContext(ReferralContext)

export { ReferralProvider, useReferral }