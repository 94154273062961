import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useOperator } from "../../../contexts/operator-context";

import { InputEmail, InputText, InputSelect, InputNumber } from "../../forms";

const FormOperator = ({ data = null, handleSave }) => {
	const { register, handleSubmit } = useForm({
		defaultValues: data,
	});

	const { recoveryPassword } = useOperator();

	const [password, setPassword] = useState("");
	const [confPassword, setConfPassword] = useState("");

	const [role, setRole] = useState(data !== null ? data.role : null);
	const [isManager, setIsManager] = useState(
		data !== null ? data.is_manager : false
	);
	const [sales, setSales] = useState(data !== null ? data.sales : false);
	const [canViewAccounting, setCanViewAccounting] = useState(
		data !== null ? data.can_view_accounting : false
	);
	const [canViewContracts, setCanViewContracts] = useState(
		data !== null ? data.can_view_contracts : false
	);
	const [canViewOperators, setCanViewOperators] = useState(
		data !== null ? data.can_view_operators : false
	);
	const [canViewCustomers, setCanViewCustomers] = useState(
		data !== null ? data.can_view_customers : false
	);
	const [canViewQuotes, setCanViewQuotes] = useState(
		data !== null ? data.can_view_quotes : false
	);
	const [canViewReferrals, setCanViewReferrals] = useState(
		data !== null ? data.can_view_referrals : false
	);
	const [canViewBonus, setCanViewBonus] = useState(
		data !== null ? data.can_view_bonus : false
	);
	const [canViewHosting, setCanViewHosting] = useState(
		data !== null ? data.can_view_hosting : false
	);

	const onSubmit = async (body) => {
		await handleSave({
			...body,
			is_manager: isManager,
			sales: sales,
			can_view_accounting: canViewAccounting,
			can_view_contracts: canViewContracts,
			can_view_operators: canViewOperators,
			can_view_customers: canViewCustomers,
			can_view_quotes: canViewQuotes,
			can_view_referrals: canViewReferrals,
			can_view_bonus: canViewBonus,
			can_view_hosting: canViewHosting,
		});
	};

	const roles = [
		{ value: "ADMIN", label: "ADMIN" },
		{ value: "OPERATORE", label: "OPERATORE" },
		{ value: "SALES", label: "SALES" },
	];

	const handleOnChangeRole = (value) => {
		setRole(value);

		if (value === "ADMIN") {
			setIsManager(false);
			setSales(false);
			setCanViewAccounting(true);
			setCanViewContracts(true);
			setCanViewOperators(true);
			setCanViewCustomers(true);
			setCanViewQuotes(true);
			setCanViewReferrals(true);
			setCanViewBonus(true);
			setCanViewHosting(false);
		}

		if (value === "OPERATORE") {
			setIsManager(false);
			setSales(false);
			setCanViewAccounting(false);
			setCanViewContracts(false);
			setCanViewOperators(false);
			setCanViewCustomers(false);
			setCanViewQuotes(false);
			setCanViewReferrals(true);
			setCanViewBonus(true);
			setCanViewHosting(false);
		}

		if (value === "SALES") {
			setIsManager(false);
			setSales(true);
			setCanViewAccounting(false);
			setCanViewContracts(true);
			setCanViewOperators(false);
			setCanViewCustomers(false);
			setCanViewQuotes(true);
			setCanViewReferrals(true);
			setCanViewBonus(true);
			setCanViewHosting(false);
		}
	};

	const onSubmitRecoveryPassword = async () => {
		const response = await recoveryPassword(data.id, {
			password: password,
			conf_password: confPassword,
		});

		if (response.error) {
			return alert(response.error.data.error);
		}

		return alert("Password modificata.");
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="row">
				<div className="col-md-4">
					<InputText name="first_name" label="Nome" register={register} />
				</div>
				<div className="col-md-4">
					<InputText name="last_name" label="Cognome" register={register} />
				</div>
				<div className="col-md-4">
					<InputEmail name="email" label="Email" register={register} />
				</div>

				<div className="col-md-4">
					<InputText name="username" label="Username" register={register} />
				</div>

				{data === null ? (
					<div className="col-md-4">
						<InputText
							type="password"
							name="password"
							label="Password"
							register={register}
						/>
					</div>
				) : null}

				<div className="col-md-4">
					<InputSelect
						onChange={handleOnChangeRole}
						data={roles}
						name="role"
						label="Ruolo"
						register={register}
					/>
				</div>

				<div className="col-md-4">
					<InputText
						name="supplier_code"
						label="Codice Fornitore"
						register={register}
					/>
				</div>

				{role === "SALES" || sales ? (
					<div className="col-md-4">
						<InputNumber
							perc={true}
							name="perc_sales"
							label="Percentuale Bonus"
							register={register}
						/>
					</div>
				) : null}

				{role === "SALES" || sales ? (
					<div className="col-md-4">
						<InputNumber
							price={true}
							name="sales_yearly_amount"
							label="Obiettivo Annuo"
							register={register}
						/>
					</div>
				) : null}
			</div>

			<hr />

			<h4 className="fw-bold">Permessi Utente</h4>
			<label className="d-block" htmlFor="is_manager">
				<input
					checked={isManager}
					onChange={() => setIsManager(!isManager)}
					name="is_manager"
					id="is_manager"
					type="checkbox"
				/>{" "}
				Capo Reparto
			</label>
			<label className="d-block" htmlFor="sales">
				<input
					checked={sales}
					onChange={() => setSales(!sales)}
					name="sales"
					id="sales"
					type="checkbox"
				/>{" "}
				Sales
			</label>
			<label className="d-block" htmlFor="can_view_accounting">
				<input
					checked={canViewAccounting}
					onChange={() => setCanViewAccounting(!canViewAccounting)}
					name="can_view_accounting"
					id="can_view_accounting"
					type="checkbox"
				/>{" "}
				Contabilità
			</label>
			<label className="d-block" htmlFor="can_view_contracts">
				<input
					checked={canViewContracts}
					onChange={() => setCanViewContracts(!canViewContracts)}
					name="can_view_contracts"
					id="can_view_contracts"
					type="checkbox"
				/>{" "}
				Contratti
			</label>
			<label className="d-block" htmlFor="can_view_hosting">
				<input
					checked={canViewHosting}
					onChange={() => setCanViewHosting(!canViewHosting)}
					name="can_view_hosting"
					id="can_view_hosting"
					type="checkbox"
				/>{" "}
				Hosting
			</label>
			<label className="d-block" htmlFor="can_view_operators">
				<input
					checked={canViewOperators}
					onChange={() => setCanViewOperators(!canViewOperators)}
					name="can_view_operators"
					id="can_view_operators"
					type="checkbox"
				/>{" "}
				Operatori
			</label>
			<label className="d-block" htmlFor="can_view_customers">
				<input
					checked={canViewCustomers}
					onChange={() => setCanViewCustomers(!canViewCustomers)}
					name="can_view_customers"
					id="can_view_customers"
					type="checkbox"
				/>{" "}
				Gestione clienti
			</label>
			<label className="d-block" htmlFor="can_view_quotes">
				<input
					checked={canViewQuotes}
					onChange={() => setCanViewQuotes(!canViewQuotes)}
					name="can_view_quotes"
					id="can_view_quotes"
					type="checkbox"
				/>{" "}
				Gestione preventivi
			</label>
			<label className="d-block" htmlFor="can_view_referrals">
				<input
					checked={canViewReferrals}
					onChange={() => setCanViewReferrals(!canViewReferrals)}
					name="can_view_referrals"
					id="can_view_referrals"
					type="checkbox"
				/>{" "}
				Referrals
			</label>
			<label className="d-block" htmlFor="can_view_bonus">
				<input
					checked={canViewBonus}
					onChange={() => setCanViewBonus(!canViewBonus)}
					name="can_view_bonus"
					id="can_view_bonus"
					type="checkbox"
				/>{" "}
				Bonus
			</label>

			<hr />

			<h4 className="fw-bold">Modifica Password</h4>

			<div className="row">
				<div className="col-md-4">
					<InputText name="password" label="Password" onChange={setPassword} />
				</div>
				<div className="col-md-4">
					<InputText name="conf_password" label="Conferma Password" onChange={setConfPassword} />
				</div>
				<div className="col-md-4">
					<label className="d-block">&nbsp;</label>
					<button onClick={() => onSubmitRecoveryPassword()} type="button" className="w-100 btn btn-primary">Modifica</button>
				</div>
			</div>

			<hr />

			<input type="submit" value="Conferma" className="btn btn-primary mt-3" />
		</form>
	);
};

export default FormOperator;
