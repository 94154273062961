import React from 'react'

import { httpPost, httpGet, httpDelete, httpPatch } from '../http'
import { OPERATOR_CONTEXT } from '../constants/API_ENDPOINT'

import { useAuth } from './auth-context';

const OperatorContext = React.createContext()

const OperatorProvider = (props) => {
	const { jwtToken } = useAuth();

	const getOperators = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${OPERATOR_CONTEXT}/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const updatePasswordOperator = async (id, body) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${OPERATOR_CONTEXT}/update-password/${id}/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const createOperator = async (body) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${OPERATOR_CONTEXT}/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const getSingleOperator = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${OPERATOR_CONTEXT}/single/${id}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const updateOperator = async (id, body) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${OPERATOR_CONTEXT}/single`, jwtToken, body, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const deleteOperator = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${OPERATOR_CONTEXT}/single`, jwtToken, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const recoveryPassword = async (id, body) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${OPERATOR_CONTEXT}/recovery-password/${id}/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	return (
		<OperatorContext.Provider
			value={{
				getOperators,
				createOperator,
				updateOperator,
				deleteOperator,
				getSingleOperator,
				updatePasswordOperator,
				recoveryPassword
			}}
			{...props}
		/>
	)
}
const useOperator = () => React.useContext(OperatorContext)

export { OperatorProvider, useOperator }