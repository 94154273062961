import React from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import FormOperator from '../../../ui-components/form-components/operators';

import { useOperator } from '../../../contexts/operator-context';
import { handlingSubmitError } from '../../../utils/handling-submit-error';


const NewOperator = ({ closePage }) => {
  const { createOperator } = useOperator();

  const handleSaveOperator = async (data) => {
    // create user
    const { error } = await createOperator({ ...data, is_staff: false });
    
    if (error !== null) {
      if (error.data.error !== undefined) {
        return alert(error.data.error)
      }

      await handlingSubmitError(error);
      return ;
    }

    closePage(false);
    return window.location.reload();
  }

  return (
    <NewPageWrapper>
      <NewPageWrapperCopy>
        <div className="top-page-form">
          <h3>Aggiungi un operatore</h3>
          <button onClick={() => closePage(false)} className="badge bg-danger">&times;</button>
        </div>

        <hr />

        <FormOperator handleSave={handleSaveOperator} data={null} />
      </NewPageWrapperCopy>
    </NewPageWrapper>
  )
}

export default NewOperator;