import React, {useState, useEffect} from 'react';

import {useMasamuneServices} from '../../contexts/accounting-contexts/masamune-services-context';

import TopBarSection from '../../ui-components/top-bar-section';
import TableFilterMonth from '../../ui-components/table-filter-months';

import {number_format} from '../../utils';
import moment from 'moment';

const SingleAgentInfo = () => {
	const {getAgentsInfo, getRecapSingleAgent} = useMasamuneServices();

	const [items, setItems] = useState([]);
	const [recaps, setRecaps] = useState([]);
	const [month, setMonth] = useState(new Date().getMonth() + 1)
	const [year, setYear] = useState(new Date().getFullYear())

	useEffect(() => {
		const fetchItems = async () => {
			const {data, error} = await getAgentsInfo(month, year);
			if (error !== null) return alert(error.data.error);
			setItems(data.results);
		};

		const fetchRecapYear = async () => {
			const {data, error} = await getRecapSingleAgent(year);
			if (error !== null) return alert(error.data.error);
			setRecaps(data.results);
		};

		fetchItems();
		fetchRecapYear();
	}, [year, month, getAgentsInfo, getRecapSingleAgent]);

	return (
		<div className="single-agent-info">
			<TopBarSection pageName="Il mio maturato" handleChangeYear={setYear}/>

			<TableFilterMonth data={recaps} handleChangeMonth={setMonth}/>
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
					<tr>
						<th>Data</th>
						<th>Cliente</th>
						<th>Compenso</th>
					</tr>
					</thead>
					<tbody>
					{
						items.map((item, index) => {
							return (
								<tr key={index}>
									<td>{moment(item.date).format('DD/MM/YYYY')}</td>
									<td>{item.customer}</td>
									<td>€ {number_format(item.amount, 2, ',', '.')}</td>
								</tr>
							)
						})
					}
					</tbody>
				</table>
			</div>
		</div>
	)
};

export default SingleAgentInfo;