import React, { useEffect, useState } from 'react';

import TopBarSection from '../../../ui-components/top-bar-section';
import { AccountingAreaWrapper, AccountingAreaItem } from './components';

import { useMasamuneServices } from '../../../contexts/accounting-contexts/masamune-services-context';
import { useHosting } from '../../../contexts/accounting-contexts/hosting-context';
import { number_format } from '../../../utils';



const AccountingArea = () => {
  const { getRecapInvoices, getSumCreditNotes} = useMasamuneServices();
  const { getRecapSubscriptions } = useHosting();

  const [totalSection1, setTotalSection1] = useState(0.00);
  const [totalSection2, setTotalSection2] = useState(0.00);
  const [totalSection3, setTotalSection3] = useState(0.00);
  const [totalSection4, setTotalSection4] = useState(0.00);
  const [totalCreditNotes, setTotalCreditNotes] = useState(0.00);

  useEffect(() => {
    const fetchCreditNotesSum = async () => {
      const { data, error } = await getSumCreditNotes(new Date().getFullYear());
      if (error !== null) return alert(error.data.error);
      setTotalCreditNotes(data.results);
    }
    const fetchDataSection1 = async () => {
      const { data, error } = await getRecapInvoices(new Date().getFullYear());

      if (error !== null) return alert(error.data.error);

      let total1 = 0;
      data.results.forEach((item) => total1 += isNaN(item) ? 0 : item )
      setTotalSection1(total1);
    }

    const fetchDataSection2 = async () => {
      const { data, error } = await getRecapInvoices(new Date().getFullYear(), { category: 'Rate Oraria' });

      if (error !== null) return alert(error.data.error);

      let total2 = 0;
      data.results.forEach((item) => total2 += isNaN(item) ? 0 : item )
      setTotalSection2(total2);
    }

    const fetchDataSection3 = async () => {
      const { data, error } = await getRecapInvoices(new Date().getFullYear(), { category: 'Talent Sourcing' });

      if (error !== null) return alert(error.data.error);

      let total3 = 0;
      data.results.forEach((item) => total3 += isNaN(item) ? 0 : item )
      setTotalSection3(total3);
    }

    const fetchDataSection4 = async () => {
      const { data, error } = await getRecapSubscriptions(new Date().getFullYear());

      if (error !== null) return alert(error.data.error);

      let total4 = 0;
      data.results.forEach((item) => total4 += isNaN(item) ? 0 : item )
      setTotalSection4(total4);
    }

    fetchDataSection1();
    fetchDataSection2();
    fetchDataSection3();
    fetchDataSection4();
    fetchCreditNotesSum();
  }, [getRecapSubscriptions, getRecapInvoices, getSumCreditNotes]);

  return (
    <div className="accounting-area">
      <TopBarSection pageName="Contabilità" />

      <div className="alert alert-info dark"><strong>I valori delle varie aree non comprendono le note credito.</strong></div>

      <hr />

      <p className="mb-1">Totale Netto Fatture:<strong> &euro; {number_format(totalSection1 + totalSection2 + totalSection3 + totalSection4, 2, ',', '.')}</strong></p>
      <p className="mb-1">Totale Netto Note Credito:<strong> &euro; {number_format(totalCreditNotes, 2, ',', '.')}</strong></p>
      <p className="mb-1">Totale Netto:<strong> &euro; {number_format((totalSection1 + totalSection2 + totalSection3 + totalSection4) - totalCreditNotes, 2, ',', '.')}</strong></p>

      <hr />

      
      <AccountingAreaWrapper>
        <AccountingAreaItem>
          <a href="/accounting/masamune">
            <h2>Masamune Projects</h2>
            <p>&euro; { number_format(totalSection1, 2, ',', '.') }</p>
          </a>
        </AccountingAreaItem>

        <AccountingAreaItem>
          <a href="/accounting/masamune/hourly-consulting">
            <h2>Hourly Consulting</h2>
            <p>&euro; { number_format(totalSection2, 2, ',', '.') }</p>
          </a>
        </AccountingAreaItem>
      </AccountingAreaWrapper>

      <AccountingAreaWrapper>
        <AccountingAreaItem>
          <a href="/accounting/masamune/talent-sourcing">
            <h2>Talent Sourcing</h2>
            <p>&euro; { number_format(totalSection3, 2, ',', '.') }</p>
          </a>
        </AccountingAreaItem>

				<AccountingAreaItem>
          <a href="hosting/subscriptions">
            <h2>Masamune Hosting</h2>
            <p>&euro; { number_format(totalSection4, 2, ',', '.') }</p>
          </a>
        </AccountingAreaItem>
      </AccountingAreaWrapper>
    </div>
  )
};

export default AccountingArea;