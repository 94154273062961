import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ListQuotes from '../components/quotes-components/list-quotes';
import SingleQuote from '../components/quotes-components/single-quote';

import { QuoteProvider } from '../contexts/quote-context';

const Quotes = ({ match }) => {
  return (
    <Switch>
      <QuoteProvider>
        <Route exact path={`${match.path}`} component={ListQuotes} />
        <Route exact path={`${match.path}/:id`} component={SingleQuote} />
      </QuoteProvider>
    </Switch>
  )
}

export default Quotes;