import React from 'react'

import { httpGet, httpPost } from '../../http'
import { BONUS_ENDPOINT } from '../../constants/API_ENDPOINT'

import { useAuth } from '../auth-context';

const BonusContext = React.createContext()

const BonusProvider = (props) => {
	const { jwtToken } = useAuth();

	const getBonusSingleSeller = async (id, year = new Date().getFullYear()) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${BONUS_ENDPOINT}/single-bonus-sales/${id}/${year}/`, jwtToken, { });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const getBonusInterns = async (year = new Date().getFullYear(), compareYear = null) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpGet(`${BONUS_ENDPOINT}/bonus-interns/${year}/`, jwtToken, { compare_year: compareYear });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const storeCompsBonusInterns = async (year = new Date().getFullYear()) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${BONUS_ENDPOINT}/bonus-interns/${year}/`, jwtToken, { });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const storeCompsBonusSales = async (id, year = new Date().getFullYear()) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${BONUS_ENDPOINT}/single-bonus-sales/${id}/${year}/`, jwtToken, { });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const createNewBonus = async (target_amount, target_year, perc_bonus, perc_responsables, perc_operators, perc_bonus_sale) => {
		let obj = {
			data: null,
			error: null,
			status: null
		};

		try {
			const result = await httpPost(`${BONUS_ENDPOINT}/create/`, jwtToken, { target_amount, target_year, perc_bonus, perc_responsables, perc_operators, perc_bonus_sale });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	return (
		<BonusContext.Provider
			value={{
				getBonusInterns,
				getBonusSingleSeller,
				storeCompsBonusInterns,
				storeCompsBonusSales,
				createNewBonus
			}}
			{...props}
		/>
	)
}
const useBonus = () => React.useContext(BonusContext)

export { BonusProvider, useBonus }