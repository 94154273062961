import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { useReferral } from '../../../contexts/referral-context';

import TopBarSection from '../../../ui-components/top-bar-section';
import Pagination from '../../../ui-components/pagination';
import NewReferral from '../new-referral';


const ListReferrals = () => {
  const { getReferrals, deleteReferral } = useReferral();

  const [lengthAll, setLengthAll] = useState(0);
  const [query, setQuery] = useState(null);
  const [offset, setOffset] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [showNewReferral, setShowNewReferral] = useState(false);

	const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchReferrals = async () => {
      const { data, error } = await getReferrals({
        limit: 24 + offset,
        offset: offset,
        query: query
      });

      if (error !== null) return alert(error.data.error);
      setReferrals(data.results);
      setLengthAll(data.count);
    }

    fetchReferrals();
  }, [offset, query, getReferrals, refresh]);

  const handleSearchInfo = async (e, value) => {
    e.preventDefault();
    setOffset(0);
    setQuery(value);
  }

	const handleDeleteReferral = async (id) => {
		if (!window.confirm('Sei sicuro di voler continuare?')) return ;

		const { error } = await deleteReferral(id);
		if (error !== null) return alert(error.data.error);

		setRefresh(x => x + 1);
	}

  return (
    <div className="list-referrals-components">
      <TopBarSection pageName="Referrals" handleNewComponent={setShowNewReferral} handleSearchSubmit={handleSearchInfo} placeholderSearch={'P. IVA o Ragione Sociale'} />
      <Pagination length={lengthAll} setOffset={setOffset} offset={offset} />

      <div className="table-responsive">
<table className="table table-striped">
          <thead>
            <tr>
              <th>Azioni</th>
              <th>Nome</th>
              <th>Ragione Sociale</th>
              <th>P. Iva</th>
              <th>C. Fiscale</th>
              <th>Email</th>
              <th>PEC</th>
              <th>Stato</th>
              <th>Inizio</th>
              <th>Scadenza</th>
              <th>Creato il</th>
            </tr>
          </thead>
          <tbody>
            {
              referrals.map((item, index) => {
                return (
									<tr key={index}>
                    <td>
                      <a className="btn btn-edit me-2" href={`/referrals/internal/${item.id}`}><FontAwesomeIcon icon={faPen} /></a>
                      <button onClick={() => handleDeleteReferral(item.id)} className="btn btn-delete"><FontAwesomeIcon icon={faTrash} /></button>
                    </td>
                    <td>{`${item.first_name} ${item.last_name}`}</td>
                    <td>{item.rag_sociale}</td>
                    <td>{item.piva}</td>
                    <td>{item.cf}</td>
                    <td>{item.email}</td>
                    <td>{item.pec}</td>
                    <td>{item.status}</td>
                    <td>{item.start_count !== null ? moment(item.start_count).format('DD/MM/YYYY') : 'n/a'}</td>
                    <td>{item.expired_at !== null ? moment(item.expired_at).format('DD/MM/YYYY') : 'n/a'}</td>
                    <td>{item.created_at !== null ? moment(item.created_at).format('DD/MM/YYYY') : 'n/a'}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table></div>

      {showNewReferral ? <NewReferral closePage={setShowNewReferral} /> : null}
    </div>
  )
}

export default ListReferrals;