import styled from 'styled-components';

export const NewPageWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
	background: #0008;
	transform: translate(-50%, -50%);
	z-index: 99999;
`;

export const NewPageWrapperCopy = styled.div`
	position: fixed;
	width: 80%;
	max-height: 80%;
	top: 50%;
	left: 50%;
	background: #1a1a1a;
	border: 1px solid #333;
	transform: translate(-50%, -50%);
	border-radius: 8px;
	padding: 20px;
	z-index: 9999;
	overflow: auto;

	.top-page-form {
		display: flex;
		align-items: center;
		width: 100%;

		h3 {
			font-size: 22px;
			font-weight: bold;
		}

		button {
			margin-left: auto;
			border: 0;
			text-decoration: none;
			font-size: 20px;
			font-weight: bold;
			padding: 8;
			line-height: 1;
			color: #1a1a1a;
			border-radius: 2px;
		}
	}
`;

export const WrapperDashboard = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 20px;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
`;

export const WrapperPages = styled.div`
	flex: 0 0 calc(100% - 300px);
	max-width: calc(100% - 300px);
	height: 100%;
	background: #1a1a1a;
	padding: 20px;
	background: #212121;
	margin-left: 10px;
	border-radius: 8px;
	border: 1px solid #333;
	position: relative;
	overflow: auto;
`;

export const BottomPreview = styled.p`
	font-size: 14px;
	font-weight: bold;
	color: #666;
	margin-top: 6px;
	margin-bottom: 0;
`;