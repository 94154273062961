import React, { useEffect, useState } from 'react';

import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useOperator } from '../../../contexts/operator-context';
import TopBarSection from '../../../ui-components/top-bar-section';
import NewOperator from '../new-operator';

const OperatorsList = () => {
  const { getOperators, deleteOperator } = useOperator();
  const [operators, setOperators] = useState([]);

  const [showNewOperator, setShowNewOperator] = useState(false);

  useEffect(() => {
    const fetchOperators = async () => {
      const { data, error } = await getOperators();

      if (error !== null) {
        return alert(error.data.error)
      }

      setOperators(data.results);
    };

    fetchOperators();
  }, [getOperators])

  const onChangeRole = async (e) => {
    const value = e.target.value;
    const { data, error } = await getOperators({ role: value });

    if (error !== null) {
      return alert(error.data.error)
    }

    setOperators(data.results);
  }

  const handleDeleteOperator = async (id) => {
    const canIDelete = window.confirm('Sei sicuro di voler eliminare l\'operatore?');

    if (!canIDelete) return;

    const { error } = await deleteOperator(id);
    if (error !== null) {
      return alert(error.data.error)
    }

    alert('Operatore eliminato con successo.');
    return window.location.reload();
  }

  return (
    <div className="opeartors-list-page">
      <TopBarSection pageName="Operatori" handleFilterRole={onChangeRole} handleNewComponent={setShowNewOperator} />

      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>Cod. Fornitore</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Username</th>
              <th>Email</th>
              <th>Ruolo</th>
            </tr>
          </thead>
          <tbody>
            {
              operators !== null ?
                operators.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <a className="btn btn-edit me-2" href={`/operatori/${item.id}`}><FontAwesomeIcon icon={faPen} /></a>
                        <button className="btn btn-delete" onClick={() => handleDeleteOperator(item.id)}><FontAwesomeIcon icon={faTrash} /></button>
                      </td>
                      <td>{item.supplier_code}</td>
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>{item.username}</td>
                      <td>{item.email}</td>
                      <td>{item.role}</td>
                    </tr>
                  )
                })
                : null
            }
          </tbody>
        </table>
      </div>

      {showNewOperator ? <NewOperator closePage={setShowNewOperator} /> : null}
    </div>
  )
}

export default OperatorsList;