import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAuth } from '../../contexts/auth-context';

const Login = () => {
	const { setToken, login } = useAuth();
	const [error, setError] = useState(null);

	const {
		register,
		handleSubmit,
	} = useForm({ mode: 'onChange' });

	const onSubmit = async (data) => {
		try {
			const result = await login({
				username: data.username,
				password: data.password,
			});

			const { token } = result.data;
			return setToken(token);
		} catch (err) {
			const { data } = err.response;
			return setError(data.detail)
		}
	}

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)} className={'form-login'}>
				{
					error ?
						<div className="alert alert-danger mb-2">
							<strong>{error}</strong>
						</div>
						: null}
				<div className="form-field login-form">
					<label className={'oval-circle-red oval--right'} htmlFor="email">Username</label>
					<input name="username" className="form-control" {...register('username')} />
				</div>
				<div className="form-field">
					<label className={'oval-circle-red oval--right'} htmlFor="password">Password</label>
					<input type="password" className="form-control" name="password" {...register('password')} />
				</div>

				<hr />

				<p style={{ fontSize: 12 }}>Facendo clic su "Log in", accetti i nostri <a target="_blank" href="/termini-e-condizioni">Termini</a> e la nostra <a target="_blank" href="/privacy-policy">Privacy Policy</a></p>

				<hr />

				<button type="submit" className={`btn btn-md btn-primary`}>Log in</button>
			</form>
		</div>
	)
}

export default Login;
