import React, { useEffect, useState } from "react";

import { useOperator } from "../../../contexts/operator-context";
import { useMasamuneServices } from "../../../contexts/accounting-contexts/masamune-services-context";

import TableFilterMonth from "../../../ui-components/table-filter-months";
import FormOperator from "../../../ui-components/form-components/operators";

import TopBarSection from "../../../ui-components/top-bar-section";
import { handlingSubmitError } from "../../../utils/handling-submit-error";
import moment from "moment";
import { InputNumber } from "../../../ui-components/forms";

const OperatorUpdate = (props) => {
	const { id } = props.match.params;
	const { updateOperator, getSingleOperator } = useOperator();
	const { getAgentsInfo, getRecapSingleAgent, updateCompOperator } =
		useMasamuneServices();

	const [operator, setOpeartor] = useState(null);

	const [refresh, setRefresh] = useState(0);
	const [items, setItems] = useState([]);
	const [recaps, setRecaps] = useState([]);
	const [month, setMonth] = useState(new Date().getMonth() + 1);
	const year = new Date().getFullYear();

	useEffect(() => {
		const fetchItems = async () => {
			const { data, error } = await getAgentsInfo(month, year, id);
			if (error !== null) return alert(error.data.error);
			setItems(data.results);
		};

		const fetchRecapYear = async () => {
			const { data, error } = await getRecapSingleAgent(year, id);
			if (error !== null) return alert(error.data.error);
			setRecaps(data.results);
		};

		fetchItems();
		fetchRecapYear();
	}, [year, month, getAgentsInfo, getRecapSingleAgent, id, refresh]);

	useEffect(() => {
		const fetchSingleOperator = async () => {
			const { data, error } = await getSingleOperator(id);

			if (error !== null) {
				if (error.data.error !== undefined) {
					return alert(error.data.error);
				}

				await handlingSubmitError(error);
				return;
			}

			setOpeartor(data.results);
		};

		fetchSingleOperator();
	}, [getSingleOperator, id]);

	const handleSaveOperator = async (data) => {
		// update user
		const { error } = await updateOperator(id, { ...data });
		if (error !== null) return alert(error.data.error);

		return window.location.reload();
	};

	const handleUpdateComp = async (idComp, idElement) => {
		if (
			!window.confirm(
				"Sei sicuro di voler modificare il compenso dell'operatore?"
			)
		)
			return;

		const value = document.getElementById(idElement).value;
		const { data, error } = await updateCompOperator(idComp, value);

		if (error !== null) return alert(error.data.error);

		setRefresh((x) => x + 1);
		return alert(data.results);
	};

	return (
		<div className="update-operator-page">
			<TopBarSection pageName="Modifica Operatore" />

			

			{operator !== null ? (
				<>
					<FormOperator handleSave={handleSaveOperator} data={operator} />
					{operator.sales ? (
						<>
							<hr />{" "}
							<a
								href={`/bonus/sales/${operator.id}`}
								rel="noreferrer"
								target="_blank"
								className="btn btn-primary"
							>
								Bonus Agente
							</a>
						</>
					) : null}
				</>
			) : null}

			<hr />

			<TableFilterMonth data={recaps} handleChangeMonth={setMonth} />
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>Data</th>
							<th>Cliente</th>
							<th>Compenso</th>
						</tr>
					</thead>
					<tbody>
						{items.map((item, index) => {
							return (
								<tr key={index}>
									<td>{moment(item.date).format("DD/MM/YYYY")}</td>
									<td>{item.customer}</td>
									<td>
										<div className="input-group flex-nowrap w-100">
											<InputNumber
												type="number"
												step="0.01"
												classWrapper="w-100"
												name={`comp-${item.id}`}
												defaultValue={item.amount}
												price={true}
												onChange={() => {}}
											/>
											<button
												className="btn btn-primary"
												onClick={() =>
													handleUpdateComp(item.id, `comp-${item.id}`)
												}
											>
												Modifica
											</button>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default OperatorUpdate;
