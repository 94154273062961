import React, { useState, useEffect } from 'react';
import moment from 'moment';

import TopBarSection from '../../../../ui-components/top-bar-section';

import { useMasamuneServices } from '../../../../contexts/accounting-contexts/masamune-services-context';
import { number_format } from '../../../../utils';


const MasamuneServiceSingleInvoice = (props) => {
  const { id } = props.match.params;
  const { getSingleInvoice } = useMasamuneServices();

  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    const fetchSingleInvoice = async () => {
      const { data, error } = await getSingleInvoice(id);
      if (error !== null) return alert(error.data.error);
      setInvoice(data.results);
    }

    fetchSingleInvoice();
  }, [id, getSingleInvoice]);


  return (invoice !== null ?
    <div className="masamune-service-single-invoice">
      <TopBarSection pageName={`Contabilità - Fattura ID ${id}`} />

      <h3 className="section-title">Informazioni generiche</h3>
      <div class="table-responsive">
<table className="table table-one-way table-striped">
        <tbody>
          <tr>
            <th>Numero</th>
            <td>{invoice.nr_doc}</td>
            <th>Data</th>
            <td>{moment(invoice.date_doc).format('DD/MM/YYYY')}</td>
          </tr>
          <tr>
            <th>Cliente</th>
            <td>{invoice.customer}</td>
            <th>Documento</th>
            <td>€ {number_format(invoice.import_doc, 2, ',', '.')}</td>
          </tr>
          <tr>
            <th>IVA</th>
            <td>€ {number_format(invoice.import_vat, 2, ',', '.')}</td>
            <th>Totale</th>
            <td>€ {number_format(invoice.import_tot, 2, ',', '.')}</td>
          </tr>
        </tbody>
      </table></div>

      <hr />

      <h3 className="section-title">Servizi</h3>
        <div class="table-responsive">
<table className="table table-striped">
          <thead>
            <tr>
              <th>Codice</th>
              <th>Nome</th>
              <th>Reparto</th>
              <th>Categoria</th>
              <th>Qta.</th>
              <th>IVA</th>
              <th>Prezzo Singolo</th>
              <th>Prezzo Netto</th>
              <th>Prezzo Totale</th>
              <th>Sconto</th>
            </tr>
          </thead>
          <tbody>
            {
              invoice.services.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.code}</td>
                    <td>{item.name}</td>
                    <td>{item.department}</td>
                    <td>{item.category}</td>
                    <td>{item.qta}</td>
                    <td>{item.vat_value}</td>
                    <td>€ {number_format(item.price_net, 2, ',', '.')}</td>
                    <td>€ {number_format(item.price_gross, 2, ',', '.')}</td>
                    <td>€ {number_format(item.price_gross * item.qta, 2, ',', '.')}</td>
                    <td>% {number_format(item.discount, 0, ',', '.')}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table></div>

      <hr />

			<h3 className="section-title">Pagamenti</h3>
        <div class="table-responsive">
<table className="table table-striped">
          <thead>
            <tr>
              <th>Data Pagamento</th>
              <th>Data Scadenza</th>
              <th>Valore</th>
              <th>Metodo</th>
              <th>Pagato?</th>
              <th>Registrato?</th>
            </tr>
          </thead>
          <tbody>
            {
              invoice.payments.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{moment(item.date_payed).format('DD/MM/YYYY')}</td>
                    <td>{moment(item.expire_date).format('DD/MM/YYYY')}</td>
                    <td>€ {number_format(item.value, 2, ',', '.')}</td>
                    <td>{item.method}</td>
                    <td>{item.payed ? 'SI' : 'NO'}</td>
                    <td>{item.registered ? 'SI' : 'NO'}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table></div>

			<hr />

      <a href={invoice.doc_link} target="_blank" className="btn btn-primary me-2" rel="noreferrer">Apri la fattura pdf</a>
      <a href={`https://secure.fattureincloud.it/new/invoices-view-${invoice.id_fic}`} target="_blank" className="btn btn-primary" rel="noreferrer">Apri la fattura su FIC</a>

    </div>
    : <p>Caricamento risorse in corso...</p>)
}


export default MasamuneServiceSingleInvoice;