import styled from 'styled-components';

export const SidebarMain = styled.div`
	flex: 0 0 300px;
	max-width: 300px;
	margin-right: 10px;
	height: 100%;
	background: #212121;
	padding: 15px;
	z-index: 9999;
	border-radius: 8px;
	border: 1px solid #333;

	a {
		color: #fff;
		text-decoration: none;
		font-size: 18px;
		margin-bottom: 0!important;
		margin-top: 0!important;
	}
	
	hr {
		border-top: 1px solid #fff2;
		z-index: 99999;
		width: 100%;
	}

	.sidebar-link, .sidebar-account {
		a.active {
			span {
				color: #33FF99;
			}
		}
	}
`

export const SidebarLink = styled.span`
	display: block;
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 5px 14px;
	color: #eee;
	font-size: 16px;
	transition: .4s ease;
	
	&:hover {
		color: #33FF99;
		text-decoration: none
	}

	span {
		margin-left: 15px;
		font-weight: 600;
	}
`