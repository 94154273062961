import React from 'react';
import moment from 'moment';

import LabelInfo from '../../../ui-components/label-info';
import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import { filterRole } from '../../../constants/STATUS_FILTER';
import { number_format } from '../../../utils';

const SingleProject = ({ data = null, closePage = () => { } }) => {
	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page-form">
					<h3>{data.name}</h3>
					<button onClick={() => closePage()} className="badge bg-danger">&times;</button>
				</div>

				<hr />

				<LabelInfo name="Nome progetto" value={data.name} />

				<hr />

				{
					data.operators.length > 0 ?
						<>
							<p className="section-title">Operatori selezionati</p>
							<div class="table-responsive">
<table className="table table-striped">
								<thead>
									<tr>
										<th>Operatore</th>
										<th>Servizio</th>
										<th>Ruolo</th>
									</tr>
								</thead>
								<tbody>
									{
										data.operators.map((item, index) => {
											return (
												<tr key={index}>
													<td>{`${item.user.first_name} ${item.user.last_name}`}</td>
													<td>{`${item.service.code} - ${item.service.description.slice(0, 100)}...`}</td>
													<td>{item.role}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table></div>

							<hr />
						</>
						: null
				}


				{
					data.contracts.length > 0 ?
						<>
							<p className="section-title">Contratti selezionati</p>
							<div class="table-responsive">
<table className="table table-striped">
								<thead>
									<tr>
										<th>ID Contratto</th>
										<th>Cliente</th>
										<th>Stato</th>
										<th>Creato il</th>
										<th>Tipo</th>
									</tr>
								</thead>
								<tbody>
									{
										data.contracts.map((item, index) => {
											return (
												<tr key={index}>
													<td>{`${item.id}`}</td>
													<td>{`${item.customer_name}`}</td>
													<td>{`${filterRole[item.contract_status]}`}</td>
													<td>{`${moment(item.created_at).format('DD/MM/YYYY')}`}</td>
													<td>{`${item.type_file}`}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table></div>
						</>
						: null
				}

			{
				data.invoices.length > 0 ?
				<>
					<p className="section-title">Fatture selezionate</p>
					<div class="table-responsive">
<table className="table table-striped">
						<thead>
							<tr>
								<th>ID Fattura</th>
								<th>Cliente</th>
								<th>Nr. Doc</th>
								<th>Data</th>
								<th>Totale Document</th>
							</tr>
						</thead>
						<tbody>
							{
								data.invoices.map((item, index) => {
									return (
										<tr key={index}>
											<td>{ `${item.id}` }</td>
											<td>{ `${item.customer}` }</td>
											<td>{ `${item.nr_doc}` }</td>
											<td>{ `${moment(item.date_doc).format('DD/MM/YYYY')}` }</td>
											<td>&euro; { `${number_format(item.import_doc, 2, ',', '.')}` }</td>
										</tr>
									)
								})
							}
						</tbody>
					</table></div>
				</>
				: null
			}
			</NewPageWrapperCopy>
		</NewPageWrapper>
	);
};

export default SingleProject;