import React, { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';

import { useContract } from '../../../contexts/contract-context';

const ContractsPanels = ({ year }) => {
	const { getDashboardData } = useContract();

	const [result, setResult] = useState(null);

	useEffect(() => {
		setResult(null);

		const fetchContractsData = async () => {
			const { data, error } = await getDashboardData(year);
			if (error !== null) return alert(error.data.error);
			setResult(data.results);
		}

		fetchContractsData();
	}, [year, getDashboardData]);

	return (
		<div className="contracts-panels">
			{
				result !== null ?
					<div className="content">
						<div style={{ height: 400 }}>
							<p>Preventivi creati e firmati</p>
							<ResponsiveBar
								data={result.data_all}
								keys={['total_created', 'total_signed']}
								indexBy={'month'}
								margin={{ top: 50, right: 50, bottom: 70, left: 100 }}
								valueScale={{ type: 'linear' }}
								padding={0.15}
								groupMode={'grouped'}
								indexScale={{ type: 'band', round: true }}
								colors={{ scheme: 'dark2' }}
								labelSkipWidth={12}
								labelSkipHeight={12}
								labelTextColor={{
									from: 'theme',
									modifiers: [
										[
											'brighter',
											1.6
										]
									]
								}}
								enableLabel={false}
								theme={{
									tooltip: {
										container: {
											background: '#333',
											color: '#fff'
										}
									},
									background: '#111',
									textColor: '#fff',
									fontSize: '14px',
									tickColor: '#fff',
								}}
								axisBottom={{
									tickSize: 10,
									tickPadding: 5,
									tickRotation: -30,
									legend: 'Mese',
									legendPosition: 'start',
									legendOffset: 40
								}}
								axisLeft={{
									tickSize: 10,
									tickPadding: 5,
									tickRotation: 0,
									tickValues: 5,
									legend: 'Totale',
									legendPosition: 'middle',
									legendOffset: -80
								}}
								isInteractive={true}
								role="application"
								ariaLabel="Nivo bar chart demo"
								barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
							/>
						</div>

						<div className="row mt-5">
							<div className="col-md-6 mt-4">
								<div style={{ height: 400 }}>
									<p>Preventivi creati</p>
									<ResponsiveBar
										data={result.data_created}
										keys={['total']}
										indexBy={'month'}
										margin={{ top: 50, right: 50, bottom: 70, left: 100 }}
										valueScale={{ type: 'linear' }}
										padding={0.15}
										groupMode={'grouped'}
										indexScale={{ type: 'band', round: true }}
										colors={{ scheme: 'dark2' }}
										labelSkipWidth={12}
										labelSkipHeight={12}
										labelTextColor={{
											from: 'theme',
											modifiers: [
												[
													'brighter',
													1.6
												]
											]
										}}
										enableLabel={false}
										theme={{
											tooltip: {
												container: {
													background: '#333',
													color: '#fff'
												}
											},
											background: '#111',
											textColor: '#fff',
											fontSize: '14px',
											tickColor: '#fff',
										}}
										axisBottom={{
											tickSize: 10,
											tickPadding: 5,
											tickRotation: -30,
											legend: 'Mese',
											legendPosition: 'start',
											legendOffset: 40
										}}
										axisLeft={{
											tickSize: 10,
											tickPadding: 5,
											tickRotation: 0,
											tickValues: 5,
											legend: 'Totale',
											legendPosition: 'middle',
											legendOffset: -80
										}}
										isInteractive={true}
										role="application"
									/>
								</div>
							</div>
							<div className="col-md-6 mt-4">
								<div style={{ height: 400 }}>
									<p>Preventivi firmati</p>
									<ResponsiveBar
										data={result.data_signed}
										keys={['total']}
										indexBy={'month'}
										margin={{ top: 50, right: 50, bottom: 70, left: 100 }}
										valueScale={{ type: 'linear' }}
										padding={0.15}
										groupMode={'grouped'}
										indexScale={{ type: 'band', round: true }}
										colors={{ scheme: 'dark2' }}
										labelSkipWidth={12}
										labelSkipHeight={12}
										labelTextColor={{
											from: 'theme',
											modifiers: [
												[
													'brighter',
													1.6
												]
											]
										}}
										enableLabel={false}
										theme={{
											tooltip: {
												container: {
													background: '#333',
													color: '#fff'
												}
											},
											background: '#111',
											textColor: '#fff',
											fontSize: '14px',
											tickColor: '#fff',
										}}
										axisBottom={{
											tickSize: 10,
											tickPadding: 5,
											tickRotation: -30,
											legend: 'Mese',
											legendPosition: 'start',
											legendOffset: 40
										}}
										axisLeft={{
											tickSize: 10,
											tickPadding: 5,
											tickRotation: 0,
											tickValues: 5,
											legend: 'Totale',
											legendPosition: 'middle',
											legendOffset: -80
										}}
										isInteractive={true}
									/>
								</div>
							</div>
							<div className="col-md-12 mt-5">
								<div style={{ height: 400, marginTop: 10 }}>
									<p>Converison Rate %</p>
									<ResponsiveLine
										data={[
											{
												id: 'conversion_rate',
												data: result.data_conversion_rate
											}
										]}
										margin={{ top: 50, right: 50, bottom: 70, left: 100 }}
										colors={{ scheme: 'dark2' }}
										xScale={{ type: 'point' }}
										yScale={{
											type: 'linear',
											min: 'auto',
											max: '100',
											stacked: true,
											reverse: false
										}}
										yFormat=" >-.2f"
										theme={{
											tooltip: {
												container: {
													background: '#333',
													color: '#fff'
												}
											},
											background: '#111',
											textColor: '#fff',
											fontSize: '14px',
											tickColor: '#fff',
										}}
										axisBottom={{
											tickSize: 10,
											tickPadding: 5,
											tickRotation: -30,
											legend: 'Mese',
											legendPosition: 'start',
											legendOffset: 40
										}}
										axisLeft={{
											tickSize: 10,
											tickPadding: 5,
											tickRotation: 0,
											tickValues: 5,
											legend: 'Totale',
											legendPosition: 'middle',
											legendOffset: -80
										}}
										useMesh={true}
										pointSize={10}
										pointColor={{ theme: 'background' }}
										pointBorderWidth={1}
										pointBorderColor={{ from: 'serieColor' }}
										pointLabelYOffset={-12}

									/>
								</div>
							</div>
						</div>
					</div>
					: <p>Caricamento risorse in corso...</p>
			}
		</div>
	)
}

export default ContractsPanels;