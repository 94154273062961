import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// contexts
import { HostingProvider } from '../contexts/accounting-contexts/hosting-context';

// components
import AccountingHosting from '../components/accounting-components/hosting-area';
import SingleHostingUserInfo from '../components/accounting-components/hosting-area/user-info';
import ListHostingSubscriptions from '../components/accounting-components/hosting-area/subscriptions';
import SubscriptionsCalendar from '../components/accounting-components/hosting-area/subscriptions/calendar';
import SingleHostingSubscription from '../components/accounting-components/hosting-area/subscriptions/single-subscription';


const Hosting = ({ match }) => {
	return (
		<BrowserRouter>
			<HostingProvider>
				<Switch>
					<Route exact path={`${match.path}`} component={AccountingHosting} />
					<Route exact path={`${match.path}/single-user/:id`} component={SingleHostingUserInfo} />
					<Route exact path={`${match.path}/subscriptions`} component={ListHostingSubscriptions} />
					<Route exact path={`${match.path}/subscriptions/calendar`} component={SubscriptionsCalendar} />
					<Route exact path={`${match.path}/subscriptions/:id`} component={SingleHostingSubscription} />
				</Switch>
			</HostingProvider>
		</BrowserRouter>
	)
}

export default Hosting;