import React from 'react';

const Privacy = () => {
	return (
		<div className="privacy-page container">
			<h1>Privacy & Cookie Policy </h1>
			<p>Questo gestionale ERP di Anfutravel s.r.l., all'indirizzo <a href="https://dashboard.masamune.it/">https://dashboard.masamune.it/</a> raccoglie i dati personali dei propri utenti.</p>
			
			
			<h1>Titolare del Trattamento dei dati</h1>
			<p>Anfutravel s.r.l. – Via Anfuso, 2 – 95126 Catania, Italia – P.IVA: 05310200877</p>
			<p>Indirizzo email: <a href="mailto:amministrazione@masamune.it">amministrazione@masamune.it</a></p>
			
			
			<h1>Tipologie di dati raccolti</h1>
			<p>Fra i dati personali raccolti da questo ERP, in modo autonomo o tramite terze parti, ci sono: cookie; dati di utilizzo; email; nome; cognome; numero di telefono; varie tipologie di dati personali e societari; codice fiscale; partita iva; dati relativi al pagamento; indirizzo; ragione sociale. Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.</p>
			
			<p>I dati personali sono inseriti da Anfutravel s.r.l. dopo essere stati liberamente e deliberatamente forniti dall’utente o, nel caso di dati di utilizzo, raccolti automaticamente durante l’uso di questo ERP. Se non diversamente specificato, tutti i dati richiesti da questo ERP sono obbligatori. Se l’utente rifiuta di comunicarli, potrebbe essere impossibile per questo ERP fornire il servizio. Nei casi in cui questo ERP indichi alcuni dati come facoltativi, gli utenti sono liberi di astenersi dal comunicare tali dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del servizio o sulla sua operatività Gli utenti che dovessero avere dubbi su quali dati siano obbligatori, sono incoraggiati a contattare il titolare.</p>
			
			<p>L’eventuale utilizzo di Cookie – o di altri strumenti di tracciamento – da parte di questo ERP o dei titolari dei servizi terzi utilizzati da questo ERP, ove non diversamente precisato, ha la finalità di fornire il servizio richiesto dall’utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile. L’utente si assume la responsabilità dei dati personali di terzi ottenuti, pubblicati o condivisi mediante questo ERP e garantisce di avere il diritto di comunicarli o diffonderli, liberando il titolare da qualsiasi responsabilità verso terzi.</p>
			
			
			<h1>Modalità e luogo del trattamento dei dati raccolti</h1>
			
			<p className="fw-bold">Modalità di trattamento</p>
			<p>Il titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei dati personali.</p>
			<p>Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. </p>
			<p>Oltre al titolare, in alcuni casi, potrebbero avere accesso ai dati altri soggetti coinvolti nell’organizzazione di questo ERP (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del titolare. </p>
			<p>L’elenco aggiornato dei responsabili potrà sempre essere richiesto al titolare del Trattamento.</p>
			
			
			<h1>Base giuridica del trattamento</h1>
			<p>Il titolare tratta dati personali relativi all’utente in caso sussista una delle seguenti condizioni:</p>
			
			<ul>
			<li>l’utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti il titolare può essere autorizzato a trattare dati personali senza che debba sussistere il consenso dell’utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento di dati personali sia regolato dalla legislazione europea in materia di protezione dei dati personali;</li>
			<li>il trattamento è necessario all’esecuzione di un contratto con l’utente e/o all’esecuzione di misure precontrattuali;</li>
			<li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il titolare;</li>
			<li>il trattamento è necessario per l’esecuzione di un compito di interesse pubblico o per l’esercizio di pubblici poteri di cui è investito il titolare;</li>
			<li>il trattamento è necessario per il perseguimento del legittimo interesse del titolare o di terzi.</li>
			</ul>
			
			<p>È comunque sempre possibile richiedere al titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.</p>
			
			
			<h1>Luogo del trattamento</h1>
			<p>I dati sono trattati presso le sedi operative del titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il titolare. I dati personali dell’utente potrebbero essere trasferiti in un paese diverso da quello in cui l’utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei dati personali. L’utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal titolare per proteggere i dati. L’utente può verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione di questo documento relativa ai dettagli sul trattamento di dati personali o chiedere informazioni al titolare contattandolo agli estremi riportati in apertura.</p>
			
			
			<h1>Periodo di conservazione</h1>
			<p>I dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti. Pertanto:</p>
			
			<ul>
			<li>I dati personali raccolti per scopi collegati all’esecuzione di un contratto tra il titolare e l’utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.</li>
			<li>I dati personali raccolti per finalità riconducibili all’interesse legittimo del titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal titolare nelle relative sezioni di questo documento o contattando il titolare.</li>
			</ul>
			
			<p>Quando il trattamento è basato sul consenso dell’utente, il titolare può conservare i dati personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il titolare potrebbe essere obbligato a conservare i dati personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.</p>
			
			<p>Al termine del periodo di conservazione i dati personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei dati non potranno più essere esercitati.</p>
			
			
			<h1>Finalità del Trattamento dei dati raccolti</h1>
			<p>I dati dell’utente sono raccolti per consentire al titolare di fornire i propri Servizi, così come per le seguenti finalità: protezione dallo spam, statistica, ottimizzazione e distribuzione del traffico, visualizzazione di contenuti da piattaforme esterne, gestione contatti e invio di messaggi, contattare l’utente e gestione dei pagamenti.</p>
			<p>Per ottenere ulteriori informazioni dettagliate sulle finalità del trattamento e sui dati personali concretamente rilevanti per ciascuna finalità, l’utente può fare riferimento alle relative sezioni di questo documento.</p>
			
			
			<h1>Dettagli sul trattamento dei dati personali</h1>
			<p>I dati personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:</p>
			<p>Iscrizione e login all’ ERP.</p>
			
			<p>L’utente, accettando l’iscrizione e la conseguente aggiunta al ERP, acconsente all’utilizzo dei propri dati a fini statistici, di gestione aziendale, dei compensi, del maturato e per l’invio di comunicazioni. Dati personali raccolti: cognome; email; nome; numero di telefono; varie tipologie di dati sulla mansione/incarichi/forniture.</p>
			
			<h1>Gestione contatti e invio di messaggi</h1>
			<p>Questo tipo di servizi consente di gestire un database di contatti email, contatti telefonici o contatti di qualunque altro tipo, utilizzati per comunicare con l’utente.</p>
			<p>Questi servizi potrebbero inoltre consentire di raccogliere dati relativi alla data e all’ora di visualizzazione dei messaggi da parte dell’utente, così come all’interazione dell’utente con essi, come le informazioni sui click sui collegamenti inseriti nei messaggi.</p>
			
			<h1>Sendgrid</h1>
			<p>Sendgrid è un servizio di gestione indirizzi e invio di messaggi email fornito da Twilio Inc.</p>
			
			<p>Dati personali raccolti: cognome; email; nome.</p>
			<p>Luogo del trattamento: 375 Beale Street, Suite 300, San Francisco, CA 94105. </p>
			<p>Soggetto aderente al Privacy Shield.</p>
			
			<h1>Cloudflare (Cloudflare Inc.)</h1>
			<p>Cloudflare è un servizio di ottimizzazione e distribuzione del traffico fornito da Cloudflare Inc. Le modalità di integrazione di Cloudflare prevedono che questo filtri tutto il traffico di questo ERP, ossia le comunicazioni fra questo ERP ed il browser dell’utente, permettendo anche la raccolta di dati statistici su di esso.</p>
			
			<p>Dati personali raccolti: Cookie; varie tipologie di dati secondo quanto specificato dalla privacy policy del servizio.</p>
			<p>Luogo del trattamento: Stati Uniti – Privacy Policy. </p>
			<p>Soggetto aderente al Privacy Shield.</p>
			
			<h1>Protezione dallo SPAM</h1>
			<p>Questo tipo di servizi analizzano il traffico di questo ERP, potenzialmente contenente dati personali degli Utenti, al fine di filtrarlo da parti di traffico, messaggi e contenuti riconosciuti come SPAM.</p>
			
			<h1>Google Fonts (Google Ireland Limited)</h1>
			<p>Google Fonts è un servizio di visualizzazione di stili di carattere gestito da Google Ireland Limited che permette a questo ERP di integrare tali contenuti all’interno delle proprie pagine.</p>
			
			<p>Dati personali raccolti: dati di utilizzo; varie tipologie di dati secondo quanto specificato dalla privacy policy del servizio.</p>
			<p>Luogo del trattamento: Irlanda – Privacy Policy. </p>
			<p>Soggetto aderente al Privacy Shield.</p>
			
			
			<h1>Diritti dell’utente</h1>
			<p>Gli Utenti possono esercitare determinati diritti con riferimento ai dati trattati dal titolare.</p>
			
			<p>In particolare, l’utente ha il diritto di:</p>
			
			<ul>
			<li>Revocare il consenso in ogni momento. L’utente può revocare il consenso al trattamento dei propri dati personali precedentemente espresso.</li>
			<li>Opporsi al trattamento dei propri dati. L’utente può opporsi al trattamento dei propri dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.</li>
			<li>Accedere ai propri dati. L’utente ha diritto ad ottenere informazioni sui dati trattati dal titolare, su determinati aspetti del trattamento ed a ricevere una copia dei dati trattati.</li>
			<li>Verificare e chiedere la rettificazione. L’utente può verificare la correttezza dei propri dati e richiederne l’aggiornamento o la correzione.</li>
			<li>Ottenere la limitazione del trattamento. Quando ricorrono determinate condizioni, l’utente può richiedere la limitazione del trattamento dei propri dati. In tal caso il titolare non tratterà i dati per alcun altro scopo se non la loro conservazione.</li>
			<li>Ottenere la cancellazione o rimozione dei propri dati personali. Quando ricorrono determinate condizioni, l’utente può richiedere la cancellazione dei propri dati da parte del titolare.</li>
			<li>Ricevere i propri dati o farli trasferire ad altro titolare. L’utente ha diritto di ricevere i propri dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa disposizione è applicabile quando i dati sono trattati con strumenti automatizzati ed il trattamento è basato sul consenso dell’utente, su un contratto di cui l’utente è parte o su misure contrattuali ad esso connesse.</li>
			<li>Esporre reclamo. L’utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.</li>
			</ul>
			
			
			<h1>Dettagli sul diritto di opposizione</h1>
			<p>Quando i dati personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il titolare oppure per perseguire un interesse legittimo del titolare, gli utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</p>
			<p>Si fa presente agli utenti che, ove i loro dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il titolare tratti dati con finalità di marketing diretto gli utenti possono fare riferimento alle rispettive sezioni di questo documento.</p>
			
			
			<h1>Come esercitare i diritti</h1>
			<p>Per esercitare i propri diritti, gli utenti possono indirizzare una richiesta agli estremi di contatto del titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito ed evase dal titolare nel più breve tempo possibile, in ogni caso entro un mese.</p>
			
			
			<h1>Cookie Policy</h1>
			<p>Questo ERP fa utilizzo di Cookie. Per saperne di più e per prendere visione dell’informativa dettagliata, l’utente può consultare la Cookie Policy.</p>
			
			
			<h1>Ulteriori informazioni sul trattamento</h1>
			
			<p className="fw-bold">Difesa in giudizio</p>
			<p>I dati personali dell’utente possono essere utilizzati da parte del titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell’utilizzo di questo ERP o dei Servizi connessi da parte dell’utente.</p>
			<p>L’utente dichiara di essere consapevole che il titolare potrebbe essere obbligato a rivelare i dati per ordine delle autorità pubbliche.</p>
			
			<p className="fw-bold">Informative specifiche</p>
			<p>Su richiesta dell’utente, in aggiunta alle informazioni contenute in questa privacy policy, questo ERP potrebbe fornire all’utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di dati personali.</p>
			
			<p className="fw-bold">Log di sistema e manutenzione</p>
			<p>Per necessità legate al funzionamento ed alla manutenzione, questo ERP e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche dati personali, quali l’indirizzo IP utente.</p>
			
			<p className="fw-bold">Informazioni non contenute in questa policy</p>
			<p>Ulteriori informazioni in relazione al trattamento dei dati personali potranno essere richieste in qualsiasi momento al titolare del Trattamento utilizzando gli estremi di contatto.</p>
			
			<p className="fw-bold">Risposta alle richieste “Do Not Track”</p>
			<p>Questo ERP non supporta le richieste “Do Not Track”.</p>
			<p>Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l’utente è invitato a consultare le rispettive privacy policy.</p>
			
			<p className="fw-bold">Modifiche a questa privacy policy</p>
			<p>Il titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento dandone informazione agli Utenti su questa pagina e, se possibile, su questo ERP nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso il titolare . Si prega dunque di consultare regolarmente questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo. Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il titolare provvederà a raccogliere nuovamente il consenso dell’utente, se necessario.</p>
			
			
			<h1>Definizioni e riferimenti legali</h1>
			
			<p><strong>Dati personali (o dati)</strong>: costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
			
			<p><strong>Dati di Utilizzo</strong>: sono le informazioni raccolte automaticamente attraverso questo ERP (anche da applicazioni di parti terze integrate in questo ERP), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’utente che si connette con questo ERP, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’utente.</p>
			
			<p><strong>Credenziali di Accesso</strong>: significa il sistema di autenticazione attraverso il quale è possibile accedere e utilizzare la piattaforma ERP, inclusi i codici di identificazione e le chiavi di accesso associati a ciascun Utente e gli eventuali token.</p>
			
			<p><strong>Cookie</strong>: I cookie sono frammenti di dati sugli utenti memorizzati sul computer e utilizzati per migliorare la navigazione. I cookie, anche conosciuti come cookie HTTP, web, Internet o del browser, vengono creati dal server e inviati sul browser dell’utente. </p>
			
			<p><strong>ERP</strong>: Enterprise Resource Planning (ERP) si riferisce a un tipo di software che le organizzazioni utilizzano per gestire le attività commerciali quotidiane, come ad esempio contabilità, procurement, project management, gestione del rischio e compliance e operations per la supply chain. Una suite ERP completa include anche delle funzioni di enterprise performance management, cioè risorse che aiutano a pianificare, quantificare, prevedere e comunicare i risultati finanziari di un'organizzazione.</p>
			
			<p><strong>Parti</strong>: significa, congiuntamente, Anfutravel s.r.l. e l’Utente.</p>
			
			<p><strong>PEC</strong>: significa posta elettronica certificata.</p>
			
			<p><strong>Proprietà Intellettuale</strong>: significa ogni diritto di proprietà intellettuale e/o industriale, registrato o non registrato, in tutto o in parte, ovunque nel mondo, quali - a titolo esemplificativo e non esaustivo - marchi, brevetti, modelli di utilità, disegni e modelli, nomi a dominio, know-how, opere coperte dal diritto d’autore, database e software (ivi inclusi, ma non limitatamente a, le sue derivazioni, il codice sorgente, il codice oggetto e le interfacce).</p>
			
			<p><strong>Saas</strong>: significa software-as-a-service.</p>
			
			<p><strong>Servizi Cloud</strong>: significa i servizi forniti da Anfutravel s.r.l. all’utente attraverso l’accesso e l’utilizzo, da parte di quest’ultimo, del Gestionale ERP.</p>
			
			<p><strong>Utente</strong>: significa ciascun dipendente e/o collaboratore di Anfutravel s.r.l., da quest’ultimo autorizzato ad utilizzare le Credenziali di Accesso per accedere e utilizzare l’ERP al fine di usufruirne.</p>
			
			<p><strong>Responsabile del Trattamento (o Responsabile)</strong>: la persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del titolare, secondo quanto esposto nella presente privacy policy.</p>
			
			<p><strong>Titolare del Trattamento (o titolare)</strong>: la persona fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questo ERP. Il titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questo ERP.</p>
			
			<p><strong>Riferimenti legali</strong>: la presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.</p>
			<p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questo ERP.</p>

		</div >
	)
}

export default Privacy;