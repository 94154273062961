import React from 'react';


import TopBarSection from '../../../ui-components/top-bar-section';
import { SettingsMainWrapper, SettingsMainOption } from './components';

const SettingsMain = () => {
  return (
    <div className="settings-page">
      <TopBarSection pageName="Impostazioni" />

      <SettingsMainWrapper>
        <SettingsMainOption>
          <a href="/impostazioni/sincronizza">
            <h2>Importa</h2>
            <hr />
            <p>Importa i clienti, servizi, fatture, preventivi e note credito presenti su FIC.</p>
          </a>
        </SettingsMainOption>

        <SettingsMainOption>
          <a href="/impostazioni/compensi">
            <h2>Compensi Fornitori</h2>
            <hr />
            <p>NB. Prima sincronizza le fatture e i servizi.</p>
          </a>
        </SettingsMainOption>
      </SettingsMainWrapper>
    </div>
  );
};

export default SettingsMain;