import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// contexts
import { AccountingProvider } from '../contexts/accounting-contexts/accounting-context';
import { HostingProvider } from '../contexts/accounting-contexts/hosting-context';
import { MasamuneServicesProvider } from '../contexts/accounting-contexts/masamune-services-context';

// components
import AccountingArea from '../components/accounting-components/main-area';
import AccountingMasamuneServices from '../components/accounting-components/masamune-services';
import MasamuneServiceSingleInvoice from '../components/accounting-components/masamune-services/single-invoice';
import AccountingMasamuneHourlyConsulting from '../components/accounting-components/hourly-consulting';
import AccountingMasamuneTalentSourcing from '../components/accounting-components/talent-sourcing';

const Accounting = ({ match }) => {
	return (
		<BrowserRouter>
			<AccountingProvider>
				<MasamuneServicesProvider>
					<HostingProvider>
						<Switch>
							<Route exact path={`${match.path}`} component={AccountingArea} />

							<Route exact path={`${match.path}/masamune`} component={AccountingMasamuneServices} />
							<Route exact path={`${match.path}/masamune/talent-sourcing`} component={AccountingMasamuneTalentSourcing} />
							<Route exact path={`${match.path}/masamune/hourly-consulting`} component={AccountingMasamuneHourlyConsulting} />
							<Route exact path={`${match.path}/masamune/:id`} component={MasamuneServiceSingleInvoice} />
						</Switch>
					</HostingProvider>
				</MasamuneServicesProvider>
			</AccountingProvider>
		</BrowserRouter>
	)
}

export default Accounting;