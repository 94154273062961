import styled from 'styled-components';

export const QuarterSectionBonus = styled.div`
	padding: 15px;
	width: 100%;
	flex: 0 100%;
	transition: all .3s ease;
	cursor: pointer;
	min-width: 100px;
	border-radius: 4px;

	&.success-bonus {
		background: #46b725;
	}

	&.no-completed-bonus {
		background: #cb4e4e;
	}

	p {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 0;
	}

	span {
		font-size: 12px;

		&.false {
			color: #DB7F7F;
		}

		&.ok {
			color: #81BB6D;
		}
	}
`;