import React from 'react';

const Pagination = ({ length = 0, limit = 24, offset = 0, setOffset = () => { } }) => {
  let x = Math.ceil(length / limit);

  const getPagination = () => {
    let items = [];

    for (let i = 0; i < x; i++) {
      items.push(<li key={i} className="page-item"><button style={{ color: '#fff' }} onClick={() => setOffset(i * limit)} className={offset === i*limit ? 'page-link active' : 'page-link'}>{i + 1}</button></li>)
    }

    return items
  }

  return (
    <nav className="pagination-section">
      <ul className="pagination">
        <li className="page-item">
          <button className="page-link" style={{ color: '#fff' }} onClick={() => { if (offset > 0) setOffset(offset - limit) }} aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {getPagination()}
        <li className="page-item">
          <button className="page-link" style={{ color: '#fff' }} onClick={() => { setOffset(offset + limit) }} aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination;