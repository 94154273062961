import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';

import { useBonus } from '../../../contexts/accounting-contexts/bonus-context';
import { QuarterSectionBonus } from './bonus-components';
import { number_format } from '../../../utils';
import TopBarSection from '../../../ui-components/top-bar-section';

const BonusAreaSales = ({ match }) => {
	const { id } = match.params;
	const { getBonusSingleSeller, storeCompsBonusSales } = useBonus();

	const [data, setData] = useState(null);
	const [year, setYear] = useState(new Date().getFullYear());
	const [refresh, setRefresh] = useState(0);

	useEffect(() => {
		const fetchBonusSingleSeller = async () => {
			const { data, error } = await getBonusSingleSeller(id, year);
			if (error !== null) return alert(error.data.error);
			setData(data);
		}

		fetchBonusSingleSeller();
	}, [year, refresh, getBonusSingleSeller, id]);

	const handleStoreCompsBonusAgents = async () => {
		const { error } = await storeCompsBonusSales(id, year);
		if (error !== null) return alert(error.data.error);
		setRefresh(x => x + 1);
	}

	return (
		<div className="bonus-area-sales">
			<TopBarSection pageName="Bonus Agente" handleChangeYear={setYear} />

			{
				data !== null ?
					<div className="component">
						{data.has_achieved_target && !data.stored_payment ? <p>Obiettivo raggiunto, ecco il bonus: <strong>&euro; {number_format(data.bonus, 2, ',', '.')}</strong></p> : null}
						{data.has_achieved_target && data.stored_payment ? <p>Obiettivo raggiunto e pagato</p> : <p>Obiettivo non raggiunto</p>}

						{!data.has_achieved_target && data.a_quarter_was_achieved ? <p>Un obiettivo trimestrale è stato raggiunto!</p> : null}
						{!data.has_achieved_target && data.a_quarter_was_achieved ? <p>Bonus disponibile: <strong>&euro; {number_format(data.bonus, 2, ',', '.')}</strong></p> : null}

						{data.has_achieved_target && !data.stored_payment ? <button onClick={handleStoreCompsBonusAgents} className="btn btn-primary">Dividi i compensi</button> : null}
						{!data.has_achieved_target && data.a_quarter_was_achieved && !data.stored_payment_splitted ? <button onClick={handleStoreCompsBonusAgents} className="btn btn-primary">Dividi i compensi 50%</button> : null}

						<hr />

						<div className="row">
							<div className="col-md-3">
								<QuarterSectionBonus className={data.quarters[0].has_achieved_quarter_target ? "success-bonus" : "no-completed-bonus"}>
									<p className="mb-2">Primo trimestre</p>
									<p><strong>Obiettivo: </strong>&euro; {number_format(data.quarters[0].amount_target, 2, ',', '.')}</p>
									<p><strong>Totale: </strong>&euro; {number_format(data.quarters[0].amount_quarter, 2, ',', '.')}</p>
								</QuarterSectionBonus>
							</div>
							<div className="col-md-3">
								<QuarterSectionBonus className={data.quarters[1].has_achieved_quarter_target ? "success-bonus" : "no-completed-bonus"}>
									<p className="mb-2">Secondo trimestre</p>
									<p><strong>Obiettivo: </strong>&euro; {number_format(data.quarters[1].amount_target, 2, ',', '.')}</p>
									<p><strong>Totale: </strong>&euro; {number_format(data.quarters[1].amount_quarter, 2, ',', '.')}</p>
								</QuarterSectionBonus>
							</div>
							<div className="col-md-3">
								<QuarterSectionBonus className={data.quarters[2].has_achieved_quarter_target ? "success-bonus" : "no-completed-bonus"}>
									<p className="mb-2">Terzo trimestre</p>
									<p><strong>Obiettivo: </strong>&euro; {number_format(data.quarters[2].amount_target, 2, ',', '.')}</p>
									<p><strong>Totale: </strong>&euro; {number_format(data.quarters[2].amount_quarter, 2, ',', '.')}</p>
								</QuarterSectionBonus>
							</div>
							<div className="col-md-3">
								<QuarterSectionBonus className={data.quarters[3].has_achieved_quarter_target ? "success-bonus" : "no-completed-bonus"}>
									<p className="mb-2">Quarto trimestre</p>
									<p><strong>Obiettivo: </strong>&euro; {number_format(data.quarters[3].amount_target, 2, ',', '.')}</p>
									<p><strong>Totale: </strong>&euro; {number_format(data.quarters[3].amount_quarter, 2, ',', '.')}</p>
								</QuarterSectionBonus>
							</div>
						</div>

						<h2 className="section-title mt-3">Analisi</h2>
						<div style={{ height: 400, marginTop: 10 }}>
							<p>Maturato {year}</p>
							<ResponsiveLine
								data={[
									{
										id: 'maturato',
										data: data.data_chart
									}
								]}
								margin={{ top: 50, right: 50, bottom: 70, left: 100 }}
								colors={{ scheme: 'dark2' }}
								xScale={{ type: 'point' }}
								yScale={{
									type: 'linear',
									min: '0',
									max: 'auto',
									stacked: true,
									reverse: false
								}}
								yFormat=" >-.2f"
								theme={{
									tooltip: {
										container: {
											background: '#333',
											color: '#fff'
										}
									},
									background: '#111',
									textColor: '#fff',
									fontSize: '14px',
									tickColor: '#fff',
								}}
								axisBottom={{
									tickSize: 10,
									tickPadding: 5,
									tickRotation: -30,
									legend: 'Mese',
									legendPosition: 'start',
									legendOffset: 40
								}}
								axisLeft={{
									tickSize: 10,
									tickPadding: 5,
									tickRotation: 0,
									tickValues: 5,
									legend: 'Totale',
									legendPosition: 'middle',
									legendOffset: -80
								}}
								useMesh={true}
								pointSize={10}
								pointColor={{ theme: 'background' }}
								pointBorderWidth={1}
								pointBorderColor={{ from: 'serieColor' }}
								pointLabelYOffset={-12}

							/>
						</div>

					</div>
					: null}
		</div>
	)
};

export default BonusAreaSales;