import React, { useState } from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import FormContracts from '../../../ui-components/form-components/contracts';

import { useContract } from '../../../contexts/contract-context';
import { handlingSubmitError } from '../../../utils/handling-submit-error';
import { MasamuneServicesProvider } from '../../../contexts/accounting-contexts/masamune-services-context';
import { OperatorProvider } from '../../../contexts/operator-context';
import TopBarSection from '../../../ui-components/top-bar-section';


const NewContract = ({ closePage }) => {
	const { createQuoteEnvelope, createPartnerEnvelope, createAgentEnvelope, createPerformanceEnvelope } = useContract();

	const [year, setYear] = useState(new Date().getFullYear());

	const handleSaveEnvelope = async (body, isDisable) => {
		let error = null;

		switch (body.type_file) {
			case 'Preventivo':
				const resQuote = await createQuoteEnvelope(body);
				error = resQuote.error;
				isDisable(false);
				break;

			case 'Nuovo Partner':
				const resPartner = await createPartnerEnvelope(body);
				error = resPartner.error;
				isDisable(false);
				break;

			case 'Agente':
				const resAgent = await createAgentEnvelope(body);
				error = resAgent.error;
				isDisable(false);
				break;

			case 'Performance':
				const resPerf = await createPerformanceEnvelope(body);
				error = resPerf.error;
				isDisable(false);
				break;
			default:
				isDisable(false);
				return alert('Opzione non valida.');
		}

		if (error !== null) {
			if (error.data.error !== undefined) {
				isDisable(false);
				return alert(error.data.error)
			}

			await handlingSubmitError(error);
			isDisable(false);
			return;
		}

		closePage(false);
		return window.location.reload();
	}

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<TopBarSection closePage={closePage} pageName="Crea un nuovo contratto" handleChangeYear={setYear} />

				<OperatorProvider>
					<MasamuneServicesProvider>
						<FormContracts handleSave={handleSaveEnvelope} data={null} year={year} />
					</MasamuneServicesProvider>
				</OperatorProvider>
			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default NewContract;