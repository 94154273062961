export const handlingSubmitError = async (error) => {
  try {
    // clear all errors span
    const items = document.getElementsByClassName('errors');
    
    for (let i = 0; i < items.length; i++) {
      items[i].innerText = '';
    }
    
    var errors = Array(error.data);
    for (let i = 0; i < errors.length; i++) {
      var keys = Object.keys(errors[i]);
      for (let j = 0; j < keys.length; j++) {
        document.getElementById(`error_${keys[j]}`).innerText = errors[i][keys[j]]
      }
    }
  } catch (err) {
    console.error('Handler Error Failder', err);
    return false;
  }

  return true;
}