import React from 'react'

import { httpPost } from '../http';
import { SYNC_ENDPOINT } from '../constants/API_ENDPOINT';

import { useAuth } from './auth-context';

const SyncContext = React.createContext()

const SyncProvider = (props) => {
	const { jwtToken } = useAuth();

	const syncInvoices = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${SYNC_ENDPOINT}/invoices/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const syncQuotes = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${SYNC_ENDPOINT}/quotes/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const syncServices = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${SYNC_ENDPOINT}/services/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const syncCustomers = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${SYNC_ENDPOINT}/customers/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const syncCreditNotes = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${SYNC_ENDPOINT}/credit-notes/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	return (
		<SyncContext.Provider
			value={{
				syncInvoices,
				syncServices,
				syncQuotes,
				syncCustomers,
				syncCreditNotes
			}}
			{...props}
		/>
	)
}
const useSync = () => React.useContext(SyncContext)

export { SyncProvider, useSync }