import React, { useState } from 'react';

import { useSync } from '../../../contexts/sync-context';
import TopBarSection from '../../../ui-components/top-bar-section';

const SettingsSyncs = () => {
  const { syncInvoices, syncServices, syncQuotes, syncCustomers, syncCreditNotes } = useSync();

  const [ok, setOk] = useState(null);
  const [importantMessage, setImportantMessage] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleSyncInvoices = async () => {
    setDisabled(true);
    setOk(null);
    setMessage(null);

    const { error } = await syncInvoices({ year, month });

    setDisabled(false);

    if (error !== null) {
      setOk(false);
      setMessage("Impossibile sincronizzare le fatture. Riprova tra 10 minuti.");
      return ;
    }

    setOk(true);
    setImportantMessage(true);
    setMessage("Fatture sincronizzate.");
    return ;
  }

  const handleSyncQuotes = async () => {
    setDisabled(true);
    setOk(null);
    setMessage(null);

    const { error } = await syncQuotes({ year, month });

    setDisabled(false);

    if (error !== null) {
      setOk(false);
      setMessage("Impossibile sincronizzare i preventivi. Riprova tra 10 minuti.");
      return ;
    }

    setOk(true);
    setMessage("Preventivi sincronizzati.");
    return ;
  }

  const handleSyncServices = async () => {
    setDisabled(true);
    setOk(null);
    setMessage(null);

    const { error } = await syncServices({ year, month });

    setDisabled(false);

    if (error !== null) {
      setOk(false);
      setMessage("Impossibile sincronizzare i servizi. Riprova tra 10 minuti.");
      return ;
    }

    setOk(true);
    setMessage("Servizi sincronizzati.");
    return ;
  }

  const handleSyncCustomers = async () => {
    setDisabled(true);
    setOk(null);
    setMessage(null);

    const { error } = await syncCustomers();

    setDisabled(false);

    if (error !== null) {
      setOk(false);
      setMessage("Impossibile sincronizzare i clienti. Riprova tra 10 minuti.");
      return ;
    }

    setOk(true);
    setMessage("Clienti sincronizzati.");
    return ;
  }

  const handleSyncCreditNotes = async () => {
    setDisabled(true);
    setOk(null);
    setMessage(null);

    const { error } = await syncCreditNotes();

    setDisabled(false);

    if (error !== null) {
      setOk(false);
      setMessage("Impossibile sincronizzare le note credito. Riprova tra 10 minuti.");
      return ;
    }

    setOk(true);
    setMessage("Note Credito sincronizzati.");
    return ;
  }

  return (
    <div className="settings-syncs-page">
      <TopBarSection pageName="Impostazioni - Sincronizza" handleChangeYear={setYear} handleChangeMonth={setMonth} />

			<div className="alert alert-info">
				<p className='mb-1'>Qui puoi importare i Clienti, Servizi, Fatture, Preventivi, Note Credito presenti salvati su Fatture in Cloud</p>
				<strong className='d-block'>Una volta che hai cliccato un pulsante non ricaricare la pagina.</strong>
			</div>

      {
        ok !== null && message !== null ?
        <div className={ ok && importantMessage ? 'alert alert-success alert-dismissible mb-3' : !ok ? 'alert alert-danger alert-dismissible mb-3' : ok && !importantMessage ? 'alert alert-warning alert-dismissible mb-3' : 'alert alert-danger alert-dismissible mb-3' }>
          <strong>{message}</strong>
          <button type="button" className="btn-close" onClick={() => setOk(null)}></button>
        </div>
        : null
      }

      <button disabled={disabled} onClick={handleSyncInvoices} className="btn btn-primary">Fatture Clienti</button>
      <button disabled={disabled} onClick={handleSyncQuotes} className="btn btn-primary ms-2">Preventivi</button>
      <button disabled={disabled} onClick={handleSyncCreditNotes} className="btn btn-primary ms-2">Note Credito</button>
      <button disabled={disabled} onClick={handleSyncCustomers} className="btn btn-primary ms-2">Clienti</button>
      <button disabled={disabled} onClick={handleSyncServices} className="btn btn-primary ms-2">Servizi</button>
    </div>
  )
};

export default SettingsSyncs;