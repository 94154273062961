import React, { useEffect, useState } from "react";
import moment from "moment";

import ApproveReferralModal from "./approve-referral-modal";
import UpdateExpireDateReferralModal from "./update-expire-date-modal";

import { handlingSubmitError } from "../../../utils/handling-submit-error";
import { useReferral } from "../../../contexts/referral-context";
import { referralsConstants } from "../../../constants/API_ACTIONS";
import FormReferral from "../../../ui-components/form-components/referrals";
import { number_format, Padder } from "../../../utils";
import { useAuth } from "../../../contexts/auth-context";

const UpdateReferral = (props) => {
	const { id } = props.match.params;
	const { role } = useAuth();
	const {
		updateReferral,
		getSingleReferral,
		actionSingleReferral,
		setReferralCompPayed,
	} = useReferral();

	const [referral, setReferral] = useState(null);
	const [referralComps, setReferralComps] = useState([]);

	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showUpdateExpireModal, setShowUpdateExpireModal] = useState(false);

	const [refresh, setRefresh] = useState(0);

	useEffect(() => {
		const fetchSingleReferral = async () => {
			const { data, error } = await getSingleReferral(id);

			if (error !== null) {
				if (error.data.error !== undefined) {
					return alert(error.data.error);
				}

				return;
			}

			setReferral(data.results);
			setReferralComps(data.comps);
		};

		fetchSingleReferral();
	}, [getSingleReferral, id, refresh]);

	const handleSaveOperator = async (data) => {
		// update user
		const { error } = await updateReferral(id, { ...data });

		if (error !== null) {
			if (error.data.error !== undefined) {
				return alert(error.data.error);
			}

			await handlingSubmitError(error);
		}

		return window.location.reload();
	};

	const storeOnFic = async () => {
		const { error } = await actionSingleReferral(
			id,
			referralsConstants.STORE_REFERRAL_ON_FIC,
			{}
		);
		if (error !== null) return alert(error.data.error);
		alert("Referral aggiunto su FIC.");
		return setRefresh((x) => x + 1);
	};

	const approveReferral = async (value) => {
		const { error } = await actionSingleReferral(
			id,
			referralsConstants.APPROVE_REFERRAL,
			{ value }
		);
		if (error !== null) return alert(error.data.error);
		alert("Referral approvato.");
		setShowApproveModal(false);
		return setRefresh((x) => x + 1);
	};

	const refuseReferral = async () => {
		const { error } = await actionSingleReferral(
			id,
			referralsConstants.REFUSE_REFERRAL,
			{}
		);
		if (error !== null) return alert(error.data.error);
		alert("Referral rifiutato.");
		return setRefresh((x) => x + 1);
	};

	const updateExpireDate = async (value) => {
		const { error } = await actionSingleReferral(
			id,
			referralsConstants.UPDATE_EXPIRE_DATE,
			{ value }
		);
		if (error !== null) return alert(error.data.error);
		alert("Scadenza referral aggiornata.");
		setShowUpdateExpireModal(false);
		return setRefresh((x) => x + 1);
	};

	const setCompPayed = async (idComp) => {
		const { error } = await setReferralCompPayed(idComp);
		if (error !== null) return alert(error.data.error);
		return setRefresh((x) => x + 1);
	};

	return (
		<div className="update-operator-page">
			<div className="top-page-form">
				<h3>Modifica Referral</h3>
			</div>

			<hr />

			{referral !== null ? (
				<>
					<FormReferral handleSave={handleSaveOperator} data={referral} />

					<hr />

					<h4 className="section-title">Azioni</h4>

					{referral.id_fic === null ? (
						<button
							onClick={() => storeOnFic()}
							className="btn btn-primary me-1"
						>
							Salva su FIC
						</button>
					) : null}
					{referral.expired_at !== null ? (
						<button
							onClick={() => setShowUpdateExpireModal(true)}
							className="btn btn-primary mx-1"
						>
							Aggiorna Data Scadenza
						</button>
					) : null}
					{referral.status !== 1 ? (
						<button
							onClick={() => setShowApproveModal(true)}
							className="btn btn-primary mx-1"
						>
							Approva
						</button>
					) : null}
					{referral.status !== 2 ? (
						<button
							onClick={() => refuseReferral()}
							className="btn btn-primary mx-1"
						>
							Rifiuta
						</button>
					) : null}

					{showApproveModal ? (
						<ApproveReferralModal
							save={approveReferral}
							close={setShowApproveModal}
						/>
					) : null}
					{showUpdateExpireModal ? (
						<UpdateExpireDateReferralModal
							save={updateExpireDate}
							close={setShowUpdateExpireModal}
						/>
					) : null}

					<hr />

					<p>
						<strong>Data inizio conteggio: </strong>{" "}
						{referral.start_count
							? moment(referral.start_count).format("DD/MM/YYYY")
							: "N/A"}{" "}
						- <strong>Data Scadenza: </strong>{" "}
						{referral.expired_at
							? moment(referral.expired_at).format("DD/MM/YYYY")
							: "N/A"}
					</p>

					<hr />

					<h4 className="section-title">% cliente</h4>
					<div class="table-responsive">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Nr. Fattura</th>
									<th>Importo</th>
									<th>Totale Pagato</th>
									<th>Data</th>
									<th>Pagato</th>
									{role === "ADMIN" || role === "SUPER ADMIN" ? (
										<th></th>
									) : null}
								</tr>
							</thead>
							<tbody>
								{referralComps.map((item, index) => {
									return (
										<tr key={index}>
											<td>#{new Padder(6).pad(item.invoice.nr_doc)}</td>
											<td>&euro; {number_format(item.amount, 2, ",", ".")}</td>
											<td>
												&euro; {number_format(item.tot_payed, 2, ",", ".")}
											</td>
											<td>{moment(item.date).format("DD/MM/YYYY")}</td>
											<td>{item.payed ? "SI" : "NO"}</td>
											{role === "ADMIN" || role === "SUPER ADMIN" ? (
												<td>
													{!item.payed ? (
														<button
															className="btn btn-primary"
															onClick={() => setCompPayed(item.id)}
														>
															Pagato
														</button>
													) : null}
												</td>
											) : null}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</>
			) : null}
		</div>
	);
};

export default UpdateReferral;
