import React, { useEffect } from 'react'

import { useAuth } from './contexts/auth-context'

import AuthenticatedApp from './components/authenticated-app'
import UnauthenticatedApp from './components/unauthenticated-app'

import './app.scss';

const App = () => {
	const auth = useAuth();

	useEffect(() => {
		var current = window.location.pathname.split('/');

		if (current.length >= 3) {
			current = `${current[1]}/${current[2]}`
		} else {
			current = current[1];
		}
		
		if (current === '') return ;
		var menuItems = document.querySelectorAll('.sidebar a');
		
		for (var i = 0, len = menuItems.length; i < len; i++) {
			if (menuItems[i].getAttribute('href').indexOf(current) !== -1) {
				menuItems[i].className += 'active';
			}
		}
	});

	return auth.isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}

export default App;