import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { faInbox, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useReferralExternal } from '../../../contexts/referra-external-context';
import { useCustomer } from '../../../contexts/customer-context';
import TopBarSection from '../../../ui-components/top-bar-section';
import { InputTextArea, InputNumber } from '../../../ui-components/forms';

import { number_format } from '../../../utils';
import LabelInfo from '../../../ui-components/label-info';

const SingleCustomer = ({ match }) => {
  const { id } = match.params;
	const { generateAccessToken } = useReferralExternal();
  const { getSingleCustomer, addNote, updateFidoValue, checkFido } = useCustomer();

  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [linkReferral, setLinkReferral] = useState("");

  useEffect(() => {
    const fetchCustomer = async () => {
      const { data, error } = await getSingleCustomer(id);
      if (error !== null) return alert(error.data.error);
      setCustomer(data.customer);
      setNotes(data.customer_notes);
      setInvoices(data.invoices);
    }

    fetchCustomer();
  }, [showNoteForm, getSingleCustomer, id]);

  const handleAddNote = async (e) => {
    e.preventDefault();

    const { error } = await addNote(id, note);
    if (error !== null) return alert(error.data.error);

    setShowNoteForm(false);
    setNote('');
  }

  const handleChangeFido = async (value) => {
    const { error } = await updateFidoValue(id, value)
    if (error !== null) return alert(error.data.error);
  }

  const handleCheckFido = async () => {
    const { error } = await checkFido(id)
    if (error !== null) return alert(error.data.error);
    window.location.reload();
  }

	const handleGenAccessLink = async () => {
		const { data, error } = await generateAccessToken(id);
		if (error !== null) return alert(error.data.error);
		setLinkReferral(data.url);

		let inputLink = document.getElementById('access-token-link-customer');
		inputLink.select();
		document.execCommand('copy');

		return alert("Link copiato!");
	}

  return (
    <div className="list-customers-components">
      <TopBarSection pageName="Informazioni cliente" />

			<input type="text" style={{ position: 'fixed', left: -10000 }} id="access-token-link-customer" value={linkReferral} />
			<button className="btn btn-primary" onClick={handleGenAccessLink}>Genera Link Referral</button>

			<hr />

      {
        customer !== null ?
          <div>
            <div className="row">
              <LabelInfo name="Nome" value={customer.name} />
              <LabelInfo name="Referente" value={customer.ref} />
              <LabelInfo name="Paese" value={customer.country} />
            </div>

            <div className="row">
              <LabelInfo name="Indirizzo" value={customer.address_st} />
              <LabelInfo name="Città" value={customer.address_city} />
              <LabelInfo name="CAP" value={customer.address_postcode} />
            </div>

            <div className="row">
              <LabelInfo name="Email" value={customer.mail} />
              <LabelInfo name="PEC" value={customer.pec} />
              <LabelInfo name="Partita IVA" value={customer.piva} />
            </div>

            <div className="row">
              <LabelInfo name="Codice Fiscale" value={customer.cf} />
            </div>

            <hr />

            <div className="section-title mb-1">Gestione FIDO</div>
            <div className="row">
              <div className="col-md-3">
                <InputNumber name="fido" type="number" step="0.01" defaultValue={customer.fido} onChange={handleChangeFido} label="FIDO" price={true} bold={true} />
              </div>
              <LabelInfo colSize='col-md-3' name="Stato FIDO" value={customer.fido_status === 0 ? 'Nei limiti' : customer.fido_status === 1 ? 'Rischio sconfinamento' : 'Oltre la soglia'} />
              <LabelInfo colSize='col-md-3' name="Ultimo controllo FIDO" value={moment(customer.fido_last_update).format('DD/MM/YYYY hh:mm A')} />
              <LabelInfo colSize='col-md-3' name="Totale debito" value={`€ ${number_format(customer.fido_debit, 2, ',', '.')}`} />
              <div className="col-md-3 mt-3">
                <button className="btn btn-primary" onClick={handleCheckFido}>Verifica FIDO</button>
              </div>
            </div>

            <hr />

            <h4 className="section-title mb-1">Note cliente <button onClick={() => setShowNoteForm(!showNoteForm)} className="badge bg-primary">Aggiungi nota</button></h4>
            {
              showNoteForm ?
                <div className="customer-add-note-form">
                  <InputTextArea name="note" label="Note" onChange={setNote} />
                  <input type="button" value="Salva" className="btn btn-primary mt-2" onClick={handleAddNote} />

                  <hr />
                </div>
                : null
            }
            {
              notes.map((item, index) => {
                return (
                  <div className="customer-note-item">
                    <InputTextArea
                      label={`Creato da ${item.created_by} il ${moment(item.created_at).format('DD/MM/YYYY')} alle ${moment(item.created_at).format('hh:mm A')}`}
                      disabled={true}
                      name="note"
                      defaultValue={item.note} />
                  </div>
                )
              })
            }

            <hr />

            <h4 className="section-title">Acquisti del cliente</h4>
            <div className="table-responsive">
<table className="table table-striped">
                <thead>
                  <tr>
                    <th>Azioni</th>
                    <th>ID</th>
                    <th>Cliente</th>
                    <th>Numero</th>
                    <th>Data</th>
                    <th>Documento</th>
                    <th>IVA</th>
                    <th>Totale</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    invoices.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <a className="btn btn-edit me-2" href={item.doc_link} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInbox} /></a>
                            <a className="btn btn-edit" href={`/accounting/masamune/${item.id}`}><FontAwesomeIcon icon={faEye} /></a>
                          </td>
                          <td>{item.id_fic}</td>
                          <td>{item.customer}</td>
                          <td>{item.nr_doc}</td>
                          <td>{moment(item.date_doc).format('DD/MM/YYYY')}</td>
                          <td>€ {number_format(item.import_doc, 2, ',', '.')}</td>
                          <td>€ {number_format(item.import_vat, 2, ',', '.')}</td>
                          <td>€ {number_format(item.import_tot, 2, ',', '.')}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table></div>
            </div>
          : null}

    </div>
  )
}

export default SingleCustomer;