import React from 'react';
import { useForm } from 'react-hook-form';

import { InputEmail, InputText } from '../../../../forms';

const FormAccountingHostingUser = ({ data = null, handleSave }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: data
  });

  const onSubmit = async (body) => {
    await handleSave(body);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-4">
          <InputText name="first_name" label="Nome" register={register} />
        </div>
        <div className="col-md-4">
          <InputText name="last_name" label="Cognome" register={register} />
        </div>
        <div className="col-md-4">
          <InputEmail name="email" label="Email" register={register} />
        </div>
        <div className="col-md-4">
          <InputText name="username" label="Username" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="rag_social" label="Ragione Sociale" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="address" label="Indirizzo" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="city" label="Città" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="postcode" label="CAP" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="piva" label="P. Iva" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="cf" label="Cod. Fiscale" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="pec" label="PEC" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="codedest" label="Cod. Destinatario" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="phone" label="Cellulare" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="telephone" label="Telefono" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="customer_fic_id" label="FIC ID Cliente" register={register} />
        </div>

        <div className="col-md-4">
          <InputText name="stripe_customer_id" label="Stripe ID Cliente" register={register} />
        </div>
      </div>
      
      <hr />

      <input type="submit" value="Conferma" className="btn btn-primary" />
    </form>
  )
}

export default FormAccountingHostingUser;