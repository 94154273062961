import React from 'react'

import { httpGet } from '../../http'
import { QUOTES_ENDPOINT } from '../../constants/API_ENDPOINT'

import { useAuth } from '../auth-context';

const QuoteContext = React.createContext()

const QuoteProvider = (props) => {
	const { jwtToken } = useAuth();

  const getQuotes = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${QUOTES_ENDPOINT}/list/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}


	return (
		<QuoteContext.Provider
			value={{
        getQuotes
			}}
			{...props}
		/>
	)
}
const useQuote = () => React.useContext(QuoteContext)

export { QuoteProvider, useQuote }