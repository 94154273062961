import styled from 'styled-components';

export const TableFilterMonthWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  overflow-x: scroll;
`;

export const SingleMonthItem = styled.div`
  padding: 5px;
  width: 8.3333333%;
  flex: 0 8.3333333%;
  background: #333;
  border: 1px solid #2a2a2a;
  transition: all .3s ease;
  cursor: pointer;
  min-width: 100px;

  &.active {
    background: #222;
  }

  &:hover {
    background: #222
  }

  &:first-of-type {
    border-radius: 4px 0 0 0;
  }

  &:last-of-type {
    border-radius: 0 4px 0 0;
  }

  h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 40px;
  }

  span {
    font-size: 12px;

    &.false {
      color: #DB7F7F;
    }

    &.ok {
      color: #81BB6D;
    }
  }
`;