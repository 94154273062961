import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ListReferrals from '../components/referrals-external-components/list-referrals';
import UpdateReferral from '../components/referrals-external-components/update-referral';

import { ReferralExternalProvider } from '../contexts/referra-external-context';

const ReferralsExternal = ({ match }) => {
  return (
    <Switch>
			<ReferralExternalProvider>
				<Route exact path={`${match.path}`} component={ListReferrals} />
				<Route exact path={`${match.path}/:id`} component={UpdateReferral} />
			</ReferralExternalProvider>
    </Switch>
  )
}

export default ReferralsExternal;