import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from '../../ui-components/login';
import NewReferral from '../../components/referrals-external-components/new-referral';
import { ReferralExternalProvider } from '../../contexts/referra-external-context';
import Privacy from '../../pages/Privacy';
import Terms from '../../pages/Terms';

const UnauthenticatedApp = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path={[ '/', '/login' ]} component={Login} />
				<Route exact path="/privacy-policy" component={Privacy} />
				<Route exact path="/termini-e-condizioni" component={Terms} />
				<ReferralExternalProvider>
					<Route exact path="/referral/new-referral-customer" component={NewReferral} />
				</ReferralExternalProvider>
			</Switch>
		</BrowserRouter>
	)
}

export default UnauthenticatedApp