import React from 'react'

// import { httpPost, httpGet, httpDelete, httpPatch } from '../../http'
// import { ACCOUNTING_ENDPOINT } from '../../constants/API_ENDPOINT'

// import { useAuth } from '../auth-context';

const AccountingContext = React.createContext()

const AccountingProvider = (props) => {
	// const { jwtToken } = useAuth();


	return (
		<AccountingContext.Provider
			value={{
			}}
			{...props}
		/>
	)
}
const useAccounting = () => React.useContext(AccountingContext)

export { AccountingProvider, useAccounting }