import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// contexts
import { BonusProvider } from '../contexts/accounting-contexts/bonus-context';

// components
import BonusAreaInterns from '../components/accounting-components/bonus-area/bonus-area-interns';
import BonusAreaSales from '../components/accounting-components/bonus-area/bonus-area-sales';

const Bonus = ({ match }) => {
	return (
		<BrowserRouter>
			<BonusProvider>
				<Switch>
					<Route exact path={`${match.path}/interns`} component={BonusAreaInterns} />
					<Route exact path={`${match.path}/sales/:id`} component={BonusAreaSales} />
				</Switch>
			</BonusProvider>
		</BrowserRouter>
	)
}

export default Bonus;