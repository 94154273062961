import React, { useEffect } from 'react';
import jwt from 'jwt-decode'

import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import FormReferral from '../../../ui-components/form-components/referrals';

import { useReferralExternal } from '../../../contexts/referra-external-context';
import { handlingSubmitError } from '../../../utils/handling-submit-error';


const NewReferral = (props) => {
	const token = String(props.location.search).replace('?token=', '')
  const { createReferral } = useReferralExternal();

	// check token is valid
	useEffect(() => {
		if (token) {
			let decodedToken = jwt(token, { complete: true })
			let dateNow = new Date()
			let fixedExpToken = parseInt(decodedToken.exp) * 1000

			if (fixedExpToken <= dateNow.getTime()) {
				alert('Link scaduto.')
				return window.location.href = 'https://masamune.it/';
			}

			return ;
		}

		return window.location.href = 'https://masamune.it/';
	}, [token]);

  const handleSaveReferral = async (data) => {
    const { error } = await createReferral({ ...data, token });
    
    if (error !== null) {
      if (error.data.error !== undefined) {
        return alert(error.data.error)
      }

      await handlingSubmitError(error);
      return ;
    }
		
		alert('Referral creato con successo.');
    return window.location.href = 'https://masamune.it/';
  }

  return (
    <div className='referral-page-bs'>
			<div className="container">

				<div className="header-form">
					<img src='https://masamune.it/static/media/logo-masamune-small-white.79a0089b.svg' alt="Masamune logo" />
          <h3>Richiedi una consulenza gratuita</h3>
					<p>Ottieni una consulenza gratuita! Registrati con il nostro link referral e ricevi una consulenza personalizzata dai nostri esperti. Non perdere l'occasione di ottenere il massimo dal nostro servizio! Registrarti adesso.</p>
				</div>

        <FormReferral handleSave={handleSaveReferral} data={null} />
			</div>
    </div>
  )
}

export default NewReferral;