import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InputText, InputSelect } from '../../forms';
import { useOperator } from '../../../contexts/operator-context';
import { useMasamuneServices } from '../../../contexts/accounting-contexts/masamune-services-context';
import { useContract } from '../../../contexts/contract-context';
import { filterRole } from '../../../constants/STATUS_FILTER';
import { number_format } from '../../../utils';

const FormProject = ({ data = null, handleSave, year, month }) => {
	const { getOperators } = useOperator();
	const { getServices, getInvoices } = useMasamuneServices();
	const { getContracts } = useContract();

	const [dataOperators, setDataOperators] = useState([]);
	const [dataContracts, setDataContracts] = useState([]);
	const [dataServices, setDataServices] = useState([]);
  const [dataInvoices, setDataInvoices] = useState([]);

	const [operators, setOperators] = useState(data !== null ? data.operators : []);
	const [contracts, setContracts] = useState(data !== null ? data.contracts : []);
	const [invoices, setInvoices] = useState(data !== null ? data.invoices : []);
	const [projectName, setProjectName] = useState(data !== null ? data.name : '');

	const [selectedOperator, setSelectedOperator] = useState(null);
	const [selectedContract, setSelectedContract] = useState(null);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [selectedRole, setSelectedRole] = useState(null);
	const [selectedService, setSelectedService] = useState(null);

	useEffect(() => {
		setDataOperators([]);
		setDataServices([]);

		const fetchOperators = async () => {
			const { data, error } = await getOperators();
			if (error !== null) return alert(error.data.error);
			data.results.forEach(item => {
				setDataOperators(oldOperators => [
					...oldOperators,
					{
						label: `${item.first_name} ${item.last_name}`,
						value: JSON.stringify(item)
					}
				])
			});
		}

		const fetchServices = async () => {
			const { data, error } = await getServices();
			if (error !== null) return alert(error.data.error);
			data.results.forEach(item => {
				setDataServices(oldServices => [
					...oldServices,
					{
						label: `${item.code} - ${item.name} - ${item.category}`,
						value: JSON.stringify(item)
					}
				])
			});
		}

		fetchOperators();
		fetchServices();
	}, [getOperators, getServices]);

	useEffect(() => {
		setDataContracts([]);
		setDataInvoices([]);

    const fetchInvoices = async () => {
      const { data, error } = await getInvoices(month, year);
      if (error !== null) return alert(error.data.error);

			data.results.forEach(item => {
				setDataInvoices(oldInvoices => [
					...oldInvoices,
					{
						label: `Fattura nr. ${item.nr_doc} del ${moment(item.date_doc).format('DD/MM/YYYY')}`,
						value: JSON.stringify(item)
					}
				])
			});
    };

		const fetchContracts = async () => {
			const { data, error } = await getContracts({ year, month });
			if (error !== null) return alert(error.data.error);
			data.results.forEach(item => {
				setDataContracts(oldOntracts => [
					...oldOntracts,
					{
						label: `Cliente: ${item.customer_name} - Data: ${moment(item.created_at).format('DD/MM/YYYY')} - ${item.quote !== null ? `Preventivo: nr.  ${item.quote.nr_doc}/${moment(item.quote.date).format('YYYY')}` : 'n/a'}`,
						value: JSON.stringify(item)
					}
				])
			});
		}


    fetchInvoices();
		fetchContracts();
  }, [month, year, getInvoices, getContracts]);

  const onSubmit = async (e) => {
		e.preventDefault();

    await handleSave({ contracts, operators, invoices, name: projectName });
  }

	const handlePushOperatorProject = () => {
		if (!selectedOperator || !selectedService || !selectedRole) return alert('Tutti i campi sono obbligatori');
		
		setOperators(
			oldOperators => [
				...oldOperators,
				{
					user: JSON.parse(selectedOperator),
					service: JSON.parse(selectedService),
					role: selectedRole,
				}
			]
		)

		setSelectedOperator(null);
		setSelectedService(null);
		setSelectedRole(null);

		document.getElementById('operator').selectedIndex = 0;
		document.getElementById('service').selectedIndex = 0;
		document.getElementById('role').selectedIndex = 0;
	}

	const handlePushContract = () => {
		if (!selectedContract) return alert("Seleziona un contratto");

		setContracts(oldContracts => [ ...oldContracts, JSON.parse(selectedContract)]);

		document.getElementById('contract').selectedIndex = 0;
	}

	const handlePushInvoice = () => {
		if (!selectedInvoice) return alert("Seleziona una fattura");

		setInvoices(oldInvoices => [ ...oldInvoices, JSON.parse(selectedInvoice)]);

		document.getElementById('invoice').selectedIndex = 0;
	}

  const roles = [
    { value: 'Responsabile', label: 'Responsabile' },
    { value: 'Supporto', label: 'Supporto' }
  ]

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
				<div className="col-md-12">
					<InputText defaultValue={data !== null ? data.name : ''} label="Nome Progetto" name="name-project" onChange={setProjectName} />
				</div>
			</div>

			<hr />

			<div className="row">
				<div className="col-md-8">
					<InputSelect bold={true} data={dataContracts} label="Seleziona un contratto" name="contract" onChange={setSelectedContract} />
				</div>
				<div className="col-md-4">
					<label htmlFor="" className="d-block">&nbsp;</label>
					<input type="button" onClick={handlePushContract} className="btn btn-primary w-100 btn-block" value={"Aggiungi"} /> 
				</div>
			</div>

			<hr />

			<div className="row">
				<div className="col-md-8">
					<InputSelect bold={true} data={dataInvoices} label="Seleziona una fattura" name="invoice" onChange={setSelectedInvoice} />
				</div>
				<div className="col-md-4">
					<label htmlFor="" className="d-block">&nbsp;</label>
					<input type="button" onClick={handlePushInvoice} className="btn btn-primary w-100 btn-block" value={"Aggiungi"} /> 
				</div>
			</div>

			<hr />

			<p className="section-title mb-0">Aggiungi fornitori</p>
			<div className="row">
				<div className="col-md-4">
					<InputSelect data={dataOperators} label="Operatore" name="operator" onChange={setSelectedOperator} />
				</div>
				<div className="col-md-4">
					<InputSelect data={dataServices} label="Servizio" name="service" onChange={setSelectedService} />
				</div>
				<div className="col-md-4">
					<InputSelect data={roles} label="Ruolo" name="role" onChange={setSelectedRole} />
				</div>

				<div className="col-md-4">
					<input type="button" onClick={handlePushOperatorProject} className="btn btn-primary w-100 btn-block mt-2" value={"Aggiungi"} /> 
				</div>
			</div>

			<hr />

			{
				operators.length > 0 ?
				<>
					<p className="section-title">Operatori selezionati</p>
					<div class="table-responsive">
<table className="table table-striped">
						<thead>
							<tr>
								<th>Azioni</th>
								<th>Operatore</th>
								<th>Servizio</th>
								<th>Ruolo</th>
							</tr>
						</thead>
						<tbody>
							{
								operators.map((item, index) => {
									return (
										<tr key={index}>
											<td>
	                      <button type="button" onClick={() => setOperators(prev => prev.filter((_value, i) => i !== index))} className="btn btn-delete"><FontAwesomeIcon icon={faTrash} /></button>
											</td>
											<td>{ `${item.user.first_name} ${item.user.last_name}` }</td>
											<td>{ `${item.service.code} - ${item.service.description.slice(0, 100)}...` }</td>
											<td>{ item.role }</td>
										</tr>
									)
								})
							}
						</tbody>
					</table></div>

					<hr />
				</>
				: null
			}


			{
				contracts.length > 0 ?
				<>
					<p className="section-title">Contratti selezionati</p>
					<div class="table-responsive">
<table className="table table-striped">
						<thead>
							<tr>
								<th>Azioni</th>
								<th>ID Contratto</th>
								<th>Cliente</th>
								<th>Stato</th>
								<th>Creato il</th>
								<th>Tipo</th>
							</tr>
						</thead>
						<tbody>
							{
								contracts.map((item, index) => {
									return (
										<tr key={index}>
											<td>
	                      <button type="button" onClick={() => setContracts(prev => prev.filter((_value, i) => i !== index))} className="btn btn-delete"><FontAwesomeIcon icon={faTrash} /></button>
											</td>
											<td>{ `${item.id}` }</td>
											<td>{ `${item.customer_name}` }</td>
											<td>{ `${filterRole[item.contract_status]}` }</td>
											<td>{ `${moment(item.created_at).format('DD/MM/YYYY')}` }</td>
											<td>{ `${item.type_file}` }</td>
										</tr>
									)
								})
							}
						</tbody>
					</table></div>
				</>
				: null
			}

			{
				invoices.length > 0 ?
				<>
					<p className="section-title">Fatture selezionate</p>
					<div class="table-responsive">
<table className="table table-striped">
						<thead>
							<tr>
								<th>Azioni</th>
								<th>ID Fattura</th>
								<th>Cliente</th>
								<th>Nr. Doc</th>
								<th>Data</th>
								<th>Totale Document</th>
							</tr>
						</thead>
						<tbody>
							{
								invoices.map((item, index) => {
									return (
										<tr key={index}>
											<td>
	                      <button type="button" onClick={() => setInvoices(prev => prev.filter((_value, i) => i !== index))} className="btn btn-delete"><FontAwesomeIcon icon={faTrash} /></button>
											</td>
											<td>{ `${item.id}` }</td>
											<td>{ `${item.customer}` }</td>
											<td>{ `${item.nr_doc}` }</td>
											<td>{ `${moment(item.date_doc).format('DD/MM/YYYY')}` }</td>
											<td>&euro; { `${number_format(item.import_doc, 2, ',', '.')}` }</td>
										</tr>
									)
								})
							}
						</tbody>
					</table></div>
				</>
				: null
			}

      <input type="submit" value="Conferma" className="btn btn-primary mt-3" />
    </form>
  )
}

export default FormProject;