import React from 'react'

import { httpPost, httpGet, httpPatch, httpDelete } from '../http';
import { CONTRACTS_ENDPOINT } from '../constants/API_ENDPOINT';

import { useAuth } from './auth-context';

const ContractContext = React.createContext()

const ContractProvider = (props) => {
	const { jwtToken } = useAuth();

	const getContracts = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${CONTRACTS_ENDPOINT}/list/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getRecapContracts = async (year, params = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${CONTRACTS_ENDPOINT}/recap-year/${year}/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getSingleContract = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${CONTRACTS_ENDPOINT}/single/${id}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const deleteContract = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${CONTRACTS_ENDPOINT}/single`, jwtToken, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const downloadDocument = async (id, documentId) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${CONTRACTS_ENDPOINT}/download-document/${id}/${documentId}/`, jwtToken, {});
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const createQuoteEnvelope = async (body) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${CONTRACTS_ENDPOINT}/create-quote-envelope/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const createPartnerEnvelope = async (body) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${CONTRACTS_ENDPOINT}/create-partner-envelope/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const createAgentEnvelope = async (body) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${CONTRACTS_ENDPOINT}/create-agent-envelope/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const createPerformanceEnvelope = async (body) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${CONTRACTS_ENDPOINT}/create-performance-envelope/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	}

	const getDashboardData = async (year) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		if (jwtToken === undefined) return ;

		try {
			const result = await httpGet(`${CONTRACTS_ENDPOINT}/overview/`, jwtToken, { year });
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj; 
	}

	const actionContract = async (id, action, body = {}) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${CONTRACTS_ENDPOINT}/actions/${action}`, jwtToken, body, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj; 
	}

	return (
		<ContractContext.Provider
			value={{
				getContracts,
				getSingleContract,
				createQuoteEnvelope,
				downloadDocument,
				deleteContract,
				createPartnerEnvelope,
				createAgentEnvelope,
				createPerformanceEnvelope,
				getDashboardData,
				actionContract,
				getRecapContracts
			}}
			{...props}
		/>
	)
}
const useContract = () => React.useContext(ContractContext)

export { ContractProvider, useContract }