import React, { useState, useEffect } from "react";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";

import TopBarSection from "../../../../ui-components/top-bar-section";
import FormAccountingHostingUser from "../../../../ui-components/form-components/accounting/hosting/users";
import FormAccountingHostingSubscription from "../../../../ui-components/form-components/accounting/hosting/subscriptions";

import { useHosting } from "../../../../contexts/accounting-contexts/hosting-context";
import { useAuth } from "../../../../contexts/auth-context";

import { handlingSubmitError } from "../../../../utils/handling-submit-error";
import { number_format, Padder } from "../../../../utils";

const SingleHostingUserInfo = (props) => {
	const { id } = props.match.params;
	const { role } = useAuth();
	const {
		getSingleUser,
		updateUser,
		getSubscriptionsByUserId,
		updatePassword,
		createSubRequest
	} = useHosting();

	const [showForm, setShowForm] = useState(false);
	const [user, setUser] = useState(null);
	const [subscriptions, setSubscriptions] = useState([]);

	useEffect(() => {
		const fetchSingleUser = async () => {
			const { data, error } = await getSingleUser(id);
			if (error !== null) return alert(error.data.error);
			setUser(data.results);
		};

		const fetchSubscriptions = async () => {
			const { data, error } = await getSubscriptionsByUserId(id);

			if (error !== null) {
				return alert(error.data.error);
			}

			setSubscriptions(data.results);
		};

		fetchSingleUser();
		fetchSubscriptions();
	}, [getSingleUser, getSubscriptionsByUserId, id]);

	const handleSave = async (body) => {
		const { error } = await updateUser(id, body);

		if (error !== null) {
			if (error.data.error !== undefined) {
				return alert(error.data.error);
			}

			await handlingSubmitError(error);
			return;
		}

		return window.location.reload();
	};

	const handleCreateSub = async (body) => {
		const { error } = await createSubRequest({ ...body, price_id: body.price_id === 'custom' ? null : body.price_id, user_id: user.id});

		if (error !== null) {
			if (error.data.error !== undefined) {
				return alert(error.data.error);
			}
		}

		window.location.reload();
	}

	const handleChangePassword = async () => {
		const password = prompt("Inserisci la nuova password dell'utente");
		const passwordConf = prompt("Conferma la nuova password dell'utente");

		if (password === null || passwordConf === null) return;
		if (password !== passwordConf)
			return alert(
				"La password e la password di conferma non combaciano, riprova"
			);

		const { data, error } = await updatePassword(id, {
			password,
			conf_password: passwordConf,
		});
		if (error !== null) return alert(error.data.error);
		return alert(data.results);
	};

	const statusStrings = [
		"Non accettato",
		"In corso",
		"Scaduto",
		"Bloccato",
		"Cancellato",
		"In attesa"
	]

	return (
		<div className="single-user-hosting-info">
			<TopBarSection pageName="Modifica Utente" />

			<p className="section-title">Azioni utente</p>
			{role === "ADMIN" || role === "SUPER ADMIN" ? (
				<button
					onClick={handleChangePassword}
					type="button"
					className="btn btn-primary"
				>
					Cambia password
				</button>
			) : null}

			<hr />

			{user !== null ? (
				<FormAccountingHostingUser data={user} handleSave={handleSave} />
			) : null}

			<hr />

			<button className="btn btn-primary mb-2" onClick={() => setShowForm(!showForm)}>{ !showForm ? 'Crea abbonamento' : 'Chiudi' }</button>
			{ user !== null && showForm && <FormAccountingHostingSubscription data={null} handleSave={handleCreateSub} /> }

			<div className="table-responsive">
				<div className="table-responsive">
					<table className="table table-striped">
						<thead>
							<tr>
								<th>Azioni</th>
								<th>Nr. Abbonamento</th>
								<th>Data Scadenza</th>
								<th>Totale</th>
								<th>Piano</th>
								<th>Stato</th>
							</tr>
						</thead>
						<tbody>
							{subscriptions.map((item, index) => {
								return (
									<tr key={index}>
										<td>
											<a
												className="btn btn-edit"
												href={`/hosting/subscriptions/${item.id}`}
											>
												<FontAwesomeIcon icon={faBoxOpen} />
											</a>
										</td>
										<td>#{new Padder(6).pad(item.nr_subscription)}</td>
										<td>{moment(item.expire_date).format("DD/MM/YYYY hh:mm")}</td>
										<td>&euro; {number_format(item.tot, 2, ",", ".")}</td>
										<td>{item.plane}</td>
										<td>{statusStrings[item.status]}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default SingleHostingUserInfo;
