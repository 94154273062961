import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { CustomerProvider } from '../contexts/customer-context';
import { ReferralExternalProvider } from '../contexts/referra-external-context';

import CustomersList from '../components/customers-components/list-customers';
import SingleCustomer from '../components/customers-components/single-customer';

const Customers = ({ match }) => {
	return (
		<Switch>
			<CustomerProvider>
				<ReferralExternalProvider>
					<Route exact path={`${match.path}`} component={CustomersList} />
					<Route exact path={`${match.path}/:id`} component={SingleCustomer} />
				</ReferralExternalProvider>
			</CustomerProvider>
		</Switch>
	)
}

export default Customers;