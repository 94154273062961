import React, { useState } from 'react';

import { ContractProvider } from '../contexts/contract-context';

import ContractsPanels from '../components/dashboard-components/contracts-panels';
import TopBarSection from '../ui-components/top-bar-section';

const Home = ({ match }) => {
	const [year, setYear] = useState(new Date().getFullYear());

	return (
		<div className="dashboard-wrapper">
			<TopBarSection pageName={'Overview'} handleChangeYear={setYear} />

			<ContractProvider>
				<ContractsPanels year={year} />
			</ContractProvider>
		</div>
	)
}

export default Home;