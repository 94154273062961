import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TopBarSection from '../../../ui-components/top-bar-section';
import TableFilterMonth from '../../../ui-components/table-filter-months';

import { useQuote } from '../../../contexts/quote-context';
import { number_format } from '../../../utils';


const ListQuotes = () => {
  const { getQuotes, getRecapQuotes } = useQuote();

  const [quotes, setQuotes] = useState([]);
  const [recaps, setRecaps] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const fetchQuotes = async () => {
      const { data, error } = await getQuotes({ month, year });
      if (error !== null) return alert(error.data.error);
      setQuotes(data.results);
    };

    const fetchRecapYear = async () => {
      const { data, error } = await getRecapQuotes(year, {});
      if (error !== null) return alert(error.data.error);
      setRecaps(data.results);
    }

    fetchQuotes();
    fetchRecapYear();
  }, [month, year, getQuotes, getRecapQuotes]);

  return (
    <div className="list-quotes-component">
      <TopBarSection pageName="Gestione Preventivi" handleChangeYear={setYear} />
      <TableFilterMonth data={recaps} handleChangeMonth={setMonth} />
      <div className="table-responsive">
<table className="table table-striped">
          <thead>
            <tr>
              <th>Azioni</th>
              <th>ID</th>
              <th>Nr. Doc</th>
              <th>Data</th>
              <th>Cliente</th>
              <th>Importo Documento</th>
              <th>Importo IVA</th>
              <th>Importo Totale</th>
            </tr>
          </thead>
          <tbody>
            {
              quotes.map((item, index) => {
                return (
                  <tr key={index} className={item.status_quote === 1 ? 'quote-accepted' : item.status_quote === 2 ? 'quote-refused' : 'quote-pending'}>
                    <td>
                      <a className="btn btn-edit me-2" href={`/preventivi/${item.id}`} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInbox} /></a>
                    </td>
                    <td>{item.id_fic}</td>
                    <td>{item.nr_doc}</td>
                    <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                    <td>{item.customer_fic_name}</td>
                    <td>&euro; {number_format(item.import_doc, 2, ',', '.')}</td>
                    <td>&euro; {number_format(item.import_vat, 2, ',', '.')}</td>
                    <td>&euro; {number_format(item.import_tot, 2, ',', '.')}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table></div>
    </div>
  )
}

export default ListQuotes;