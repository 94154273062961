import React, { useState, useEffect } from 'react';


import TopBarSection from '../../../../../ui-components/top-bar-section';
import { useHosting } from '../../../../../contexts/accounting-contexts/hosting-context';
import { number_format, Padder } from '../../../../../utils';
import moment from 'moment';


const SingleHostingSubscription = (props) => {
	const { id } = props.match.params;
	const { getSingleSubscription, sendEventRequest } = useHosting();

	const [subscription, setSubscription] = useState(null);

	useEffect(() => {
		const fetchSingleSubscription = async () => {
			const { data, error } = await getSingleSubscription(id);
			if (error !== null) return alert(error.data.error);
			setSubscription(data.results);
		};

		fetchSingleSubscription();
	}, [getSingleSubscription, id]);

	const handleSendEvent = async (event) => {
		const { data, error } = await sendEventRequest(id, event);
		if (error !== null) return alert(error.data.error);

		if (event === 'rinnovo') {
			window.open(data.hosting_response.results)
			return;
		}

		return window.location.reload();
	}

	const statusStrings = [
		"Non accettato",
		"In corso",
		"Scaduto",
		"Bloccato",
		"Cancellato",
		"In attesa"
	]

	return (
		<div className="single-user-hosting-info">
			<TopBarSection pageName="Abbonamento" />

			{
				subscription !== null ?
					<div className="row">
						<div className="col-md-7">
							<h3 className="section-title">Dettagli Abbonamento</h3>

							<div className="table-responsive">
								<table className="table table-one-way table-striped">
									<tr>
										<th>Nr. Ordine</th>
										<td>#{new Padder(6).pad(subscription.nr_subscription)}</td>
									</tr>
									<tr>
										<th>Utente</th>
										<td><a href={`/hosting/single-user/${subscription.user}`} target="_blank" rel="noreferrer">{subscription.user}</a></td>
									</tr>
									<tr>
										<th>Importo</th>
										<td>&euro; {number_format(subscription.tot, 2, ',', '.')}</td>
									</tr>
									<tr>
										<th>Piano</th>
										<td>{subscription.plane}</td>
									</tr>
									<tr>
										<th>Data Ultimo Pagamento</th>
										<td>{moment(subscription.last_payment_date).format('DD/MM/YYYY hh:mm')}</td>
									</tr>
									<tr>
										<th>Data Scadenza</th>
										<td>{moment(subscription.expire_date).format('DD/MM/YYYY hh:mm')}</td>
									</tr>
									<tr>
										<th>Stato</th>
										<td>{statusStrings[subscription.status]}</td>
									</tr>
								</table></div>
						</div>

						<div className="col-md-5">
							<h3 className="section-title">Azioni</h3>

						{ subscription.status === 5 && <button onClick={() => handleSendEvent('attiva')} className="btn btn-success btn-block btn-big mb-2 w-100">Attiva Abbonamento</button> }
						{ subscription.status === 1 && <button onClick={() => handleSendEvent('sollecito')} className="btn btn-primary btn-block btn-big mb-2 w-100">Invio Sollecito</button> }
						{ subscription.status === 2 && <button onClick={() => handleSendEvent('rinnovo')} className="btn btn-success btn-block btn-big mb-2 w-100">Effettua Rinnovo</button> }
						{ subscription.status === 1 && <button onClick={() => handleSendEvent('disattiva')} className="btn btn-danger btn-block btn-big mb-2 w-100">Disattiva Abbonamento</button> }
						</div>
					</div>
					: null}
		</div>
	)
};


export default SingleHostingSubscription;