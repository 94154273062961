import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ListProjects from '../components/projects-components/list-projects';
import { MasamuneServicesProvider } from '../contexts/accounting-contexts/masamune-services-context';
import { OperatorProvider } from '../contexts/operator-context';
import { ProjectProvider } from '../contexts/project-context';
import { ContractProvider } from '../contexts/contract-context';

const Projects = ({ match }) => {
	return (
		<Switch>
			<OperatorProvider>
				<MasamuneServicesProvider>
					<ProjectProvider>
						<ContractProvider>
							<Route exact path={`${match.path}`} component={ListProjects} />
						</ContractProvider>
					</ProjectProvider>
				</MasamuneServicesProvider>
			</OperatorProvider>
		</Switch>
	)
}

export default Projects;