import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useQuote } from '../../../contexts/quote-context';

import TopBarSection from '../../../ui-components/top-bar-section';
import LabelInfo from '../../../ui-components/label-info';
import { InputSelect, InputTextArea } from '../../../ui-components/forms';

import { number_format } from '../../../utils';


const SingleQuote = ({ match }) => {
  const { id } = match.params;
  const { getSingleQuote, editStatusSingleQuote, addNote } = useQuote();

  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [showNoteForm, setShowNoteForm] = useState(false);
  // const [customer, setCustomer] = useState(null);
  const [quote, setQuote] = useState(null);

  useEffect(() => {
    const fetchQuoteData = async () => {
      const { data, error } = await getSingleQuote(id);

      if (error !== null) return alert(error.data.error);

      // setCustomer(data.customer);
      setQuote(data.quote);
      setNotes(data.notes);
    }

    fetchQuoteData();
  }, [id, getSingleQuote, showNoteForm]);

  const handleChangeStatusQuote = async (value) => {
    const { error } = await editStatusSingleQuote(id, value);
    if (error !== null) return alert(error.data.error);
  }

  const handleAddNote = async (e) => {
    e.preventDefault();

    const { error } = await addNote(id, note);
    if (error !== null) return alert(error.data.error);

    setShowNoteForm(false);
    setNote('');
  }

  const statusQuoteList = [
    { value: 0, label: 'IN ATTESA' },
    { value: 1, label: 'VINTO' },
    { value: 2, label: 'PERSO' }
  ]

  return (
    <div className="single-quote-component">
      <TopBarSection pageName="Informazioni preventivo" />

      {
        quote !== null ?
          <div>
            <div className="row">
              <LabelInfo name="Nr. Preventivo" value={quote.nr_doc} />
              <LabelInfo name="Data Preventivo" value={moment(quote.date).format('DD/MM/YYYY')} />
              <LabelInfo name="Cliente" value={quote.customer_fic_name} />
            </div>

            <div className="row">
              <LabelInfo name="Importo Documento" value={`€ ${number_format(quote.import_doc, 2, ',', '.')}`} />
              <LabelInfo name="Importo IVA" value={`€ ${number_format(quote.import_vat, 2, ',', '.')}`} />
              <LabelInfo name="Importo Totale" value={`€ ${number_format(quote.import_tot, 2, ',', '.')}`} />
            </div>

            <div className="row">
              <div className="col-md-4">
                <InputSelect name="status_quote" bold={true} defaultValue={quote.status_quote} data={statusQuoteList} label="Stato del preventivo" onChange={handleChangeStatusQuote} />
              </div>
            </div>

            <hr />

            <h4 className="section-title mb-1">Note preventivo <button onClick={() => setShowNoteForm(!showNoteForm)} className="badge bg-primary">Aggiungi nota</button></h4>
            {
              showNoteForm ?
                <div className="customer-add-note-form">
                  <InputTextArea name="note" label="Note" onChange={setNote} />
                  <input type="button" value="Salva" className="btn btn-primary mt-2" onClick={handleAddNote} />

                  <hr />
                </div>
                : null
            }
            {
              notes.map((item, index) => {
                return (
                  <div className="customer-note-item" key={index}>
                    <InputTextArea
                      label={`Creato da ${item.created_by} il ${moment(item.created_at).format('DD/MM/YYYY')} alle ${moment(item.created_at).format('hh:mm A')}`}
                      disabled={true}
                      name="note"
                      defaultValue={item.note} />
                  </div>
                )
              })
            }

          </div>
          : null}
    </div>
  )
}

export default SingleQuote;