import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { useMasamuneServices } from '../../../contexts/accounting-contexts/masamune-services-context';
import { useOperator } from '../../../contexts/operator-context';

import TopBarSection from '../../../ui-components/top-bar-section';
import { number_format } from '../../../utils';

const InvoiceFieldCompile = () => {
  const { getOperators } = useOperator();
  const { getInvoices, getServices, setInvoiceComps } = useMasamuneServices();

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const [agents, setAgents] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [services, setServices] = useState(null);

  const [invoice, setInvoice] = useState(null);
  const [agent, setAgent] = useState(null);
  const [service, setService] = useState(null);
  const [perc, setPerc] = useState(0.00);
  const [discount, setDiscount] = useState(0.00);

  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState('');
  const [dataView, setDataView] = useState([]);

  useEffect(() => {
    const fetchAgents = async () => {
      const { data, error } = await getOperators();
      if (error !== null) return alert(error.data.error);
      setAgents(data.results);
    };

    const fetchServices = async () => {
      const { data, error } = await getServices();
      if (error !== null) return alert(error.data.error);
      setServices(data.results);
    };

    fetchAgents();
    fetchServices();
  }, [getOperators, getServices]);

  useEffect(() => {
    const fetchInvoices = async () => {
      const { data, error } = await getInvoices(month, year);
      if (error !== null) return alert(error.data.error);
      setInvoices(data.results);
    };

    fetchInvoices();
  }, [month, year, getInvoices]);

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const invoiceData = invoices[invoice];
    if (invoice === null) return alert('Fattura non selezionata');

    const agentData = agents[agent];
    if (agent === null) return alert('Operatore non selezionato');

    const serviceData = services[service];
    const supplierPrice = service !== null && service !== '' && service !== '' ? serviceData.supplier_price : 0;

    let total = ((supplierPrice) + ((invoiceData.import_doc * perc) / 100));
    total = total - ((total * discount) / 100);

    setDataView(items => [
      ...items,
      {
        agent: `${agentData.supplier_code} - ${agentData.first_name} ${agentData.last_name}`,
        service: service !== null && service !== '' ? `${serviceData.name} - ${serviceData.code} - ${serviceData.category}` : 'n/a',
        discount: discount,
        perc: perc,
        total: total
      }
    ]);

    // sum total of all document
    setTotal(tot => tot + total);

    setAgent('');
    setService('');
    setPerc(0);
    setDiscount(0);

    // create string
    setData(str => str + `${agentData.supplier_code}:${service !== null && service !== '' ? serviceData.code : 'NULL'}:${perc >= 0.01 ? perc : 'NULL'}:${discount >= 0.01 ? discount : 'NULL'},`);
  }

  const removeItemFromData = async (index) => {
    setTotal(tot => tot - dataView[index].total);
    setDataView(items => items.filter((_, i) => i !== index));

    // remove from str
    let splittedString = data.split(',');
    const fixedStr = splittedString.filter((_, i) => i !== index);

    let newData = '';
    for (let i = 0; i < fixedStr.length - 1; i++) {
      newData += fixedStr[i] + ',';
    }

    setData(newData);
  }

  const handleSelectInvoice = async (e) => {
    setInvoice(e.target.value);
    setInvoiceTotal(invoices[e.target.value].import_doc);
    setDisabled(true);
  }

  const handleSubmitAll = async () => {
    const { error } = await setInvoiceComps(invoices[invoice].id, data.substring(0, data.length - 1));
    if (error !== null) return alert(error.data.error);
    window.location.reload();
  }

  return (
    <div className="settings-syncs-page">
      <TopBarSection pageName="Impostazioni - Compensi" handleChangeMonth={setMonth} handleChangeYear={setYear} />

      {
        agents && invoices && services ?
          <div>
            <form onSubmit={handleSubmitForm}>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="invoice">Fattura</label>
                  <select disabled={disabled} name="invoice" id="invoice" className="form-select" required onChange={handleSelectInvoice}>
                    <option value="">Seleziona una fattura</option>
                    {
                      invoices.map((item, index) => {
                        return (
                          <option value={index} index={index}>{`Fattura nr. ${item.nr_doc} del ${moment(item.date_doc).format('DD/MM/YYYY')}`}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="agent">Agente</label>
                  <select name="agent" id="agent" value={agent} className="form-select" required onChange={(e) => setAgent(e.target.value)} ed>
                    <option value="">Seleziona un agente</option>
                    {
                      agents.map((item, index) => {
                        return (
                          <option value={index} index={index}>{`${item.first_name} ${item.last_name}`}</option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="service">Servizio</label>
                  <select name="service" id="service" value={service} className="form-select" required onChange={(e) => setService(e.target.value)} ed>
                    <option value="">Seleziona un servizio</option>
                    {
                      services.map((item, index) => {
                        return (
                          <option value={index} index={index}>{`${item.code} - ${item.name} - ${item.category}`}</option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="to_add">Percentuale</label>
                  <input type="number" step="0.01" value={perc} className="form-control" max="100" min="0" required onChange={(e) => setPerc(e.target.value)} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="discount">Sconto</label>
                  <input type="number" step="0.01" value={discount} className="form-control" max="100" min="0" required onChange={(e) => setDiscount(e.target.value)} />
                </div>
              </div>

              <input type="submit" className="btn btn-primary mt-3" value="Aggiungi" />
            </form>

            <hr />

            <h3 className="section-title">Riepilogo</h3>
            <div className="table-responsive">
<table className="table table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Agente</th>
                    <th>Servizio</th>
                    <th>Percentuale</th>
                    <th>Sconto</th>
                    <th>Compenso</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dataView.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td><button type="button" className="btn btn-delete" onClick={() => removeItemFromData(index)}><FontAwesomeIcon icon={faTrash} /></button></td>
                          <td>{item.agent}</td>
                          <td>{item.service}</td>
                          <td>{number_format(item.perc, 2, ',', '.')} %</td>
                          <td>{number_format(item.discount, 2, ',', '.')} %</td>
                          <td>&euro;{number_format(item.total, 2, ',', '.')}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table></div>

            <p><strong>Totale Fattura: </strong> &euro; {number_format(invoiceTotal, 2, ',', '.')}</p>
            <p><strong>Totale Compensi: </strong> &euro; {number_format(total, 2, ',', '.')}</p>
            <p><strong>Stringa: </strong> {data.substring(0, data.length - 1)}</p>

            <hr />

            <input type="button" onClick={handleSubmitAll} className="btn btn-primary" value="Conferma" />

          </div>
          : <p>Caricamento risorse in corso...</p>}
    </div>
  )
};

export default InvoiceFieldCompile;