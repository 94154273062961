import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faCoins, faFileContract, faFolder, faHandshake, faMoneyBill, faServer, faSignOutAlt, faSlidersH, faTachometerAlt, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';

import { SidebarMain, SidebarLink } from './sidebar-components';
import { useAuth } from '../../contexts/auth-context';

// import LogoMasamune from '../../media/logo.svg';

const Sidebar = () => {
	const { permissions, id, role } = useAuth();

	const handleLogout = () => {
		localStorage.removeItem('msmn_partner_access_token');
		window.location.href = '/';
	}

	return (
		<SidebarMain>
			<div className="d-flex sidebar flex-column h-100">
				{/* <a href="/">
					<img src={LogoMasamune} alt="Logo Masamune" className="w-100" />
				</a> */}

				{/* <hr /> */}

				<div className="sidebar-link">
					<a href="/">
						<SidebarLink>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<span>Overview</span>
						</SidebarLink>
					</a>


					{
						permissions.can_view_operators ?
							<a href="/operatori">
								<SidebarLink>
									<FontAwesomeIcon icon={faUsers} />
									<span>Operatori</span>
								</SidebarLink>
							</a>
							: null}

					{
						permissions.can_view_contracts ?
							<a href="/contratti">
								<SidebarLink>
									<FontAwesomeIcon icon={faFileContract} />
									<span>Contratti</span>
								</SidebarLink>
							</a>
							: null}
							
					{
						permissions.can_view_accounting ?
							<a href="/accounting">
								<SidebarLink>
									<FontAwesomeIcon icon={faChartPie} />
									<span>Contabilità</span>
								</SidebarLink>
							</a>
							: null}

					{
						permissions.can_view_hosting ?
							<a href="/hosting">
								<SidebarLink>
									<FontAwesomeIcon icon={faServer} />
									<span>Hosting</span>
								</SidebarLink>
							</a>
							: null}

					{
						permissions.can_view_customers ?
							<a href="/clienti">
								<SidebarLink>
									<FontAwesomeIcon icon={faHandshake} />
									<span>Gestione clienti</span>
								</SidebarLink>
							</a>
							: null}

					{
						permissions.can_view_quotes ?
							<a href="/preventivi">
								<SidebarLink>
									<FontAwesomeIcon icon={faFolder} />
									<span>Gestione preventivi</span>
								</SidebarLink>
							</a>
							: null}

					{
						permissions.can_view_referrals ?
							<a href="/referrals/internal">
								<SidebarLink>
									<FontAwesomeIcon icon={faHandshake} />
									<span>Referrals</span>
								</SidebarLink>
							</a>
							: null}

					{
						permissions.can_view_referrals ?
							<a href="/referrals/external">
								<SidebarLink>
									<FontAwesomeIcon icon={faHandshake} />
									<span>Referrals Esterni</span>
								</SidebarLink>
							</a>
							: null}


					{
						permissions.can_view_bonus && role !== 'SALES' ?
							<a href="/bonus/interns">
								<SidebarLink>
									<FontAwesomeIcon icon={faMoneyBill} />
									<span>Bonus Interni</span>
								</SidebarLink>
							</a>
							: null}

					{
						permissions.can_view_bonus && permissions.sales ?
							<a href={`/bonus/sales/${id}`}>
								<SidebarLink>
									<FontAwesomeIcon icon={faMoneyBill} />
									<span>Bonus Agente</span>
								</SidebarLink>
							</a>
							: null}

					<a href="/progetti">
						<SidebarLink>
							<FontAwesomeIcon icon={faCoins} />
							<span>Progetti</span>
						</SidebarLink>
					</a>

					<a href="/maturato">
						<SidebarLink>
							<FontAwesomeIcon icon={faCoins} />
							<span>Maturato</span>
						</SidebarLink>
					</a>
				</div>

				<div className="sidebar-account mt-auto">
					{
						role === 'SUPER ADMIN' || role === 'ADMIN' ?
							<a href="/impostazioni">
								<SidebarLink>
									<FontAwesomeIcon icon={faSlidersH} />
									<span>Impostazioni</span>
								</SidebarLink>
							</a>
							: null}

					<a href="/profilo">
						<SidebarLink>
							<FontAwesomeIcon icon={faUser} />
							<span>Profilo</span>
						</SidebarLink>
					</a>

					<SidebarLink onClick={handleLogout}>
						<FontAwesomeIcon icon={faSignOutAlt} />
						<span>Disconnettiti</span>
					</SidebarLink>
				</div>
			</div>
		</SidebarMain>
	)
}

export default Sidebar;