import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';

import { useHosting } from '../../../../../contexts/accounting-contexts/hosting-context';
import { Padder } from '../../../../../utils';

import './calendar-style.scss';

const SubscriptionsCalendar = () => {
	const { getSubscriptions } = useHosting();

	const [events, setEvents] = useState(null);

	const headerToolbar = {
		left: 'dayGridMonth',
		center: 'title',
		right: 'prev,next',
	};

	useEffect(() => {
		const fetchData = async () => {
			const { data, error } = await getSubscriptions(null, null);

      if (error !== null) return alert(error.data.error);
      let _events = [];
			
			data.results.map((item) => {
				let startDate = moment(item.expire_date).toISOString();
				let endDate = moment(item.expire_date).toISOString();

				_events.push({
					title: `Scadenza iscrizione Nr. #${ new Padder(6).pad(item.nr_subscription) }`,
					start: startDate,
					end: endDate,
          url: `/hosting/subscriptions/${item.id}`
				})

				return {}
			});

			setEvents(_events);
		}

		fetchData();
	}, [getSubscriptions]);

	return (
		<FullCalendar
			plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
			initialView="dayGridMonth"
			headerToolbar={headerToolbar}
			events={events}
			slotMinTime={'06:00:00'}
      themeSystem={'standard'}
			locale={itLocale}
      eventClick={(e) => window.location.href = e.event.url}
		/>
	)
}

export default SubscriptionsCalendar;