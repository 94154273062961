import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Sidebar from '../sidebar';
import { WrapperDashboard, WrapperPages } from '../custom-components';

import { OperatorProvider } from '../../contexts/operator-context';

// pages
import Home from '../../pages/Home';
import Operators from '../../pages/Operators';
import Accounting from '../../pages/Accounting';
import Bonus from '../../pages/Bonus';
import Settings from '../../pages/Settings';
import Maturato from '../../pages/Maturato';
import Contracts from '../../pages/Contracts';
import Customers from '../../pages/Customers';
import Quotes from '../../pages/Quotes';
import Profile from '../../components/profile-components';
import Referrals from '../../pages/Referrals';
import ReferralsExternal from '../../pages/ReferralsExternal';
import Projects from '../../pages/Projects';
import Hosting from '../../pages/Hosting';

import Privacy from '../../pages/Privacy';
import Terms from '../../pages/Terms';

const Dashboard = () => {
  return (
    <BrowserRouter>
      <WrapperDashboard>
        <Sidebar />
        <WrapperPages>
          <Switch>
            <Route exact path={[ "/", "/dashboard" ]} component={Home} />
            <Route path="/operatori" component={Operators} />
            <Route path="/accounting" component={Accounting} />
            <Route path="/hosting" component={Hosting} />
            <Route path="/bonus" component={Bonus} />
            <Route path="/impostazioni" component={Settings} />
            <Route path="/maturato" component={Maturato} />
            <Route path="/contratti" component={Contracts} />
            <Route path="/preventivi" component={Quotes} />
            <Route path="/clienti" component={Customers} />
            <Route path="/referrals/internal" component={Referrals} />
            <Route path="/referrals/external" component={ReferralsExternal} />
            <Route path="/progetti" component={Projects} />
            <OperatorProvider>
              <Route path="/profilo" component={Profile} />
            </OperatorProvider>
						<Route exact path="/privacy-policy" component={Privacy} />
						<Route exact path="/termini-e-condizioni" component={Terms} />
          </Switch>
        </WrapperPages>
      </WrapperDashboard>
    </BrowserRouter>
  )
}

export default Dashboard;