import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// contexts
import { ContractProvider } from '../contexts/contract-context';
import { QuoteProvider } from '../contexts/accounting-contexts/quote-context';

// components
import ListContracts from '../components/contracts-components/list-contracts';
import SingleContract from '../components/contracts-components/single-contract';
import { OperatorProvider } from '../contexts/operator-context';
import { MasamuneServicesProvider } from '../contexts/accounting-contexts/masamune-services-context';


const Contracts = ({ match }) => {
	return (
		<BrowserRouter>
			<ContractProvider>
				<QuoteProvider>
					<OperatorProvider>
						<MasamuneServicesProvider>
							<Switch>
								<Route exact path={`${match.path}`} component={ListContracts} />
								<Route exact path={`${match.path}/:id`} component={SingleContract} />
							</Switch>
						</MasamuneServicesProvider>
					</OperatorProvider>
				</QuoteProvider>
			</ContractProvider>
		</BrowserRouter>
	)
}

export default Contracts;