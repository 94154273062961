import styled from 'styled-components';

export const TopBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-size: 18px;
    font-weight: bold;
  }
`

export const TopBarInputWrapper = styled.div`
  width: 25%;
  margin: 0 5px;

  &:first-of-type {
    margin: 0 5px 0 0!important;
  }
`

export const NewComponentWrapper = styled.div`
  margin-left: auto;
  width: 15%;

	.close {
		margin-left: auto;
    border: 0;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    padding: 3px 7px 7px 7px;
    color: #1a1a1a;
    border-radius: 2px;
	}
`