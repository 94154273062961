import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faEye } from '@fortawesome/free-solid-svg-icons';

import TopBarSection from '../../../ui-components/top-bar-section';
import TableFilterMonth from '../../../ui-components/table-filter-months';

import { useMasamuneServices } from '../../../contexts/accounting-contexts/masamune-services-context';
import { number_format } from '../../../utils';

const AccountingMasamuneServices = () => {
  const { getInvoices, getRecapInvoices } = useMasamuneServices();

  const [invoices, setInvoices] = useState([]);
  const [recaps, setRecaps] = useState([]);
  const [category, setCategory] = useState(null);
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [year, setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    const fetchInvoices = async () => {
      const { data, error } = await getInvoices(month, year, { category, exclude: 'Rate Oraria' });
      if (error !== null) return alert(error.data.error);
      setInvoices(data.results);
    };

    const fetchRecapYear = async () => {
      const { data, error } = await getRecapInvoices(year, { category, exclude: 'Rate Oraria' });
      if (error !== null) return alert(error.data.error);
      setRecaps(data.results);
    }

    fetchInvoices();
    fetchRecapYear();
  }, [month, year, category, getInvoices, getRecapInvoices]);

  const handleOnChangeCategory = async (e) => {
    const elements = document.getElementsByClassName('filter-item');

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('btn-primary');
      elements[i].classList.add('btn-secondary');
    }

    e.target.classList.add('btn-primary');
    e.target.classList.remove('btn-secondary');
  };

  return (
    <div className="accounting-masamune-services">
      <TopBarSection pageName="Contabilità - Servizi Masamune" handleChangeYear={setYear} />

      <div className="filter-list">
        <div onClick={(e) => { handleOnChangeCategory(e); setCategory(null) }} className="filter-item btn btn-primary">TUTTI</div>
        <div onClick={(e) => { handleOnChangeCategory(e); setCategory('Sviluppo') }} className="filter-item btn btn-secondary">SVILUPPO</div>
        <div onClick={(e) => { handleOnChangeCategory(e); setCategory('Digital Marketing') }} className="filter-item btn btn-secondary">DIGITAL MARKETING</div>
        <div onClick={(e) => { handleOnChangeCategory(e); setCategory('Hosting') }} className="filter-item btn btn-secondary">HOSTING</div>
        <div onClick={(e) => { handleOnChangeCategory(e); setCategory('Design') }} className="filter-item btn btn-secondary">DESIGN</div>
        <div onClick={(e) => { handleOnChangeCategory(e); setCategory('Fotografia') }} className="filter-item btn btn-secondary">FOTOGRAFIA</div>
        <div onClick={(e) => { handleOnChangeCategory(e); setCategory('Video') }} className="filter-item btn btn-secondary">VIDEO</div>
        <div onClick={(e) => { handleOnChangeCategory(e); setCategory('Search Engine Optimization') }} className="filter-item btn btn-secondary">SEO</div>
        <div onClick={(e) => { handleOnChangeCategory(e); setCategory('Copywriting') }} className="filter-item btn btn-secondary">COPYWRITING</div>
      </div>

      <TableFilterMonth data={recaps} handleChangeMonth={setMonth} />
        <div class="table-responsive">
<table className="table table-striped">
          <thead>
            <tr>
              <th>Azioni</th>
              <th>ID</th>
              <th>Cliente</th>
              <th>Numero</th>
              <th>Data</th>
              <th>Documento</th>
              <th>IVA</th>
              <th>Totale</th>
            </tr>
          </thead>
          <tbody>
            {
              invoices.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <a className="btn btn-edit me-2" href={item.doc_link} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInbox} /></a>
                      <a className="btn btn-edit" href={`/accounting/masamune/${item.id}`}><FontAwesomeIcon icon={faEye} /></a>
                    </td>
                    <td>{item.id_fic}</td>
                    <td>{item.customer}</td>
                    <td>{item.nr_doc}</td>
                    <td>{moment(item.date_doc).format('DD/MM/YYYY')}</td>
                    <td>€ {number_format(item.import_doc, 2, ',', '.')}</td>
                    <td>€ {number_format(item.import_vat, 2, ',', '.')}</td>
                    <td>€ {number_format(item.import_tot, 2, ',', '.')}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table></div>
    </div>
  )
};

export default AccountingMasamuneServices;