import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faTrash } from '@fortawesome/free-solid-svg-icons';

import TopBarSection from '../../../ui-components/top-bar-section';
import TableFilterMonth from '../../../ui-components/table-filter-months';

import { useContract } from '../../../contexts/contract-context';
import NewContract from '../new-contract';

const ListContracts = () => {
  const { getContracts, getRecapContracts, deleteContract } = useContract();

  const [items, setItems] = useState(null);
  const [recapItems, setRecapItems] = useState(null);
  const [typeContract, setTypeContract] = useState(null);
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [year, setYear] = useState(new Date().getFullYear())

  const [showNewContract, setShowNewContract] = useState(false);

  useEffect(() => {
    const fetchContracts = async () => {
      const { data, error } = await getContracts({ month, year, typeContract });
      if (error !== null) return alert(error.data.error)
      setItems(data.results);
    };

		const fetchRecapContracts = async () => {
      const { data, error } = await getRecapContracts(year, { typeContract });
      if (error !== null) return alert(error.data.error)
      setRecapItems(data.results);
    };

		fetchRecapContracts();
    fetchContracts();
  }, [year, month, typeContract, getContracts, getRecapContracts])

	const handlerDeleteContract = async (contractId) => {
		if (!window.confirm("Sei sicuro di voler eliminare il contratto?")) return

		const { error } = await deleteContract(contractId);

		if (error !== null) {
			return alert("Impossibile eliminare il contratto.");
		}

		return window.location.reload();
	}

  return (
    <div className="list-contracts-page">
      <TopBarSection pageName="Contratti" handleChangeYear={setYear} handleNewComponent={setShowNewContract} handleTypeContract={setTypeContract} />

			{ recapItems !== null ? <TableFilterMonth showTotal={false} showCount={true} data={recapItems} handleChangeMonth={setMonth} /> : null }
      <div className="table-responsive">
				<table className="table table-striped">
          <thead>
            <tr>
              <th>Azioni</th>
              {/* <th>ID</th> */}
              <th>Tipo</th>
              <th>Cliente</th>
              <th>Email Cliente</th>
              <th>Inviato il</th>
              <th>Scade il</th>
            </tr>
          </thead>
          <tbody>
            {
              items !== null ?
                items.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <a className="btn btn-edit me-2" href={`/contratti/${item.id}`}><FontAwesomeIcon icon={faBoxOpen} /></a>
                        <a className="btn btn-delete me-2" onClick={() => handlerDeleteContract(item.id)}><FontAwesomeIcon icon={faTrash} /></a>
                      </td>
                      {/* <td>{item.id}</td> */}
                      <td>{item.type_file}</td>
                      <td>{item.customer_name}</td>
                      <td>{item.customer_email}</td>
                      <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
											<td>{item.expire_date !== null ? moment(item.expire_date).format('DD/MM/YYYY') : 'n/a' }</td>
                    </tr>
                  )
                })
                : null
            }
          </tbody>
        </table>
			</div>
      {showNewContract ? <NewContract closePage={setShowNewContract} /> : null}
    </div>
  )
}

export default ListContracts;