import React from 'react';
import { useForm } from 'react-hook-form';

import { InputEmail, InputText } from '../../forms';

const FormReferral = ({ data = null, handleSave }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: data
  });

  const onSubmit = async (body) => {
		let data = {};

		for (let i = 0; i < Object.keys(body).length; i++) {
			data[Object.keys(body)[i]] = body[Object.keys(body)[i]] === '' ? null : body[Object.keys(body)[i]]
		}

    await handleSave(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.first_name} name="first_name" label="Nome" register={register} isRequired={true} />
        </div>
        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.last_name} name="last_name" label="Cognome" register={register} isRequired={true} />
        </div>
        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.rag_sociale} name="rag_sociale" label="Ragione Sociale" register={register} isRequired={true} />
        </div>

				<div className="col-md-4">
          <InputEmail defaultValue={data === null ? null : data.email} name="email" label="Email" register={register} isRequired={true} />
        </div>
        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.phone} name="phone" label="Cellulare" register={register} isRequired={true} />
        </div>
        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.telephone} name="telephone" label="Telefono" register={register} />
        </div>

        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.piva} name="piva" label="P. IVA" register={register} isRequired={true} />
        </div>
        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.cf} name="cf" label="Cod. Fiscale" register={register} isRequired={false} />
        </div>
				<div className="col-md-4">
          <InputEmail defaultValue={data === null ? null : data.pec} name="pec" label="PEC" register={register} isRequired={true} />
        </div>

        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.codedest} name="codedest" label="Cod. Dest." register={register} />
        </div>
        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.address} name="address" label="Indirizzo" register={register} />
        </div>
				<div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.city} name="city" label="Città" register={register} />
        </div>

        <div className="col-md-4">
          <InputText defaultValue={data === null ? null : data.postcode} name="postcode" label="CAP" register={register} />
        </div>
      </div>

      <hr />

      <input type="submit" value="Conferma" className="btn btn-primary" />
    </form>
  )
}

export default FormReferral;