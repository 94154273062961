import React, { useEffect, useState } from 'react';

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useCustomer } from '../../../contexts/customer-context';
import TopBarSection from '../../../ui-components/top-bar-section';
import Pagination from '../../../ui-components/pagination';

import { number_format } from '../../../utils';

const CustomersList = () => {
  const { getCustomers } = useCustomer();

  const [lengthAll, setLengthAll] = useState(0);
  const [query, setQuery] = useState(null);
  const [offset, setOffset] = useState(0);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      const { data, error } = await getCustomers({
        limit: 24 + offset,
        offset: offset,
        query: query
      });

      if (error !== null) return alert(error.data.error);
      setCustomers(data.results);
      setLengthAll(data.count);
    }

    fetchCustomers();
  }, [offset, query, getCustomers]);

  const handleSearchInfo = async (e, value) => {
    e.preventDefault();
    setOffset(0);
    setQuery(value);
  }

  return (
    <div className="list-customers-components">
      <TopBarSection pageName="Gestione Clienti" handleSearchSubmit={handleSearchInfo} placeholderSearch={'P. IVA o Ragione Sociale'} />
      <Pagination length={lengthAll} setOffset={setOffset} offset={offset} />

        <div class="table-responsive">
<table className="table table-striped">
          <thead>
            <tr>
              <th>Azioni</th>
              <th>ID</th>
              <th>Nome</th>
              <th>Mail</th>
              <th>Pec</th>
              <th>Paese</th>
              <th>P. IVA</th>
              <th>C.F.</th>
              <th>FIDO</th>
            </tr>
          </thead>
          <tbody>
            {
              customers.map((item, index) => {
                return (
                  <tr className={item.fido_status === 0 ? 'fido-ok' : item.fido_status === 1 ? 'fido-limit' : 'fido-danger'} key={index}>
                    <td>
                      <a className="btn btn-edit me-2" href={`/clienti/${item.id}`}><FontAwesomeIcon icon={faPen} /></a>
                      {/* <button className="btn btn-delete"><FontAwesomeIcon icon={faTrash} /></button> */}
                    </td>
                    <td>{item.id_customer}</td>
                    <td>{item.name}</td>
                    <td>{item.mail}</td>
                    <td>{item.pec}</td>
                    <td>{item.country}</td>
                    <td>{item.piva}</td>
                    <td>{item.cf}</td>
                    <td>&euro; {number_format(item.fido, 2, ',', '.')}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table></div>
    </div>
  )
}

export default CustomersList;