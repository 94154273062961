import React, { useState } from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import FormProject from '../../../ui-components/form-components/projects';

import { useProject } from '../../../contexts/project-context';
import { OperatorProvider } from '../../../contexts/operator-context';
import { MasamuneServicesProvider } from '../../../contexts/accounting-contexts/masamune-services-context';
import { ContractProvider } from '../../../contexts/contract-context';
import TopBarSection from '../../../ui-components/top-bar-section';


const NewProject = ({ closePage }) => {
	const { createProject } = useProject();

	
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);

	const handleSaveProject = async (data) => {
		// create user
		const { error } = await createProject(data);

		if (error !== null) {
			return alert(error.data.error)
		}

		closePage(false);
		return window.location.reload();
	}

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<TopBarSection  closePage={closePage} pageName="Modifica progetto" handleChangeMonth={setMonth} handleChangeYear={setYear} />

				<OperatorProvider>
					<ContractProvider>
						<MasamuneServicesProvider>
							<FormProject handleSave={handleSaveProject} data={null} year={year} month={month} />
						</MasamuneServicesProvider>
					</ContractProvider>
				</OperatorProvider>
			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default NewProject;