import styled from 'styled-components';

export const SettingsMainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px
`;

export const SettingsMainOption = styled.div`
  width: 31.2%;
  flex: 1 31.2%;
  margin: 0 10px;
  border: 1px solid #333;
  border-radius: 2px;
  transition: all .3s ease;
	background: #2a2a2a;

  &:hover {
		background: #111;
  }

  a {
    padding: 20px;
    display: block;
    text-decoration: none;

    h2 {
      color: #eee;
      font-size: 24px;
      font-weight: bold;
      margin-top: 0;
    }

    p {
      color: #fff;
      font-size: 14px;
      margin-bottom: 0;
    }

    hr {
      background-color: #fff;
    }
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;
