import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { InputSelect, InputDate, InputNumber } from "../../../../forms";

const FormAccountingHostingSubscription = ({ handleSave }) => {
	const { register, handleSubmit } = useForm({});
	const [packageHosting, setPackageHosting] = useState(null);

	const onSubmit = async (body) => {
		await handleSave(body);
	};

	const packages = [
		{ value: "custom", label: "Custom" },
		{
			value: "price_1MmbHZJiSTjG1suRjZuUWvWX",
			label: "Masamune Hosting Startup Annuale",
		},
		{
			value: "price_1MmbHIJiSTjG1suRtHC9HJzI",
			label: "Masamune Hosting Growth Annuale",
		},
	];

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="row mb-3">
				<div className="col-md-4">
					<InputSelect
						name="price_id"
						onChange={setPackageHosting}
						label={"Seleziona un pacchetto"}
						isRequired={true}
						data={packages}
						register={register}
					/>
				</div>
			</div>

			{packageHosting === "custom" ? (
				<div className="row mb-3">
					<div className="col-md-4">
						<InputDate
							defaultValue={null}
							label="Data Inizio"
							name="start_date"
							isRequired={true}
							register={register}
						/>
					</div>
					<div className="col-md-4">
						<InputNumber
							defaultValue={null}
							label="Mesi"
							name="months"
							isRequired={true}
							register={register}
						/>
					</div>
					<div className="col-md-4">
						<InputNumber
							defaultValue={null}
							label="Prezzo"
							name="amount"
							isRequired={true}
							register={register}
							price={true}
							type="number"
							step="0.01"
						/>
					</div>
				</div>
			) : null}

			<div className="row mb-3">
				<div className="col-md-4">
					<input type="submit" value="Conferma" className="btn btn-primary" />
				</div>
			</div>
		</form>
	);
};

export default FormAccountingHostingSubscription;
