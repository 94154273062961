import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import TopBarSection from '../../../ui-components/top-bar-section';
import { useHosting } from '../../../contexts/accounting-contexts/hosting-context';

const AccountingHosting = () => {
  const { getUsers, deleteUser } = useHosting();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data, error } = await getUsers();

      if (error !== null) {
        return alert(error.data.error)
      }

      setUsers(data.results);
    };

    fetchUsers();
  }, [getUsers])

	const deleteUserHandler = async (userId) => {
		if (!window.confirm("Sei sicuro di voler eliminare questo utente?")) return

		const { error } = await deleteUser(userId);

		if (error !== null) {
			return alert("Impossibile eliminare l'utente.");
		}

		return window.location.reload();
	}

  return (
    <div className="hosting-users-signed-up">
      <TopBarSection pageName="Contabilità - Masamune Hosting" />

      <a href="/hosting/subscriptions" className="btn btn-primary mb-3 me-2">Lista Abbonamenti</a>
      <a href="/hosting/subscriptions/calendar" className="btn btn-primary mb-3">Calendario Abbonamenti</a>

      <div className="table-responsive">
				<table className="table table-striped">
          <thead>
            <tr>
              <th>Azioni</th>
              <th>Username</th>
              <th>Email</th>
              <th>Rag. Sociale</th>
              <th>P. IVA</th>
              <th>Cod. Fiscale</th>
              <th>Cellulare</th>
            </tr>
          </thead>
          <tbody>
            {
              users.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <a className="btn btn-edit" href={`/hosting/single-user/${item.id}`}><FontAwesomeIcon icon={faPen} /></a>
                      <a className="btn btn-delete" onClick={() => deleteUserHandler(item.id)}><FontAwesomeIcon icon={faTrash} /></a>
                    </td>
                    <td>{item.username}</td>
                    <td>{item.email}</td>
                    <td>{item.rag_social}</td>
                    <td>{item.piva}</td>
                    <td>{item.cf}</td>
                    <td>{item.phone}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
			</div>
    </div>
  )
}

export default AccountingHosting;