export const API_ENDPOINT = `http://apidashboard.masamune.it/api`;
export const AUTH_ENDPOINT = 'auth';
export const OPERATOR_CONTEXT = 'users';
export const ACCOUNTING_ENDPOINT = 'accounting'
export const HOSTING_ENDPOINT = 'accounting/hosting'
export const INVOICES_ENDPOINT = 'accounting/invoices'
export const BONUS_ENDPOINT = 'accounting/bonus'
export const SYNC_ENDPOINT = 'sync'
export const CONTRACTS_ENDPOINT = 'contracts'
export const QUOTES_ENDPOINT = 'accounting/quotes'
export const CUSTOMERS_ENDPOINT = 'customers'
export const REFERRALS_INTERNAL_ENDPOINT = 'referrals/internal'
export const REFERRALS_EXTERNAL_ENDPOINT = 'referrals/external'
export const PROJECTS_ENDPOINT = 'projects'
