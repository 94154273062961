import React from 'react';

import { PROJECTS_ENDPOINT } from '../constants/API_ENDPOINT';
import { httpGet, httpPatch, httpPost } from '../http';
import { useAuth } from './auth-context';

const ProjectContext = React.createContext();

const ProjectProvider = (props) => {
	const { jwtToken } = useAuth();

	const getProjects = async (params = {}) => {
		let obj = {
			data: null,
			error: null,
			status: null,
			meta: null
		};

		try {
			const result = await httpGet(`${PROJECTS_ENDPOINT}/list/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const getRecapProjects = async (year, params = {}) => {
		let obj = {
			data: null,
			error: null,
			status: null,
			meta: null
		};

		try {
			const result = await httpGet(`${PROJECTS_ENDPOINT}/recap/${year}/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const getSingleProject = async (id, params = {}) => {
		let obj = {
			data: null,
			error: null,
			status: null,
			meta: null
		};

		try {
			const result = await httpGet(`${PROJECTS_ENDPOINT}/single/${id}/`, jwtToken, params);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const createProject = async (body = {}) => {
		let obj = {
			data: null,
			error: null,
			status: null,
			meta: null
		};

		try {
			const result = await httpPost(`${PROJECTS_ENDPOINT}/create/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const splitPaymentsProjects = async (year, body = {}) => {
		let obj = {
			data: null,
			error: null,
			status: null,
			meta: null
		};

		try {
			const result = await httpPost(`${PROJECTS_ENDPOINT}/split-payments/${year}/`, jwtToken, body);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	const updateProject = async (id, body = {}) => {
		let obj = {
			data: null,
			error: null,
			status: null,
			meta: null
		};

		try {
			const result = await httpPatch(`${PROJECTS_ENDPOINT}/single`, jwtToken, body, id);
			obj.data = result.data;
			obj.status = result.status;
		} catch (err) {
			obj.error = err.response;
			obj.status = err.response.status;
		}

		return obj;
	};

	return (
		<ProjectContext.Provider
			value={{
				getProjects,
				getRecapProjects,
				getSingleProject,
				createProject,
				updateProject,
				splitPaymentsProjects
			}}

			{...props}
		/>
	)
};

const useProject = () => React.useContext(ProjectContext);

export { useProject, ProjectProvider }