import React from 'react';
import { Switch, Route } from 'react-router-dom';

// components
import SettingsMain from '../components/settings-components/main';
import SettingsSyncs from '../components/settings-components/syncs';
import InvoiceFieldCompile from '../components/settings-components/invoice-field-compile';

// providers
import { SyncProvider } from '../contexts/sync-context';
import { MasamuneServicesProvider } from '../contexts/accounting-contexts/masamune-services-context';
import { OperatorProvider } from '../contexts/operator-context';

const Settings = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={SettingsMain} />

      <SyncProvider>
        <Route exact path={`${match.path}/sincronizza`} component={SettingsSyncs} />

        <MasamuneServicesProvider>
          <OperatorProvider>
            <Route exact path={`${match.path}/compensi`} component={InvoiceFieldCompile} />
          </OperatorProvider>
        </MasamuneServicesProvider>
      </SyncProvider>
    </Switch>
  )
}

export default Settings;