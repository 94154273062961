import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { NewComponentWrapper, TopBarInputWrapper, TopBarWrapper } from './components';

const TopBarSection = ({ pageName = null, handleSearchSubmit = null, closePage = null, placeholderSearch = 'Cerca', handleFilterRole = null, handleNewComponent = null, handleChangeYear = null, handleChangeMonth = null, handleTypeContract = null }) => {
  const [searchValue, setSearchValue] = useState(null);

  return (
    <div>
      <TopBarWrapper>
        {
          pageName !== null ?
            <h1 className="mb-0 me-4">{pageName}</h1>
            : null
        }

        {
          handleSearchSubmit !== null ?
            <TopBarInputWrapper>
              <div className="input-group">
                <input type="text" className="form-control" onChange={(e) => setSearchValue(e.target.value)} placeholder={placeholderSearch} />
                <button className="btn btn-primary" type="button" onClick={(e) => handleSearchSubmit(e, searchValue)}>Cerca</button>
              </div>
            </TopBarInputWrapper>
            : null
        }

        {
          handleTypeContract !== null ?
            <TopBarInputWrapper>
              <select name="role" id="role" onChange={(e) => handleTypeContract(e.target.value)} className="form-select">
                <option value="">-- Seleziona tipo contratto --</option>
                <option value="Preventivo">Da Preventivo</option>
                <option value="Nuovo Partner">Nuovo Partner</option>
                <option value="Agente">Agente</option>
                <option value="Performance">Performance</option>
              </select>
            </TopBarInputWrapper>
            : null
        }

        {
          handleFilterRole !== null ?
            <TopBarInputWrapper>
              <select name="role" id="role" onChange={handleFilterRole} className="form-select">
                <option value="">-- Seleziona un ruolo --</option>
                <option value="SUPER ADMIN">SUPER ADMIN</option>
                <option value="ADMIN">ADMIN</option>
                <option value="OPERATORE">OPERATORE</option>
              </select>
            </TopBarInputWrapper>
            : null
        }

        {
          handleChangeYear !== null ?
            <TopBarInputWrapper>
              <select name="year" id="year" onChange={(e) => handleChangeYear(e.target.value)} className="form-select">
                <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                <option value={new Date().getFullYear()-1}>{new Date().getFullYear()-1}</option>
                <option value={new Date().getFullYear()-2}>{new Date().getFullYear()-2}</option>
                <option value={new Date().getFullYear()-3}>{new Date().getFullYear()-3}</option>
                <option value={new Date().getFullYear()-4}>{new Date().getFullYear()-4}</option>
                <option value={new Date().getFullYear()-5}>{new Date().getFullYear()-5}</option>
              </select>
            </TopBarInputWrapper>
            : null
        }

        {
          handleChangeMonth !== null ?
            <TopBarInputWrapper>
              <select name="month" id="month" onChange={(e) => handleChangeMonth(e.target.value)} className="form-select">
                <option value="">-- Seleziona un mese --</option>
                <option value="1">Gennaio</option>
                <option value="2">Febbraio</option>
                <option value="3">Marzo</option>
                <option value="4">Aprile</option>
                <option value="5">Maggio</option>
                <option value="6">Giugno</option>
                <option value="7">Luglio</option>
                <option value="8">Agosto</option>
                <option value="9">Settembre</option>
                <option value="10">Ottobre</option>
                <option value="11">Novembre</option>
                <option value="12">Dicembre</option>
              </select>
            </TopBarInputWrapper>
            : null
        }

        {
          handleNewComponent !== null ?
            <NewComponentWrapper>
              <button onClick={() => handleNewComponent(true)} className="btn btn-primary w-100"><FontAwesomeIcon icon={faPlus} className="me-2" /> Aggiungi</button>
            </NewComponentWrapper>
            : null
        }

				{
					closePage !== null ?
            <NewComponentWrapper>
							<button style={{ marginLeft: 'auto', float: 'right'}} onClick={() => closePage(false)} className="close badge bg-danger">&times;</button>
            </NewComponentWrapper>
					: null
				}
      </TopBarWrapper>

      <hr style={{ background: '#666' }} />
    </div>
  )
};

export default TopBarSection;