import styled from 'styled-components';

export const AccountingAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

export const AccountingAreaItem = styled.div`
  width: 48%;
  flex: 1 48%;
  text-align: center;
  border: 1px solid #111;
  transition: all .3s ease;
	background: #333;
  border-radius: 2px;
	
	&:hover {
		background: #1a1a1a;
	}

  a {
    display: block;
    padding: 60px 40px;
    text-decoration: none;
  }
  
  &:first-of-type {
    margin: 0 15px 0 0;
  }

  &:last-of-type {
    margin: 0 0 0 15px;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
    color: #ddd;
  }

  p {
    margin-bottom: 0;
    color: #81BB6D;
    font-weight: bold;
    font-size: 24px;
  }
`;