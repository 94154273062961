import React, { useState } from 'react';
import moment from 'moment';

import { InputDate } from '../../../ui-components/forms';

const UpdateExpireDateReferralModal = ({ save = () => { }, close = () => {} }) => {
	const [expire, setExpire] = useState(moment().add(1, 'year').format('YYYY-MM-DD'))

	return (
		<div className="approve-referral-modal modal-action">
			<div className="modal-copy">
				<div className="top-page-form">
					<h3 className="title-modal">Aggiorna Scadenza Referral</h3>
          <button onClick={() => close(false)} className="badge bg-danger">&times;</button>
        </div>

				<hr />

				<InputDate defaultValue={expire} name="expire_at" label="Scadenza Referral" onChange={setExpire} />
				<button className="mt-2 btn btn-primary" type="button" onClick={() => save(expire)}>Conferma</button>
			</div>
		</div>
	)
}

export default UpdateExpireDateReferralModal;