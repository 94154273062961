import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useForm } from 'react-hook-form';

import { InputEmail, InputText, InputSelect } from '../../forms';
import { useQuote } from '../../../contexts/accounting-contexts/quote-context';
import FormContractOperator from './contract-operator';

const FormContracts = ({ data = null, handleSave, year }) => {
	const { getQuotes } = useQuote();

	const { register, handleSubmit } = useForm({
		defaultValues: data
	});

	const [isDisabled, setIsDisabled] = useState(false);
	const [quotes, setQuotes] = useState(null);
	const [typeFile, setTypeFile] = useState(null);

	const [contractsOperators, setContractsOperators] = useState([]);

	useEffect(() => {
		const fetchQuotes = async () => {
			const { data, error } = await getQuotes({ year });
			if (error !== null) return alert(error.data.error);

			let quotesToClean = [];
			data.results.map((item, index) => {
				quotesToClean.push({
					index: index,
					label: `Preventivo nr. ${item.nr_doc} del ${moment(item.date).format('DD/MM/YYYY')} - ${item.customer_fic_name}`,
					value: item.id
				})

				return {}
			});

			setQuotes(quotesToClean);
		};

		fetchQuotes();
	}, [getQuotes, year]);

	const onSubmit = async (body) => {
		setIsDisabled(true);
		await handleSave({ ...body, contract_operators: contractsOperators }, setIsDisabled);
	}

	const types = [
		{ label: 'Da Preventivo', value: 'Preventivo' },
		{ label: 'Nuovo Partner', value: 'Nuovo Partner' },
		{ label: 'Agente', value: 'Agente' },
		{ label: 'Performance', value: 'Performance' }
	];

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="row">
				<div className="col-md-4">
					<InputSelect name="type_file" label="Tipologia" isRequired={true} register={register} data={types} onChange={setTypeFile} />
				</div>
			</div>

			<hr />

			<div className="row">
				<div className="col-md-4">
					<InputText name="customer_name" label={`Nome ${typeFile === 'Preventivo' ? 'cliente' : typeFile === 'Nuovo Partner' ? 'partner' : typeFile === 'Agente' ? 'agente' : ''}`} isRequired={true} register={register} />
				</div>

				<div className="col-md-4">
					<InputEmail name="customer_email" label={`Email ${typeFile === 'Preventivo' ? 'cliente' : typeFile === 'Nuovo Partner' ? 'partner' : typeFile === 'Agente' ? 'agente' : ''}`} isRequired={true} register={register} />
				</div>

				{
					typeFile === 'Preventivo' ?
						<div className="col-md-4">
							{
								quotes !== null ?
									<InputSelect name="quote_id" label="Preventivo" isRequired={true} register={register} data={quotes} />
									: null
							}
						</div>
						: null}

				{
					typeFile === 'Nuovo Partner' || typeFile === 'Agente' ?
						<>
							<div className="col-md-4">
								<InputText name="admin_name" label="Nome amministratore" isRequired={true} register={register} />
							</div>

							<div className="col-md-4">
								<InputEmail name="admin_email" label="Email amministratore" isRequired={true} register={register} />
							</div>
						</>
						: null}

			</div>

			{ typeFile === 'Preventivo' ? <FormContractOperator contractsOperators={contractsOperators} setContractsOperators={setContractsOperators} /> : null }

			<hr />

			<input type="submit" value="Conferma" disabled={isDisabled} className="btn btn-primary" />
		</form>
	)
}

export default FormContracts;