import React, { useState, useEffect } from 'react'
import jwt from 'jwt-decode'

import { httpGet, httpPost } from '../http'
import { AUTH_ENDPOINT, OPERATOR_CONTEXT } from '../constants/API_ENDPOINT'

const AuthContext = React.createContext()

function AuthProvider(props) {
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [jwtToken, setJwtToken] = useState()
	const [id, setId] = useState(null)
	const [username, setUsername] = useState(null)
	const [role, setRole] = useState(null)
	const [userEmail, setUserEmail] = useState(null)
	const [permissions, setPermissions] = useState({
		can_view_operators: false,
		can_view_accounting: false,
		can_view_contracts: false,
		can_view_settings: false,
		can_view_customers: false,
		can_view_quotes: false,
		can_view_referrals: false,
		can_view_bonus: false,
		can_view_hosting: false,
		is_manager: false,
		sales: false
	});

	useEffect(() => {
		const existingToken = localStorage.getItem('msmn_partner_access_token')
		if (existingToken) {
			let decodedToken = jwt(existingToken, { complete: true })
			let dateNow = new Date()
			let fixedExpToken = parseInt(decodedToken.exp) * 1000

			if (fixedExpToken > dateNow.getTime()) {
				setId(decodedToken.user_id)
				setUserEmail(decodedToken.email)
				setUsername(decodedToken.username)
				setRole(decodedToken.role)
				setJwtToken(existingToken)
				setIsLoggedIn(true)
			} else {
				setIsLoggedIn(false);
				localStorage.removeItem('msmn_partner_access_token');
				window.location.href = '/';
			}
		}
	}, [])

	useEffect(() => {
		const existingToken = localStorage.getItem('msmn_partner_access_token')

		const fetchUserData = async (userId) => {
			const { data } = await httpGet(`${OPERATOR_CONTEXT}/single/${userId}/`, existingToken, {});
			setRole(data.results.role);
			setPermissions({
				can_view_operators: data.results.can_view_operators,
				can_view_accounting: data.results.can_view_accounting,
				can_view_contracts: data.results.can_view_contracts,
				can_view_settings: data.results.can_view_settings,
				can_view_customers: data.results.can_view_customers,
				can_view_quotes: data.results.can_view_quotes,
				can_view_referrals: data.results.can_view_referrals,
				can_view_bonus: data.results.can_view_bonus,
				can_view_hosting: data.results.can_view_hosting,
				is_manager: data.results.is_manager,
				sales: data.results.sales
			});
		}

		if (existingToken) {
			let decodedToken = jwt(existingToken, { complete: true })
			let dateNow = new Date()
			let fixedExpToken = parseInt(decodedToken.exp) * 1000

			if (fixedExpToken > dateNow.getTime()) {
				fetchUserData(decodedToken.user_id);
			}
		}
	}, []);

	const setToken = (token) => {
		localStorage.setItem('msmn_partner_access_token', token)
		setIsLoggedIn(true)
		setJwtToken(token)
		window.location.reload();
	}

	const login = async (body) => {
		const { username, password } = body

		const result = await httpPost(`${AUTH_ENDPOINT}/login/`, null, { username, password })
		return result;
	}

	const logout = async () => {
		const existingToken = localStorage.getItem('msmn_partner_access_token')

		if (existingToken) {
			let decodedToken = jwt(existingToken, { complete: true })
			const req = await httpPost(`${AUTH_ENDPOINT}/logout/`, existingToken, { email: decodedToken.email })
			if (req.data) {
				localStorage.removeItem('msmn_partner_access_token')
				localStorage.removeItem('google_api_token')
				setIsLoggedIn(false)
			}
		}
	}

	const forgotPassword = async (email) => {
		let obj = {
			data: null, // 2XX-3XX
			error: null, // 4XX+
			meta: null, // if applicable
			status: null, // always
		}

		try {
			const req = await httpPost(`${AUTH_ENDPOINT}/forgot/`, null, { email })
			obj = {
				data: req.data,
				error: null,
				meta: null,
				status: req.status,
			}

			return obj
		} catch (err) {
			obj = {
				data: null,
				error: err.response.data.error.description,
				meta: null,
				status: err.response.status,
			}
			return obj
		}
	}

	const resetPassword = async (body) => {
		let obj = {
			data: null, // 2XX-3XX
			error: null, // 4XX+
			meta: null, // if applicable
			status: null, // always
		}

		try {
			const req = await httpPost(`${AUTH_ENDPOINT}/reset/`, null, body)
			obj = {
				data: req.data,
				error: null,
				meta: null,
				status: req.status,
			}

			return obj
		} catch (err) {
			obj = {
				data: null,
				error: err.response.data.error.description || err.response.data.error.message,
				meta: null,
				status: err.response.status,
			}
			return obj
		}
	}

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn,
				jwtToken,
				setToken,
				login,
				logout,
				id,
				username,
				role,
				userEmail,
				forgotPassword,
				resetPassword,
				permissions
			}}
			{...props}
		/>
	)
}
const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }